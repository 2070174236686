import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Grid, Radio, RadioGroup, Row, Schema, Stack, useToaster } from "rsuite";

import { Icon } from "@rsuite/icons";
import PlusIcon from '@rsuite/icons/Plus';
import { IoLocationOutline } from "react-icons/io5";
import { MdEmojiPeople } from 'react-icons/md';
import { PiFactory } from 'react-icons/pi';

import Alert from "../../components/Alert";
import Confirm from "../../components/Confirm";
import ContatoCadastro from "../../components/ContatoCadastro";
import ContatoList from "../../components/ContatoList";
import CustomInputCep from "../../components/CustomInputCep";
import CustomInputCnpjCpf from "../../components/CustomInputCnpjCpf";
import CustomInputNumeroEndereco from "../../components/CustomInputNumeroEndereco";
import CustomInputTelefone from "../../components/CustomInputTelefone";
import CustomSelectPicker from "../../components/CustomSelectPicker";
import CustomTextArea from "../../components/CustomTextArea";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import Help from "../../components/Help";
import Mapa from "../../components/Mapa";
import { showMessage } from "../../components/Message";

import { apiGetCep, apiGetCnpj } from '../../services/brasilApi';
import { isCNPJ, isCPF } from "../../services/utils";

import useApiFornecedor from "../../hooks/useApiFornecedor";
import useApiLocalizacao from "../../hooks/useApiLocalizacao";
import useApiMunicipio from "../../hooks/useApiMunicipio";
import useApiUF from "../../hooks/useApiUF";
import useScreenSizeComparison from "../../hooks/useScreenSizeComparison";

const Fornecedor = ({ consulta }) => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const { save, destroy, getAll, getId, getCnpjCpf } = useApiFornecedor()
    const { getCoordenadas } = useApiLocalizacao()
    const apiUF = useApiUF()
    const apiMunicipio = useApiMunicipio()

    const cnpjCpfRef = useRef()
    const formGroupRefUF = useRef()
    const formGroupRefMunicipio = useRef()

    const [fornecedores, setFornecedores] = useState({})
    const [fornecedor, setFornecedor] = useState({})
    const [fornecedorContato, setFornecedorContato] = useState()
    const [fornecedorJaCadastrado, setFornecedorJaCadastrado] = useState()

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingApiCnpj, setLoadingApiCnpj] = useState(false)
    const [loadingApiCep, setLoadingApiCep] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openContato, setOpenContato] = useState(false)

    const [openMapa, setOpenMapa] = useState(false)
    const [dadosMapa, setDadosMapa] = useState()

    const [deleteMode, setDeleteMode] = useState(false)
    const [deleteModeContato, setDeleteModeContato] = useState(false)

    const [cnpjInitial, setCnpjInitial] = useState()
    const [cepInitial, setCepInitial] = useState()

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    const getFornecedores = async () => {
        if (!dataTableParams) return setFornecedores({})
        setLoading(true)

        try {
            const data = await getAll({ ...dataTableParams })
            setFornecedores(data)
        } catch (error) {
            setFornecedores({})
            showError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        (async () => await getFornecedores())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (id, isDelete) => {
        try {
            const data = await getId(id)
            setFornecedor(data)
            setCnpjInitial(data.cnpjCpfFornecedor)
            setCepInitial(data.cepFornecedor)
            setDeleteMode(isDelete)
            setOpenEditModal(true)
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNew = () => {
        setFornecedor({
            idFornecedor: null,
            fisJurFornecedor: 'J',
            cnpjCpfFornecedor: '',
            ieFornecedor: '',
            nomeFornecedor: '',
            nomeFantasiaFornecedor: '',
            enderecoFornecedor: '',
            numeroEnderecoFornecedor: '',
            complementoFornecedor: '',
            bairroFornecedor: '',
            cepFornecedor: '',
            idMunicipio: '',
            siglaUF: '',
            telefone1Fornecedor: '',
            telefone2Fornecedor: '',
            emailFornecedor: '',
            obsFornecedor: '',
            FornecedorContatos: []
        })
        setFornecedorJaCadastrado(null)
        setCnpjInitial('')
        setCepInitial('')
        setDeleteMode(false)
        setOpenEditModal(true)
    }
    const handleClickEdit = id => editData(id)
    const handleClickDelete = id => editData(id, true)

    const editDataContato = async (indexContato, isDelete) => {
        if (!fornecedor || !fornecedor.FornecedorContatos || fornecedor.FornecedorContatos.length < indexContato - 1) return

        const contato = fornecedor.FornecedorContatos[indexContato]
        setFornecedorContato({
            indexContato: indexContato,
            nomeContato: contato.nomeFornecedorContato,
            telefoneContato: contato.telefoneFornecedorContato,
            emailContato: contato.emailFornecedorContato,
            obsContato: contato.obsFornecedorContato
        })
        setDeleteModeContato(isDelete)
        setOpenContato(true)
    }

    const handleClickNewContato = () => {
        setFornecedorContato({})
        setDeleteModeContato(false)
        setOpenContato(true)
    }
    const handleClickConfirmContato = contato => {
        const contatos = fornecedor.FornecedorContatos || []
        const indexContato = contato.indexContato

        if (deleteModeContato) {
            if (indexContato !== null && contatos.length > indexContato) {
                contatos.splice(indexContato, 1)

                setFornecedor(prevFornecedor => ({
                    ...prevFornecedor,
                    FornecedorContatos: [
                        ...contatos
                    ]
                }))
            }
        } else {
            const contatoFornecedor = {
                nomeFornecedorContato: contato.nomeContato,
                telefoneFornecedorContato: contato.telefoneContato,
                emailFornecedorContato: contato.emailContato,
                obsFornecedorContato: contato.obsContato
            }

            if (indexContato !== null && indexContato >= 0) {
                contatos[contato.indexContato] = contatoFornecedor
            } else {
                contatos.push(contatoFornecedor)
            }

            setFornecedor(prevFornecedor => ({
                ...prevFornecedor,
                FornecedorContatos: contatos
            }))
        }

        setOpenContato(false)
    }
    const handleClickEditContato = indexContato => editDataContato(indexContato)
    const handleClickDeleteContato = indexContato => editDataContato(indexContato, true)

    const handleClickCancel = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }
    const handleClickYesConfirm = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await destroy(fornecedor.idFornecedor) : await save(fornecedor)

            showSucess()
            handleClickCancel()
            await getFornecedores()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idFornecedor' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }
    const handleClickLoadData = async () => {
        setCnpjInitial(fornecedorJaCadastrado.cnpjCpfFornecedor)
        setCepInitial(fornecedorJaCadastrado.cepFornecedor)
        setFornecedor(fornecedorJaCadastrado)
        setFornecedorJaCadastrado(null)
    }

    // const hiddenCol_xl = isScreenSizeBelow('xl')
    const hiddenCol_lg = isScreenSizeBelow('lg')
    const hiddenCol_md = isScreenSizeBelow('md')

    const dataTableColumns = {
        displayButtonColumn: {
            dataKey: 'idFornecedor',
            onClick: handleClickEdit,
            hidden: () => !consulta
        },
        editButtonColumn: {
            dataKey: 'idFornecedor',
            onClick: handleClickEdit,
            hidden: () => consulta
        },
        deleteButtonColumn: {
            dataKey: 'idFornecedor',
            onClick: handleClickDelete,
            hidden: () => consulta
        },
        columns: [
            {
                dataKey: 'idFornecedor',
                headerCell: 'Id',
                align: 'center',
                width: 100,
                sortable: true,
                hidden: hiddenCol_md
            }, {
                dataKey: 'nomeFornecedor',
                headerCell: 'Nome',
                sortable: true,
                fullText: true,
                flexGrow: 2
            }, {
                dataKey: 'nomeFantasiaFornecedor',
                headerCell: 'Nome Fantasia',
                sortable: true,
                fullText: true,
                flexGrow: 2,
                hidden: hiddenCol_lg
            }, {
                dataKey: 'siglaUF',
                headerCell: 'UF',
                sortable: true,
                width: 80,
                align: 'center',
                hidden: hiddenCol_lg,
                customCellContent: rowData => rowData.UF && rowData.UF.siglaUF
            }, {
                dataKey: 'nomeMunicipio',
                headerCell: 'Município',
                sortable: true,
                fullText: true,
                flexGrow: 1,
                minwidth: 150,
                hidden: hiddenCol_lg,
                customCellContent: rowData => rowData.Municipio && rowData.Municipio.nomeMunicipio
            }
        ]
    }

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget.name

        const newValue =
            fieldName === 'ieFornecedor' ? value.toUpperCase()
                : fieldName === 'emailFornecedor' ? value.toLowerCase()
                    : value

        setFornecedor(prevFornecedor => ({
            ...prevFornecedor,
            [fieldName]: newValue
        }))

        if (fieldName === 'fisJurFornecedor' && value === 'F') {
            setFornecedor(prevFornecedor => ({
                ...prevFornecedor,
                ieFornecedor: 'ISENTO'
            }))
        }
    }

    const handleCnpjCpfBlur = async event => {
        const value = event.target.value
        const cnpj = value ? value.replace(/\D/g, '') : ''

        if (cnpj) {
            try {
                //Se der erro é pq não encontrou o cnpj no cadastro de fornecedores
                const dataCad = await getCnpjCpf(cnpj)

                setFornecedorJaCadastrado(dataCad)
                return false
            } catch { }
        }

        if (fornecedor.fisJurFornecedor === "F") return

        if (!cnpj || cnpj === cnpjInitial) return
        setLoadingApiCnpj(true)

        const dataRFB = await apiGetCnpj(cnpj)

        if (dataRFB) {
            const municipio = await apiMunicipio.getByIbge(dataRFB.codigo_municipio_ibge)

            setCepInitial(dataRFB.cep)
            setFornecedor(prevFornecedor => ({
                ...prevFornecedor,
                nomeFornecedor: dataRFB.nomeEmpresa,
                nomeFantasiaFornecedor: dataRFB.nomeFantasia,
                ieFornecedor: '',
                enderecoFornecedor: dataRFB.endereco,
                numeroEnderecoFornecedor: dataRFB.numero,
                complementoFornecedor: dataRFB.complemento,
                bairroFornecedor: dataRFB.bairro,
                cepFornecedor: dataRFB.cep,
                telefone1Fornecedor: dataRFB.telefone1,
                telefone2Fornecedor: dataRFB.telefone2,
                emailFornecedor: dataRFB.email,
                siglaUF: dataRFB.uf,
                idMunicipio: municipio ? municipio.idMunicipio : null
            }))
            setCnpjInitial(cnpj)
        }
        setLoadingApiCnpj(false)
    }

    const handleCepBlur = async event => {
        const value = event.target.value
        const cep = value ? value.replace(/\D/g, '') : ''

        if (!cep || cep === cepInitial) return
        setLoadingApiCep(true)

        const data = await apiGetCep(cep)

        if (data) {
            const municipio = await apiMunicipio.getByUFNome(data.uf, data.cidade)

            setFornecedor(prevFornecedor => ({
                ...prevFornecedor,
                enderecoFornecedor: data.endereco,
                bairroFornecedor: data.bairro,
                siglaUF: data.uf,
                idMunicipio: municipio ? municipio.idMunicipio : null
            }))
            setCepInitial(cep)
        }
        setLoadingApiCep(false)
    }

    const handleGetMunicipioData = params => fornecedor.siglaUF ? apiMunicipio.getByUF(fornecedor.siglaUF, params) : null

    const handleClickLocalizacao = async () => {
        try {
            const params = {
                cnpjCpf: fornecedor.cnpjCpfFornecedor,
                tipoCliFor: 'F'
            }

            const data = await getCoordenadas(params)

            setDadosMapa({ ...data, ...params })
            setOpenMapa(true)
        } catch (error) {
            showError(error)
        }
    }

    const ExtraButton = () => {
        if (fornecedor.idFornecedor && !deleteMode) {
            return <Button appearance="ghost" color="blue" startIcon={<IoLocationOutline />} onClick={handleClickLocalizacao} >
                Localização
            </Button>
        }
        return (<></>)
    }

    const { StringType, NumberType, MixedType } = Schema.Types;

    const model = Schema.Model({
        nomeFornecedor: StringType()
            .isRequired('Nome é obrigatório')
            .minLength(3, 'Nome deve conter 3 ou mais letras'),
        cnpjCpfFornecedor: MixedType()
            .isRequired(`${fornecedor.fisJurFornecedor === 'F' ? 'CPF' : 'CNPJ'} é obrigatório`)
            .addRule((value, data) => {
                if (data.fisJurFornecedor === 'F') return value.length === 11
                if (data.fisJurFornecedor === 'J') return value.length === 14
                return true
            }, `Informe o ${fornecedor.fisJurFornecedor === 'F' ? 'CPF' : 'CNPJ'} completo`)
            .addRule((value, data) => {
                if (data.fisJurFornecedor === 'F') return isCPF(value)
                if (data.fisJurFornecedor === 'J') return isCNPJ(value)
                return true
            }, `Informe um ${fornecedor.fisJurFornecedor === 'F' ? 'CPF' : 'CNPJ'} válido`),
        ieFornecedor: StringType().isRequired('I.E. é obrigatória'),
        enderecoFornecedor: StringType().isRequired('Endereço é obrigatório'),
        numeroEnderecoFornecedor: StringType().isRequired('Número é obrigatório'),
        bairroFornecedor: StringType().isRequired('Bairro é obrigatório'),
        cepFornecedor: StringType()
            .isRequired('CEP é obrigatório')
            .minLength(8, 'Informe o CEP completo com 8 dígitos'),
        siglaUF: StringType().isRequired('UF é obrigatória'),
        idMunicipio: NumberType().isRequired('Município é obrigatório'),
        telefone1Fornecedor: StringType()
            .isRequired('Telefone é obrigatório')
            .minLength(10, 'Telefone deve estar completo com DDD'),
        telefone2Fornecedor: StringType()
            .minLength(10, 'Telefone deve estar completo com DDD'),
        emailFornecedor: StringType().isEmail('Informe um endereço de e-mail válido')
    })

    return (
        <DataContainer title={`${consulta ? "Consulta" : "Cadastro"} de Fornecedores`}>
            <DataTable
                data={fornecedores}
                dataTableColumns={dataTableColumns}
                placeholderSearch='todas as colunas'
                textNewButton='Novo Fornecedor'
                hiddeNewButton={consulta}
                onClickNewButton={handleClickNew}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
            />

            <DataModal
                title={`${consulta ? "Consulta" : "Cadastro"} de Fornecedor`}
                titleIdValue={fornecedor.idFornecedor}
                deleteMode={deleteMode}
                open={openEditModal}
                sizeModal='lg'
                waiting={waiting}
                hideSubmitButton={consulta}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancel}
                customTextCancelButton={consulta ? "Fechar" : "Cancelar"}
                formValidation={model}
                formValue={fornecedor}
                formReadOnly={consulta}
                showLoader={loadingApiCnpj || loadingApiCep}
                messageLoader={
                    loadingApiCnpj ? 'Buscando dados da empresa pelo CNPJ'
                        : loadingApiCep ? 'Buscando endereço pelo CEP'
                            : undefined
                }
                ExtraButtonsLeft={<ExtraButton />}
            >
                <Grid fluid>
                    <Row>
                        <Col md={10} sm={9} xs={24} className="align-bottom">
                            <Form.Group controlId="fisJurFornecedor">
                                <Form.ControlLabel>Tipo de Pessoa</Form.ControlLabel>
                                <RadioGroup
                                    name="fisJurFornecedor"
                                    inline
                                    appearance="picker"
                                    defaultValue="J"
                                    value={fornecedor.fisJurFornecedor}
                                    onChange={handleInputChange}>
                                    <Radio
                                        value="J"
                                        disabled={fornecedor.idFornecedor && fornecedor.fisJurFornecedor === 'F'}
                                    >
                                        <Stack spacing={7} direction="row">
                                            <Icon as={PiFactory} size="1.5em" />
                                            Jurídica
                                        </Stack>
                                    </Radio>
                                    <Radio
                                        value="F"
                                        disabled={fornecedor.idFornecedor && fornecedor.fisJurFornecedor === 'J'}>
                                        <Stack spacing={7} direction="row">
                                            <Icon as={MdEmojiPeople} size="1.5em" />
                                            Física
                                        </Stack>
                                    </Radio>
                                </RadioGroup>
                            </Form.Group>
                        </Col>
                        <Col md={7} sm={8} xs={24}>
                            <Form.Group controlId="cnpjCpfFornecedor">
                                <Form.ControlLabel>{fornecedor.fisJurFornecedor === 'J' ? 'CNPJ' : 'CPF'}</Form.ControlLabel>
                                <Form.Control
                                    inputRef={cnpjCpfRef}
                                    accepter={CustomInputCnpjCpf}
                                    tipoFisJur={fornecedor.fisJurFornecedor}
                                    name="cnpjCpfFornecedor"
                                    readOnly={fornecedor.idFornecedor}
                                    autoFocus={!deleteMode && !consulta && !fornecedor.idFornecedor}
                                    onChange={handleInputChange}
                                    onBlur={handleCnpjCpfBlur}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={7} sm={7} xs={24}>
                            <Form.Group controlId="ieFornecedor">
                                <Form.ControlLabel>Inscrição Estadual</Form.ControlLabel>
                                <Form.Control
                                    name="ieFornecedor"
                                    maxLength={20}
                                    readOnly={deleteMode || consulta || fornecedor.fisJurFornecedor === 'F'}
                                    autoFocus={!deleteMode && !consulta && fornecedor.idFornecedor && fornecedor.fisJurFornecedor === 'J'}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={18} sm={24} xs={24}>
                            <Form.Group controlId="nomeFornecedor">
                                <Form.ControlLabel>Nome do Fornecedor</Form.ControlLabel>
                                <Form.Control
                                    name="nomeFornecedor"
                                    autoFocus={!deleteMode && !consulta && fornecedor.idFornecedor && fornecedor.fisJurFornecedor === 'F'}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Group controlId="nomeFantasiaFornecedor">
                                <Form.ControlLabel>Nome Fantasia</Form.ControlLabel>
                                <Form.Control
                                    name="nomeFantasiaFornecedor"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} sm={5} xs={24}>
                            <Form.Group controlId="cepFornecedor">
                                <Form.ControlLabel>CEP</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputCep}
                                    name="cepFornecedor"
                                    onChange={handleInputChange}
                                    onBlur={handleCepBlur}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={16} sm={14} xs={24}>
                            <Form.Group controlId="enderecoFornecedor">
                                <Form.ControlLabel>Endereço</Form.ControlLabel>
                                <Form.Control
                                    name="enderecoFornecedor"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} sm={5} xs={24}>
                            <Form.Group controlId="numeroEnderecoFornecedor">
                                <Form.ControlLabel>
                                    Número
                                    <Help size="xs" message="Informe o número ou SN se não houver." />
                                </Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputNumeroEndereco}
                                    name="numeroEnderecoFornecedor"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Group controlId="complementoFornecedor">
                                <Form.ControlLabel>Complemento</Form.ControlLabel>
                                <Form.Control
                                    name="complementoFornecedor"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Group controlId="bairroFornecedor">
                                <Form.ControlLabel>Bairro</Form.ControlLabel>
                                <Form.Control
                                    name="bairroFornecedor"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Group controlId="siglaUF" ref={formGroupRefUF}>
                                <Form.ControlLabel>UF</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefUF}
                                    name="siglaUF"
                                    placeholder="Selecione a UF"
                                    onChange={value => setFornecedor(prevFornecedor => ({ ...prevFornecedor, siglaUF: value, idMunicipio: '' }))}
                                    apiGetData={apiUF.getAll}
                                    apiGetInitialData={apiUF.getId}
                                    getDataObject={item => ({ value: item.siglaUF, label: `${item.siglaUF} - ${item.nomeUF}` })}
                                    reloadDataTriggerEffect={[cnpjInitial, cepInitial]}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Group controlId="idMunicipio" ref={formGroupRefMunicipio}>
                                <Form.ControlLabel>Município</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefMunicipio}
                                    name="idMunicipio"
                                    placeholder={`Selecione o Município${fornecedor.siglaUF && fornecedor.siglaUF !== '' ? '' : ' após selecionar a UF'}`}
                                    onChange={value => setFornecedor(prevFornecedor => ({ ...prevFornecedor, idMunicipio: value }))}
                                    apiGetData={handleGetMunicipioData}
                                    apiGetInitialData={apiMunicipio.getId}
                                    apiGetDataOrder={{ column: 'nomeMunicipio' }}
                                    getDataObject={item => ({ value: item.idMunicipio, label: item.nomeMunicipio })}
                                    reloadDataTriggerEffect={[fornecedor.siglaUF, cnpjInitial, cepInitial]}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={7} sm={12} xs={24}>
                            <Form.Group controlId="telefone1Fornecedor">
                                <Form.ControlLabel>Telefone 1</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputTelefone}
                                    name="telefone1Fornecedor"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={7} sm={12} xs={24}>
                            <Form.Group controlId="telefone2Fornecedor">
                                <Form.ControlLabel>Telefone 2</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputTelefone}
                                    name="telefone2Fornecedor"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={10} sm={24} xs={24}>
                            <Form.Group controlId="emailFornecedor">
                                <Form.ControlLabel>e-mail</Form.ControlLabel>
                                <Form.Control
                                    name="emailFornecedor"
                                    type="email"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="obsFornecedor">
                                <Form.ControlLabel>Observação</Form.ControlLabel>
                                <Form.Control
                                    name="obsFornecedor"
                                    accepter={CustomTextArea}
                                    rows={2}
                                    maxLength={500}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={24}>
                            <div className="rs-form-control">
                                <div className="rs-form-control-label">
                                    <Stack direction="row" spacing={15}>
                                        <span>Contatos</span>
                                        {!deleteMode && !consulta &&
                                            <Button color="cyan" appearance="ghost" size='xs' startIcon={<PlusIcon />} onClick={handleClickNewContato}>
                                                Novo Contato
                                            </Button>
                                        }
                                    </Stack>
                                </div>
                                <ContatoList
                                    consulta={consulta}
                                    hideButtons={deleteMode}
                                    onEditButtonClick={handleClickEditContato}
                                    onDeleteButtonClick={handleClickDeleteContato}
                                    contatos={!fornecedor.FornecedorContatos
                                        ? null
                                        : fornecedor.FornecedorContatos.map(item => ({
                                            nomeContato: item.nomeFornecedorContato,
                                            telefoneContato: item.telefoneFornecedorContato,
                                            emailContato: item.emailFornecedorContato,
                                            obsContato: item.obsFornecedorContato
                                        }))
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </Grid>
                <ContatoCadastro
                    deleteMode={deleteModeContato}
                    consulta={consulta}
                    open={openContato}
                    defaultValue={fornecedorContato}
                    onClose={() => setOpenContato(false)}
                    onConfirm={handleClickConfirmContato}
                />
            </DataModal >
            {!consulta &&
                <Confirm
                    open={openConfirm}
                    message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados do Fornecedor?`}
                    onClickNoButton={() => setOpenConfirm(false)}
                    onClickYesButton={handleClickYesConfirm}
                />
            }
            {!consulta && !deleteMode &&
                <Alert
                    open={fornecedorJaCadastrado !== null && fornecedorJaCadastrado !== undefined}
                    message={`Fornecedor já cadastrado com o ${fornecedor.fisJurFornecedor === 'F' ? "CPF" : "CNPJ"} ${cnpjCpfRef.current ? cnpjCpfRef.current.value : "informado"}.\n\nClique em OK para carregar os dados.`}
                    onClickButton={handleClickLoadData}
                />
            }
            {!deleteMode &&
                <Mapa
                    open={openMapa}
                    data={dadosMapa}
                    title={'Localização do Fornecedor'}
                    onClose={() => setOpenMapa(false)}
                />
            }
        </DataContainer >
    )
}

export default Fornecedor