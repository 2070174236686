import { Icon } from '@rsuite/icons';
import PropTypes from 'prop-types';
import { BsExclamationCircle } from 'react-icons/bs';
import { Button, Drawer, Loader, Stack } from 'rsuite';

import './style.css';

const Alert = ({
    open,
    message,
    waiting,
    onClickButton,
    textButton
}) => {
    return (
        <Drawer backdrop='static' size='xs' placement='bottom' open={open} style={{ height: 120 }}>
            <Drawer.Header closeButton={false} className='alert-drawer-header' >
                {message &&
                    <Drawer.Title style={{ flexGrow: 'unset' }}>
                        <Stack spacing={20}>
                            <Icon as={BsExclamationCircle} size='2em' color='orange' />
                            <div className='alert-message-container' >
                                {message}
                            </div>
                        </Stack>
                    </Drawer.Title>
                }
                {waiting
                    ? <Loader size="sm" content={<span style={{ fontSize: 16, fontWeight: 'bold' }}>Aguarde...</span>} />
                    : (
                        <Drawer.Actions>
                            <Button onClick={onClickButton} appearance="ghost" color='orange'>
                                {textButton || "OK"}
                            </Button>
                        </Drawer.Actions>
                    )
                }
            </Drawer.Header>
        </Drawer>
    )
}

Alert.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    waiting: PropTypes.bool,
    onClickYesButton: PropTypes.func,
    onClickNoButton: PropTypes.func,
}

export default Alert
