import { useEffect, useRef, useState } from "react";
import { CheckTree, Col, Form, Grid, Row, Schema, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { PopoverMessageContainer, showMessage } from "../../components/Message";
import TextodDestacado from "../../components/TextoDestacado";

import useApiMenu from "../../hooks/useApiMenu";
import useApiPerfil from "../../hooks/useApiPerfil";
import useScreenSizeComparison from "../../hooks/useScreenSizeComparison";

import './style.css';

const Perfil = () => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const { save, destroy, getId, getWithTasks } = useApiPerfil();
    const apiMenu = useApiMenu();

    const [perfis, setPerfis] = useState({});
    const [perfil, setPerfil] = useState({});

    const [menuData, setMenuData] = useState();
    const [taskData, setTaskData] = useState();

    const [menuValue, setMenuValue] = useState();
    const [taskValue, setTaskValue] = useState();

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [deleteMode, setDeleteMode] = useState(false)
    const [displayMode, setDisplayMode] = useState(true)

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    const geraMenuData = (menuItems, idMenuParent) => {
        const dataRetorno = []
        const auxMenuItems = idMenuParent ? menuItems.filter(c => c.menu === idMenuParent) : menuItems.filter(c => !c.menu)

        for (let index = 0; index < auxMenuItems.length; index++) {
            const menuItem = auxMenuItems[index];

            const data = {
                value: menuItem.idMenu,
                label: !menuItem.menu ? "Todas as telas" : menuItem.text,
                icon: menuItem.icon,
                isMenu: !menuItem.path
            }

            if (!menuItem.path) {
                const children = geraMenuData(menuItems, menuItem.idMenu)
                data.children = children
            }

            dataRetorno.push(data)
        }

        return dataRetorno
    }

    const getPerfis = async () => {
        if (!dataTableParams) return setPerfis({})
        setLoading(true)

        try {
            const data = await getWithTasks({ ...dataTableParams })
            setPerfis(data)
        } catch (error) {
            setPerfis({});
            showError(error)
        }
        setLoading(false);
    }

    const getMenuTask = async () => {
        setLoading(true)

        try {
            const menuList = await apiMenu.getMenuList()
            const dataMenu = geraMenuData(menuList)
            setMenuData(dataMenu)

            const taskList = await apiMenu.getTaskList()
            const dataTask = [{ label: 'Todas as tarefas', value: 'tarefas_todas', isMenu: true }]
            dataTask[0].children = taskList.map(c => ({ label: c.text, value: c.idMenu, isMenu: false }))
            setTaskData(dataTask)
        } catch (error) {
            showError(error)
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => await getMenuTask())()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        (async () => await getPerfis())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (id, isDelete, isDisplay) => {
        try {
            const data = await getId(id)
            setPerfilData(data)
            setDeleteMode(isDelete)
            setDisplayMode(isDisplay)
            setOpenEditModal(true)
        } catch (error) {
            showError(error)
        }
    }
    const setPerfilData = data => {
        setPerfil(data)
        setMenuValue(data.PerfilItems.filter(c => !c.isTask).map(c => c.idMenu))
        setTaskValue(data.PerfilItems.filter(c => c.isTask).map(c => c.idMenu))
    }

    const handleClickNew = () => {
        setPerfilData({
            idPerfil: null,
            nomePerfil: '',
            PerfilItems: []
        });
        setDisplayMode(false)
        setDeleteMode(false)
        setOpenEditModal(true)
    }
    const handleClickEdit = id => editData(id)
    const handleClickDelete = id => editData(id, true)
    const handleClickDisplay = id => editData(id, false, true)

    const hiddenCol_sm = isScreenSizeBelow('sm')

    const dataTableColumns = {
        editButtonColumn: {
            dataKey: 'idPerfil',
            onClick: handleClickEdit,
            hidden: rowData => rowData.perfilPadrao
        },
        deleteButtonColumn: {
            dataKey: 'idPerfil',
            onClick: handleClickDelete,
            hidden: rowData => rowData.perfilPadrao
        },
        displayButtonColumn: {
            dataKey: 'idPerfil',
            onClick: handleClickDisplay,
            hidden: rowData => !rowData.perfilPadrao
        },
        columns: [
            {
                dataKey: 'idPerfil',
                headerCell: 'Id',
                sortable: true,
                align: 'center',
                width: 70,
                hidden: hiddenCol_sm
            }, {
                dataKey: 'nomePerfil',
                headerCell: 'Nome do Perfil',
                flexGrow: 1,
                sortable: true,
                fullText: true
            }
        ],
        expandedRow: {
            height: 110,
            dataKey: 'idPerfil',
            rowKey: 'idPerfil',
            render: rowData => (
                <div className="perfil-row-expanded-content">
                    <p className="bold">Permissões concedidas</p>
                    <TextodDestacado text={rowData.tarefasPerfil} word={dataTableParams.searchText} />
                </div>
            )
        }
    }

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget.name

        setPerfil(prevPerfil => ({
            ...prevPerfil,
            [fieldName]: value
        }));
    }

    const getItemsCheckTree = element => {
        const data = element.isMenu ? [] : [element.value]

        if (element.children) {
            for (let index = 0; index < element.children.length; index++) {
                const item = element.children[index];
                const children = getItemsCheckTree(item)
                data.push(...children)
            }
        }
        return data
    }

    const handleCheckMenuTreeChange = value => {
        if (deleteMode || displayMode) return false
        menuPerfilItemsRef.current.close()
        setMenuValue(value)
    }
    const handleCheckTaskTreeChange = value => {
        if (deleteMode || displayMode) return false
        taskPerfilItemsRef.current.close()
        setTaskValue(value)
    }
    const handleCheckTreeSelect = element => {
        if (deleteMode || displayMode) return false

        const items = getItemsCheckTree(element)

        //Itens que não estão nos valores
        const newPerfilItems = perfil.PerfilItems.filter(c => !items.includes(c.idMenu))
        if (element.check) newPerfilItems.push(...items.map(c => ({ idMenu: c })))

        const newPerfil = perfil
        newPerfil.PerfilItems = newPerfilItems

        setPerfil(newPerfil)
    }

    const { StringType, ArrayType } = Schema.Types;

    const modelErrorMessage = {
        nomePerfil: 'Nome do Perfil é obrigatório',
        perfilItems: 'Obrigatório Perfil ter alguma permissão (tela ou tarefa)',
    }

    const nomePerfilRef = useRef()
    const taskPerfilItemsRef = useRef()
    const menuPerfilItemsRef = useRef()

    const model = Schema.Model({
        nomePerfil: StringType().isRequired(),
        perfilItems: ArrayType().addRule((value, data) => {
            return value.length > 0
        })
    });

    const handleClickSubmit = () => {
        nomePerfilRef.current.close()
        taskPerfilItemsRef.current.close()
        menuPerfilItemsRef.current.close()

        const result = model.check({ nomePerfil: perfil.nomePerfil, perfilItems: perfil.PerfilItems })

        if (result.perfilItems.hasError || result.nomePerfil.hasError) {
            if (result.nomePerfil.hasError) nomePerfilRef.current.open()
            if (result.perfilItems.hasError) {
                taskPerfilItemsRef.current.open()
                menuPerfilItemsRef.current.open()
            }
            return false
        }

        setOpenConfirm(true)
    }
    const handleClickCancel = () => {
        setOpenEditModal(false)
        setWaiting(false)
        setDeleteMode(false)
        setDisplayMode(true)
    }
    const handleClickYes = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await destroy(perfil.idPerfil) : await save(perfil)

            showSucess()
            handleClickCancel()
            await getPerfis()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idPerfil' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    return (
        <DataContainer title={"Cadastro de Perfis de Acesso"}>
            <DataTable
                data={perfis}
                dataTableColumns={dataTableColumns}
                placeholderSearch='nome do perfil ou nome da tarefa'
                textNewButton='Novo Perfil'
                onClickNewButton={handleClickNew}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
                xxl={12}
                xl={15}
                lg={18}
            />

            <DataModal
                title='Cadastro de Perfil de Acesso'
                titleIdValue={perfil.idPerfil}
                deleteMode={deleteMode}
                open={openEditModal}
                sizeModal='lg'
                waiting={waiting}
                onClickSubmitButton={handleClickSubmit}
                onClickCancelButton={handleClickCancel}
                customTextCancelButton={displayMode ? "Fechar" : "Cancelar"}
                hideSubmitButton={displayMode}
                defaultValue={perfil}
                formReadOnly={displayMode}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="nomePerfil">
                                <Form.ControlLabel>Nome do Perfil</Form.ControlLabel>
                                <PopoverMessageContainer type="error" ref={nomePerfilRef} controlId="nomePerfil" message={modelErrorMessage.nomePerfil}>
                                    <Form.Control
                                        name="nomePerfil"
                                        autoFocus={!deleteMode && !displayMode}
                                        value={perfil.nomePerfil}
                                        onChange={handleInputChange}
                                    />
                                </PopoverMessageContainer>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} md={12}>
                            <Form.Group controlId="taskPerfilItems">
                                <Form.ControlLabel>Permitir acesso as tarefas de tela</Form.ControlLabel>
                                <PopoverMessageContainer type="error" ref={taskPerfilItemsRef} controlId="taskPerfilItems" message={modelErrorMessage.perfilItems}>
                                    <Form.Control
                                        classPrefix="perfil-checktree"
                                        name="taskPerfilItems"
                                        accepter={CheckTree}
                                        defaultExpandAll
                                        data={taskData}
                                        value={taskValue}
                                        onChange={handleCheckTaskTreeChange}
                                        onSelect={handleCheckTreeSelect}
                                    />
                                </PopoverMessageContainer>
                            </Form.Group>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Group controlId="menuPerfilItems">
                                <Form.ControlLabel>Permitir acesso aos itens do menu</Form.ControlLabel>
                                <PopoverMessageContainer type="error" ref={menuPerfilItemsRef} controlId="menuPerfilItems" message={modelErrorMessage.perfilItems}>
                                    <Form.Control
                                        classPrefix="perfil-checktree"
                                        name="menuPerfilItems"
                                        accepter={CheckTree}
                                        defaultExpandAll
                                        showIndentLine
                                        data={menuData}
                                        value={menuValue}
                                        onChange={handleCheckMenuTreeChange}
                                        onSelect={handleCheckTreeSelect}
                                        renderTreeNode={nodeData => {
                                            return (
                                                <div className="perfil-checktree-item">
                                                    {nodeData.icon && <img src={nodeData.icon} alt="icon" />}
                                                    {nodeData.label}
                                                </div>
                                            );
                                        }}
                                    />
                                </PopoverMessageContainer>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataModal>
            <Confirm
                open={openConfirm}
                message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados do Perfil de Acesso?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYes}
            />
        </DataContainer >
    )
}

export default Perfil