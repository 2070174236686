import { useEffect, useState } from "react";
import { Col, Form, Grid, Row, Schema, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/Message";

import useApiLaboratorio from "../../hooks/useApiLaboratorio";
import useScreenSizeComparison from "../../hooks/useScreenSizeComparison";

const Laboratorio = () => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const { getAll, getId, save, destroy } = useApiLaboratorio();

    const [laboratorios, setLaboratorios] = useState({})
    const [laboratorio, setLaboratorio] = useState({})

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [deleteMode, setDeleteMode] = useState(false)

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    const getLaboratorios = async () => {
        if (!dataTableParams) return setLaboratorios({})
        setLoading(true)

        try {
            const data = await getAll({ ...dataTableParams })
            setLaboratorios(data)
        } catch (error) {
            setLaboratorios({})
            showError(error)
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => await getLaboratorios())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (id, isDelete) => {
        try {
            const data = await getId(id)
            setLaboratorio(data)
            setDeleteMode(isDelete)
            setOpenEditModal(true)
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNewButton = () => {
        setLaboratorio({
            idLaboratorio: null,
            nomeLaboratorio: '',
            telefoneLaboratorio: ''
        });
        setDeleteMode(false);
        setOpenEditModal(true);
    }
    const handleClickEditButton = id => editData(id)
    const handleClickDeleteButton = id => editData(id, true)

    const handleClickCancelButton = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }

    const handleClickYesButton = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await destroy(laboratorio.idLaboratorio) : await save(laboratorio)

            showSucess()
            handleClickCancelButton()
            await getLaboratorios()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idLaboratorio' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const hiddenCol_sm = isScreenSizeBelow('sm')

    const dataTableColumns = {
        editButtonColumn: {
            dataKey: 'idLaboratorio',
            onClick: handleClickEditButton
        },
        deleteButtonColumn: {
            dataKey: 'idLaboratorio',
            onClick: handleClickDeleteButton
        },
        columns: [
            {
                dataKey: 'idLaboratorio',
                headerCell: 'Id',
                align: 'center',
                width: 100,
                sortable: true,
                hidden: hiddenCol_sm
            }, {
                dataKey: 'nomeLaboratorio',
                headerCell: 'Nome',
                sortable: true,
                flexGrow: 1
            }, {
                dataKey: 'telefoneLaboratorio',
                headerCell: 'Telefone de Emergência',
                width: 180,
                sortable: true,
                hidden: hiddenCol_sm
            }
        ]
    }

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        nomeLaboratorio: StringType().isRequired('Nome é obrigatório'),
        telefoneLaboratorio: StringType().isRequired('Telefone de emergência é obrigatório')
    });

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget.name
        const fieldValue = ['telefoneLaboratorio'].includes(fieldName) ? value.replace(/[^0-9 ()-]/g, '') : value

        setLaboratorio(prevLaboratorio => ({
            ...prevLaboratorio,
            [fieldName]: fieldValue
        }))
    }

    return (
        <DataContainer title={"Cadastro de Laboratórios"}>
            <DataTable
                data={laboratorios}
                dataTableColumns={dataTableColumns}
                placeholderSearch='todas as colunas'
                textNewButton='Novo Laboratório'
                onClickNewButton={handleClickNewButton}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
                xxl={15}
                xl={18}
                lg={20}
            />

            <DataModal
                title='Cadastro de Laboratório'
                titleIdValue={laboratorio.idLaboratorio}
                deleteMode={deleteMode}
                open={openEditModal}
                sizeModal='md'
                waiting={waiting}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancelButton}
                formValidation={model}
                formValue={laboratorio}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="nomeLaboratorio">
                                <Form.ControlLabel>Nome do Laboratório</Form.ControlLabel>
                                <Form.Control
                                    name="nomeLaboratorio"
                                    autoFocus={!deleteMode}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="telefoneLaboratorio">
                                <Form.ControlLabel>Telefone de Emergência</Form.ControlLabel>
                                <Form.Control
                                    name="telefoneLaboratorio"
                                    maxLength={15}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataModal>
            <Confirm
                open={openConfirm}
                message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados do Laboratório?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYesButton}
            />
        </DataContainer >
    )
}

export default Laboratorio