import { useRef } from 'react';
import { Button, Col, Divider, Form, Loader, Panel, Stack, Text } from 'rsuite';

import './style.css';

const DataPanel = ({
    title,
    customColorSubmitButton,
    customTextSubmitButton,
    onClickSubmitButton,
    formValidation,
    formValidationTrigger = 'change',
    formValue,
    formReadOnly,
    formOnChange,
    waiting,
    loading,
    showLoader,
    messageLoader,
    children,
    bodyHeight,
    ExtraButtonsLeft,
    ExtraButtonsRight,
    alignContainer = 'center',
    className,
    xxl,
    xl,
    lg,
    md,
    sm,
    xs
}) => {

    const formRef = useRef()

    const handleSubmitButton = () => {
        if (formValidation && !formRef.current.check()) return false;
        onClickSubmitButton()
    }

    return (
        <div className={`data-panel-container${alignContainer ? `-${alignContainer}` : 'center'}`}>
            <Col
                xxl={xxl || 24}
                xl={xl || 24}
                lg={lg || 24}
                md={md || 24}
                sm={sm || 24}
                xs={xs || 24}
            >
                <Panel
                    bordered
                    shaded
                    className={`data-panel ${className ? className : ''}`}
                >
                    {showLoader &&
                        <div className="data-panel-loader-backdrop">
                            <Loader size="md" vertical />
                            {messageLoader &&
                                <div className='data-panel-loader-message'>
                                    {messageLoader}
                                </div>
                            }
                        </div>
                    }

                    {title &&
                        <>
                            <div className='data-panel-header'>
                                <h4 className='data-panel-title'>
                                    <Stack spacing={25}>
                                        <Text>{title}</Text>
                                    </Stack>
                                </h4>
                            </div>
                            <Divider className='data-panel-title-divider' />
                        </>
                    }
                    <div className={`data-panel-body ${!title ? 'no-title' : ''}`} style={bodyHeight ? { height: bodyHeight } : null}>
                        {loading
                            ? <Loader size="lg" center />
                            : <Form
                                fluid
                                ref={formRef}
                                model={formValidation}
                                checkTrigger={formValidationTrigger}
                                formValue={formValue || undefined}
                                readOnly={formReadOnly}
                                onChange={formOnChange}
                            >
                                {children}
                            </Form>
                        }
                    </div>
                    <Divider className='data-panel-footer-divider' style={loading ? { display: 'none' } : null} />
                    <div className='data-panel-footer' style={loading ? { display: 'none' } : null}>
                        {waiting
                            ? <Stack justifyContent='flex-end' alignItems='center'>
                                <Loader size="sm" content={<span style={{ fontSize: 16, fontWeight: 'bold' }}>Aguarde...</span>} />
                            </Stack>
                            : <Stack justifyContent='space-between' alignItems='center'>
                                <Stack justifyContent='flex-start' alignItems='center'>
                                    {ExtraButtonsLeft}
                                </Stack>
                                <Stack justifyContent='flex-end' alignItems='center' spacing={10}>
                                    {ExtraButtonsRight}
                                    <Button onClick={handleSubmitButton} appearance="ghost" color={customColorSubmitButton ? customColorSubmitButton : 'green'}>
                                        {customTextSubmitButton ? customTextSubmitButton : "Gravar"}
                                    </Button>
                                </Stack>
                            </Stack>
                        }
                    </div>
                </Panel>
            </Col>
        </div >
    )
}

export default DataPanel
