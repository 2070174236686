import { Icon } from '@rsuite/icons';
import { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, IconButton, Modal, Stack } from 'rsuite';

import { FaEraser, FaSignature, FaSpinner, FaUpload } from 'react-icons/fa';

import './style.css';

const Assinatura = ({
    value,
    readOnly,
    height,
    width,
    marginBottom = 18,
    onChange,
    showSendButton = true,
    showSignButton = true,
    showClearButton = true
}) => {

    const [imgSign, setImgSign] = useState(value)
    const [objSign, setObjSign] = useState()
    const [openSign, setOpenSign] = useState(false)
    const [uploading, setUploading] = useState(false)

    const fileInputRef = useRef()

    useEffect(() => {
        if (openSign && objSign && imgSign) {
            objSign.fromDataURL(imgSign, 'image/png')
        }
    }, [openSign, objSign, imgSign])

    const handleModalClose = () => setOpenSign(false);

    const handleClickSend = () => {
        fileInputRef.current.click()
    }
    const handleClickSign = () => setOpenSign(true)
    const handleClickClear = () => {
        setImgSign(null)
        if (onChange) onChange(null)
    }

    const handleClickPadCancel = () => setOpenSign(false)
    const handleClickPadClear = () => objSign.clear()
    const handleClickPadConfirm = () => {
        const value = (objSign.isEmpty() ? null : objSign.getTrimmedCanvas().toDataURL('image/png'))
        setImgSign(value)
        if (onChange) onChange(value)
        setOpenSign(false)
    }

    const handleFileChange = event => {
        const file = event.target.files[0]

        if (file) {
            setUploading(true)
            const reader = new FileReader()
            reader.onload = event => {
                const result = event.target.result
                setImgSign(result)
                setUploading(false)
            }
            reader.readAsDataURL(file)
        }
    };

    return (
        <>
            <div
                className="assinatura-container"
                style={{
                    height: height || 300,
                    width: width || '100%',
                    paddingLeft: showSendButton || showSignButton || showClearButton ? 120 : undefined,
                    marginBottom: marginBottom
                }}
            >
                {imgSign && <img src={imgSign} alt='assinatura' />}
                {!readOnly &&
                    <div className="assinatura-buttons-container">
                        {showSendButton &&
                            <>
                                {
                                    uploading
                                        ? <Button className='assinatura-button-waiting' disabled ><Icon as={FaSpinner} pulse /> </Button>
                                        : <IconButton icon={<Icon as={FaUpload} />} onClick={handleClickSend}>Enviar</IconButton>
                                }
                            </>
                        }
                        {showSignButton &&
                            <>
                                {
                                    uploading
                                        ? <Button className='assinatura-button-waiting' disabled><Icon as={FaSpinner} pulse /> </Button>
                                        : <IconButton icon={<Icon as={FaSignature} />} onClick={handleClickSign}>Assinar</IconButton>
                                }
                            </>
                        }
                        {showClearButton &&
                            <>
                                {
                                    uploading
                                        ? <Button className='assinatura-button-waiting' disabled><Icon as={FaSpinner} pulse /> </Button>
                                        : <IconButton icon={<Icon as={FaEraser} />} onClick={handleClickClear}>Limpar</IconButton>
                                }
                            </>
                        }
                    </div>
                }
            </div >

            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
            />

            <Modal
                className='assinatura-modal'
                backdropClassName='assinatura-modal-backdrop'
                style={{
                    zIndex: 10000, // estilo para o wraper
                    width: '100%'
                }}
                overflow
                backdrop="static"
                open={openSign}
                onClose={handleModalClose}
            >
                <Modal.Header>
                    <Modal.Title>Desenhe sua assinatura no espaço abaixo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SignatureCanvas
                        ref={value => setObjSign(value)}
                        penColor="black"
                        canvasProps={{ className: 'assinatura-canvas' }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-end'
                        spacing={10}
                    >
                        <Button appearance='ghost' color='green' onClick={handleClickPadConfirm}>Confirmar</Button>
                        <Button appearance='ghost' color='cyan' onClick={handleClickPadClear}>Limpar</Button>
                        <Button appearance='ghost' color='orange' onClick={handleClickPadCancel}>Cancelar</Button>
                    </Stack>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default Assinatura
