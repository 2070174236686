import ApiService from '../services/api.js';

const useApiEmpresa = () => {

    const apiService = new ApiService()
    const api = apiService.api

    const resource = '/empresa'

    const getEmpresa = async () => {
        const response = await api.get(`${resource}`)
        return response.data;
    }

    const save = empresa => {
        return api.post(resource, empresa)
    }

    return {
        getEmpresa,
        save
    }
}

export default useApiEmpresa
