import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Button, Divider, Form, Loader, Modal, Stack, Tag, Text } from 'rsuite';


import './style.css';

const DataModal = ({
    title,
    titleIdValue,
    deleteMode,
    open,
    customTextSubmitButton,
    customTextCancelButton,
    onClickSubmitButton,
    onClickCancelButton,
    hideSubmitButton,
    formValidation,
    formValidationTrigger = 'change',
    formValue,
    formReadOnly,
    formOnChange,
    waiting,
    showLoader,
    messageLoader,
    sizeModal = 'md',
    children,
    bodyHeight,
    ExtraButtonsLeft,
    ExtraButtonsRight,
    className,
    ...rest
}) => {

    const formRef = useRef()

    const handleSubmitButton = () => {
        if (formValidation && !deleteMode && !formRef.current.check()) return false;
        onClickSubmitButton()
    }

    return (
        <Modal
            overflow
            backdrop='static'
            size={sizeModal}
            open={open}
            onClose={onClickCancelButton}
            className={`data-modal${className ? ' ' + className : ''}`}
            {...rest}
        >

            {showLoader &&
                <div className="data-modal-loader-backdrop">
                    <Loader size="md" vertical />
                    {messageLoader &&
                        <div className='data-modal-loader-message'>
                            {messageLoader}
                        </div>
                    }
                </div>
            }

            {title &&
                <>
                    <Modal.Header className='data-modal-header'>
                        <Modal.Title>
                            <Stack spacing={25}>
                                <Text>{title}</Text>
                                {titleIdValue && <Tag className='data-modal-title-id-value' color={deleteMode ? "red" : "blue"} size='lg'>Id: {titleIdValue}</Tag>}
                            </Stack>
                        </Modal.Title>
                    </Modal.Header>
                    <Divider className='data-modal-title-divider' />
                </>
            }
            <Modal.Body className='data-modal-body' style={bodyHeight ? { height: bodyHeight } : null}>
                <Form
                    fluid
                    ref={formRef}
                    model={formValidation}
                    checkTrigger={formValidationTrigger}
                    formValue={formValue || undefined}
                    readOnly={formReadOnly || deleteMode}
                    onChange={formOnChange}
                >
                    {children}
                </Form>

            </Modal.Body>
            <Divider className='data-modal-footer-divider' />
            <Modal.Footer>
                {waiting
                    ? <Stack justifyContent='flex-end' alignItems='center'>
                        <Loader size="sm" content={<span style={{ fontSize: 16, fontWeight: 'bold' }}>Aguarde...</span>} />
                    </Stack>
                    : <Stack justifyContent='space-between' alignItems='center'>
                        <Stack justifyContent='flex-start' alignItems='center'>
                            {ExtraButtonsLeft}
                        </Stack>
                        <Stack justifyContent='flex-end' alignItems='center' spacing={10}>
                            {ExtraButtonsRight}
                            {
                                !hideSubmitButton &&
                                <Button onClick={handleSubmitButton} appearance="ghost" color={deleteMode ? 'red' : 'green'}>
                                    {customTextSubmitButton ? customTextSubmitButton : (deleteMode ? "Excluir" : "Gravar")}
                                </Button>
                            }
                            <Button onClick={onClickCancelButton} appearance="ghost" color={'orange'}>
                                {customTextCancelButton || 'Cancelar'}
                            </Button>
                        </Stack>
                    </Stack>
                }
            </Modal.Footer>
        </Modal >
    )
}

DataModal.propTypes = {
    title: PropTypes.string,
    titleIdValue: PropTypes.any,
    open: PropTypes.bool,
    textSubmitButton: PropTypes.string,
    textCancelButton: PropTypes.string,
    colorSubmitButton: PropTypes.string,
    colorCancelButton: PropTypes.string,
    onClickSubmitButton: PropTypes.func,
    onClickCancelButton: PropTypes.func,
    hideSubmitButton: PropTypes.bool,
    formValidation: PropTypes.object,
    waiting: PropTypes.bool
}

export default DataModal
