import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import React, { useState } from 'react';
import { Text } from 'rsuite';

const MarcadorDestino = ({
    coordenadas,
    endereco
}) => {
    const [infowindowOpen, setInfowindowOpen] = useState(true);
    const [markerRef, marker] = useAdvancedMarkerRef();

    return (
        <>
            <AdvancedMarker
                ref={markerRef}
                onClick={() => setInfowindowOpen(true)}
                position={coordenadas}
                title={endereco.nome}
            />
            {infowindowOpen &&
                <InfoWindow
                    className='mapa-marker'
                    anchor={marker}
                    onCloseClick={() => setInfowindowOpen(false)}
                    headerContent={<Text className='mapa-marker' weight='extrabold'>{endereco.nome}</Text>}
                >
                    <Text>{endereco.endereco}</Text>
                    <Text>{endereco.bairro}</Text>
                    <Text>{endereco.cidade} - {endereco.uf}</Text>
                    <Text>{endereco.cep}</Text>
                </InfoWindow>
            }
        </>
    )
}

export default MarcadorDestino