import { Map } from '@vis.gl/react-google-maps'
import React, { useEffect, useState } from 'react'
import { Button, Divider, Modal, Text } from 'rsuite'

import Directions from './Directions.jsx'
import MarcadorDestino from './MarcadorDestino.jsx'
import MarcadorOrigem from './MarcadorOrigem.jsx'

import './style.css'

const Mapa = ({
    data,
    title,
    open,
    onClose,
    ...rest }) => {

    const [rota, setRota] = useState(false)

    useEffect(() => setRota(false), [open])

    const coordenadasDestino = data?.destino?.coordenadas
    const enderecoDestino = data?.destino?.endereco

    const coordenadasOrigem = data?.origem?.coordenadas
    const enderecoOrigem = data?.origem?.endereco

    return (
        <Modal
            overflow
            backdrop='static'
            open={open}
            onClose={onClose}
            backdropClassName='mapa-modal-backdrop'
            className='mapa-modal-dialog'
            {...rest}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Text>{title}</Text>
                </Modal.Title>
            </Modal.Header>
            <Divider className='mapa-modal-divider' />
            <Modal.Body>
                {coordenadasDestino && open &&
                    <Map
                        mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}
                        style={{ width: '100%', height: '100%' }}
                        defaultCenter={coordenadasDestino}
                        defaultZoom={16}
                        fullscreenControl={false}
                    >
                        {console.log('carregou o map')}
                        <MarcadorDestino coordenadas={coordenadasDestino} endereco={enderecoDestino} />
                        {rota &&
                            <>
                                <Directions data={data} />
                                <MarcadorOrigem coordenadas={coordenadasOrigem} endereco={enderecoOrigem} />
                            </>
                        }
                    </Map>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    appearance='ghost'
                    color='green'
                    onClick={() => setRota(true)}
                    disabled={rota}
                >
                    Exibir Rotas
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Mapa
