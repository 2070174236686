import ApiService from '../services/api.js';

const useApiUsuario = () => {

    const apiService = new ApiService()
    const api = apiService.api

    const resource = '/usuario'

    const getAll = async ({ pageNumber, itemsPerPage, sortColumn, sortType, searchText }) => {
        const response = await api.get(resource, {
            params: {
                pageNumber,
                itemsPerPage,
                sortColumn,
                sortType,
                searchText
            }
        })
        return response.data;
    }

    const getId = async id => {
        const response = await api.get(`${resource}/${id}`)
        return response.data;
    }

    const save = usuario => {
        if (!usuario.idUsuario) {
            return api.post(resource, usuario)
        } else {
            return api.patch(`${resource}/${usuario.idUsuario}`, usuario)
        }
    }

    const destroy = id => api.delete(`${resource}/${id}`)

    const resetPassword = id => api.patch(`${resource}/resetpassword/${id}`)

    const verifyTaskPermission = async taskName => {
        const response = await api.get(`${resource}/verifytaskpermission/${taskName}`)
        return response.data
    }

    return {
        getAll,
        getId,
        resetPassword,
        save,
        destroy,
        verifyTaskPermission
    }
}

export default useApiUsuario
