import { APIProvider } from '@vis.gl/react-google-maps';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Routes } from 'react-router-dom';

import { AuthProvider } from './contexts/authProvider';

import createOpenRoutes from './routes/OpenRoutes';
import createPrivateRoutes from './routes/PrivateRoutes';

const App = () => {
  const queryClient = new QueryClient();

  const openRoutes = createOpenRoutes();
  const privateRoutes = createPrivateRoutes();

  return (
    <QueryClientProvider client={queryClient}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              {openRoutes}
              {privateRoutes}
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </APIProvider>
    </QueryClientProvider>
  )
}

export default App;