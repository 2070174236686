import { useEffect, useRef, useState } from "react";
import { Col, Form, Grid, Radio, RadioGroup, Row, Schema, Text, useToaster } from "rsuite";

import Alert from "../../components/Alert";
import Confirm from "../../components/Confirm";
import CustomTextArea from "../../components/CustomTextArea";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/Message";

import CustomInputDate from "../../components/CustomInputDate";
import useApiFeriado from "../../hooks/useApiFeriado";
import useScreenSizeComparison from "../../hooks/useScreenSizeComparison";
import { dateFromString, formatDate } from "../../services/utils";

const Feriado = () => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const { save, destroy, getAll, getOne } = useApiFeriado()

    const currentYear = (new Date()).getFullYear()

    const dataFeriadoRef = useRef()

    const [feriados, setFeriados] = useState({})
    const [feriado, setFeriado] = useState({})
    const [feriadoJaCadastrado, setFeriadoJaCadastrado] = useState()

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)

    const [editMode, setEditMode] = useState("V")

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${editMode === 'D' ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    const getFeriados = async () => {
        if (!dataTableParams) return setFeriados({})
        setLoading(true)

        try {
            if (!dataTableParams.exibirSoDoAno) dataTableParams.exibirSoDoAno = 'S'

            const data = await getAll({ ...dataTableParams })
            setFeriados(data)
        } catch (error) {
            setFeriados({})
            showError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        (async () => await getFeriados())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (dataFeriado, modoEdicao) => {
        try {
            const data = await getOne(dataFeriado)

            data.dataFeriado = dateFromString(data.dataFeriado)
            data.obsFeriado = !data.obsFeriado ? '' : data.obsFeriado

            setEditMode(modoEdicao)
            setFeriado(data)
            setOpenEditModal(true)
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNew = () => {
        setFeriado({
            dataFeriado: '',
            nomeFeriado: '',
            obsFeriado: '',
            tipoFeriado: ''
        })
        setFeriadoJaCadastrado(null)
        setEditMode("I")
        setOpenEditModal(true)
    }
    const handleClickEdit = dataFeriado => editData(dataFeriado, "U")
    const handleClickDelete = dataFeriado => editData(dataFeriado, "D")
    const handleClickView = dataFeriado => editData(dataFeriado, "V")

    const handleClickCancel = () => {
        setEditMode("V")
        setOpenEditModal(false)
        setWaiting(false)
    }
    const handleClickYesConfirm = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            if (editMode === 'D') await destroy(feriado.dataFeriado)
            else await save(feriado, (editMode === 'U'))

            showSucess()
            handleClickCancel()
            await getFeriados()
        } catch (error) {
            showError(error)
        }
    }
    const handleClickLoadData = async () => {
        setFeriado(feriadoJaCadastrado)
        setFeriadoJaCadastrado(null)

        if (feriadoJaCadastrado.feriadoPadrao) {
            setEditMode("V")
        } else {
            setEditMode('U')
        }
    }

    const hiddenCol_xl = isScreenSizeBelow('xl')
    const hiddenCol_lg = isScreenSizeBelow('lg')
    const hiddenCol_md = isScreenSizeBelow('md')

    const dataTableColumns = {
        displayButtonColumn: {
            dataKey: 'dataFeriado',
            onClick: handleClickView,
            hidden: rowData => !rowData.feriadoPadrao
        },
        editButtonColumn: {
            dataKey: 'dataFeriado',
            onClick: handleClickEdit,
            hidden: rowData => rowData.feriadoPadrao
        },
        deleteButtonColumn: {
            dataKey: 'dataFeriado',
            onClick: handleClickDelete,
            hidden: rowData => rowData.feriadoPadrao
        },
        columns: [
            {
                dataKey: 'dataFeriado',
                headerCell: 'Data',
                align: 'center',
                width: 100,
                sortable: true,
                customCellContent: rowData => formatDate(rowData.dataFeriado)
            }, {
                dataKey: 'nomeFeriado',
                headerCell: 'Nome',
                sortable: true,
                fullText: true,
                flexGrow: 2
            }, {
                dataKey: 'descricaoTipoFeriado',
                headerCell: 'Tipo',
                sortable: true,
                width: 150,
                hidden: hiddenCol_md,
            }, {
                dataKey: 'obsFeriado',
                headerCell: 'Observação',
                sortable: true,
                fullText: true,
                width: hiddenCol_xl ? 200 : 400,
                hidden: hiddenCol_lg
            }
        ]
    }

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget ? event.currentTarget.name : event

        const newValue = value[fieldName]

        setFeriado(prevFeriado => ({
            ...prevFeriado,
            [fieldName]: newValue
        }))
    }

    const handleDataFeriadoBlur = async event => {
        const data = dateFromString(event.target.value)

        if (data) {
            try {
                //Se der erro é pq não encontrou o cnpj no cadastro de fornecedores
                const dataCad = await getOne(data)

                setFeriadoJaCadastrado(dataCad)
                return false
            } catch { }
        }
    }

    const ExtraButtonDataTable = () => {
        return (
            <RadioGroup
                inline
                appearance="picker"
                defaultValue={dataTableParams?.exibirSoDoAno || 'S'}
                onChange={value => setDataTableParams(prevParams => ({ ...prevParams, exibirSoDoAno: value }))}
            >
                <Text style={{ paddingLeft: 10, alignContent: 'center' }}>Exibir: </Text>
                <Radio value="N">Todos</Radio>
                <Radio value="S">{`Só os de ${currentYear} e de ${currentYear + 1}`}</Radio>
            </RadioGroup>
        )
    }

    const { StringType, DateType } = Schema.Types;

    const model = Schema.Model({
        dataFeriado: DateType('Informe uma data válida')
            .isRequired('Data do Feriado é obrigatória')
            .min(new Date('01/01/0001'), 'Informe uma data válida'),
        nomeFeriado: StringType()
            .isRequired('Nome é obrigatório')
            .minLength(3, 'Nome deve conter 3 ou mais letras'),
    })

    return (
        <DataContainer title="Cadastro de Feriado">
            <DataTable
                data={feriados}
                dataTableColumns={dataTableColumns}
                placeholderSearch='todas as colunas'
                textNewButton='Novo Feriado'
                onClickNewButton={handleClickNew}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(prevParams => ({ ...prevParams, ...params }))}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
                ExtraButtons={<ExtraButtonDataTable />}
            />

            <DataModal
                title={`Cadastro de Feriado`}
                deleteMode={editMode === "D"}
                open={openEditModal}
                sizeModal='lg'
                waiting={waiting}
                hideSubmitButton={editMode === "V"}
                customTextCancelButton={editMode === "V" ? "Fechar" : "Cancelar"}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancel}
                formValidation={model}
                formValue={feriado}
                formReadOnly={editMode === "V"}
                formOnChange={handleInputChange}
            >
                <Grid fluid>
                    <Row>
                        <Col lg={4} md={6} xs={24}>
                            <Form.Group controlId="dataFeriado">
                                <Form.ControlLabel>Data do Feriado</Form.ControlLabel>
                                <Form.Control
                                    inputRef={dataFeriadoRef}
                                    accepter={CustomInputDate}
                                    name="dataFeriado"
                                    hideCalendar={editMode !== "I"}
                                    autoFocus={editMode === "I"}
                                    readOnly={editMode !== "I"}
                                    onBlur={handleDataFeriadoBlur}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={20} md={18} xs={24}>
                            <Form.Group controlId="tipoFeriado">
                                <Form.ControlLabel>Tipo do Feriado</Form.ControlLabel>
                                <Form.Control
                                    name="tipoFeriado"
                                    accepter={RadioGroup}
                                    appearance="picker"
                                    inline
                                >
                                    <Radio value="N">Nacional</Radio>
                                    <Radio value="E">Estadual</Radio>
                                    <Radio value="M">Municipal</Radio>
                                    <Radio value="P">Ponto Facultativo</Radio>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="nomeFeriado">
                                <Form.ControlLabel>Nome do Feriado</Form.ControlLabel>
                                <Form.Control name="nomeFeriado" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="obsFeriado">
                                <Form.ControlLabel>Observação</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomTextArea}
                                    name="obsFeriado"
                                    rows={2}
                                    maxLength={500}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataModal >
            {editMode !== "V" &&
                <Confirm
                    open={openConfirm}
                    message={`Confirma a ${editMode === "D" ? 'exclusão' : 'gravação'} do Feriado?`}
                    onClickNoButton={() => setOpenConfirm(false)}
                    onClickYesButton={handleClickYesConfirm}
                />
            }
            {editMode !== "D" && editMode !== "V" &&
                <Alert
                    open={feriadoJaCadastrado !== null && feriadoJaCadastrado !== undefined}
                    message={`Feriado já cadastrado com a data ${dataFeriadoRef.current ? dataFeriadoRef.current.value : "informada"}.\n\nClique em OK para carregar os dados.`}
                    onClickButton={handleClickLoadData}
                />
            }
        </DataContainer >
    )
}

export default Feriado