import { useEffect, useState } from "react";
import { Loader, SelectPicker } from "rsuite";

const FixedLoader = () => (
    <Loader
        content="Carregando ..."
        style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            bottom: '0',
            width: '100%',
            padding: '4px 0'
        }}
    />
);

const CustomSelectPicker = ({
    formGroupRef,
    apiGetData,
    apiGetInitialData,
    apiGetDataOrder,
    getDataObject,
    reloadDataTriggerEffect = [],
    showError,
    ...rest
}) => {
    const [newWidth, setNewWidth] = useState()

    const [data, setData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [hasMoreData, setHasMoreData] = useState(true)

    const [searchText, setSearchText] = useState()
    const [initialValue, setInitialValue] = useState()

    const itemsPerPage = 10

    useEffect(() => {
        // Função para obter a largura do Form.Group
        const getNewWidth = () => {
            if (formGroupRef && formGroupRef.current) {
                const formGroupWidth = formGroupRef.current.getBoundingClientRect().width - 1;
                const adjustedWidth = Math.ceil(formGroupWidth) - 1;
                setNewWidth(adjustedWidth)
            }
        }

        getNewWidth()

        // Adicionar ouvinte de evento de redimensionamento ao montar o componente
        window.addEventListener('resize', getNewWidth);

        // Remover o ouvinte de evento ao desmontar o componente
        return () => {
            window.removeEventListener('resize', getNewWidth);
        };

    }, [formGroupRef])

    useEffect(() => {
        loadFirstData()
        // eslint-disable-next-line
    }, [...reloadDataTriggerEffect])

    useEffect(() => {
        loadSearch()
        // eslint-disable-next-line
    }, [searchText])

    const loadSearch = async () => {
        const data = await getData(true)
        setData(data)
    }

    const loadMore = async () => {
        if (!dataLoading) {
            setDataLoading(true);
            await loadMoreData()
            setDataLoading(false)
        }
    };

    const onItemsRendered = props => {
        if (props.visibleStopIndex >= data.length - 1 && hasMoreData) {
            loadMore();
        }
    };

    const renderMenu = menu => {
        return (
            <>
                {menu}
                {dataLoading && <FixedLoader />}
            </>
        );
    };

    const getData = async reloadData => {
        try {
            const sortColumn = apiGetDataOrder && apiGetDataOrder.column ? apiGetDataOrder.column : null
            const sortType = apiGetDataOrder
                ? apiGetDataOrder.order || 'asc'
                : null

            const pageNumber = reloadData ? 1 : parseInt(data.length / itemsPerPage) + 1
            const apiData = await apiGetData({ pageNumber, itemsPerPage, sortColumn, sortType, searchText })

            if (apiData) {
                if (data.length + apiData.data.length >= apiData.filtered) setHasMoreData(false)
                else setHasMoreData(true)

                const apiDataMap = apiData.data.map(c => (getDataObject(c)))
                return apiDataMap
            }
        } catch (error) {
            if (showError) showError(error)
        }
        setHasMoreData(false)
        return []
    }

    const loadMoreData = async () => {
        const data = await getData()
        setData(prevData => [...prevData, ...data])
    }

    const loadFirstData = async () => {
        if (rest.value && apiGetInitialData) {
            try {
                const apiInitialData = await apiGetInitialData(rest.value)
                if (apiInitialData) {
                    const dataObject = getDataObject(apiInitialData)
                    setInitialValue(dataObject)
                }
            } catch (error) {
                if (showError) showError(error);
            }
        }

        const data = await getData()
        setData(data)
    }

    return <SelectPicker
        style={{ width: newWidth }}
        data={data}
        virtualized
        onSearch={setSearchText}
        renderMenu={renderMenu}
        listProps={{ onItemsRendered }}
        onSelect={(value, item) => setInitialValue(item)}
        renderValue={(value, item, selectedElement) => {
            if (initialValue && value === initialValue.value) return initialValue.label
            if (data.length && item) return item.label
            if (selectedElement) return selectedElement
        }}
        {...rest} />;
};

export default CustomSelectPicker