import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonToolbar, Container, CustomProvider, Form, Loader, Panel, Stack, useToaster } from "rsuite";
import { ptBR } from "rsuite/esm/locales";

import { showMessage } from "../../components/Message";
import useApiAuth from "../../hooks/useApiAuth";
import { getSubdomain } from "../../services/subdomain";
import { imageBase64FromURL } from '../../services/utils';

import './style.css';

const RecuperaSenha = () => {
    const { recovery } = useApiAuth();

    const navigate = useNavigate();
    const toaster = useToaster();

    const [accessCode, setAccessCode] = useState('');
    const [loading, setLoading] = useState(false);

    const [imgClienteBase64, setImgClienteBase64] = useState();
    const [imgSistempragBase64, setImgSistempragBase64] = useState();

    useEffect(() => {
        (async () => {
            const subdomain = getSubdomain()
            if (subdomain) {
                const imgCliente = await imageBase64FromURL(`./logo/${subdomain}/logo.png`)
                const imgSistemprag = await imageBase64FromURL(`./logo/sistemprag/logo.png`)

                setImgClienteBase64(imgCliente)
                setImgSistempragBase64(imgSistemprag)
            }
        })()
    }, []);

    const handleSend = async () => {
        if (loading) return false
        setLoading(true)

        try {
            const data = await recovery(accessCode);

            showMessage({ toaster, successMessage: data.message || 'E-mail enviado com sucesso.', placement: 'topCenter', durationTime: 10000 });
            navigate('/login', { replace: true, state: null });
        } catch (error) {
            showMessage({ toaster, errorObject: error, placement: 'topCenter' });
        }

        setLoading(false)
    }

    return (
        <CustomProvider theme='light' locale={ptBR}>
            <Container className="container-recupera-senha">
                <Panel bordered shaded bodyFill className="container-recupera-senha-panel">
                    <div className="container-logo-recupera-senha-client" >
                        {imgClienteBase64 && <img alt="logo cliente" src={imgClienteBase64} />}
                    </div>

                    <div className="data-panel-recupera-senha">

                        <h5 className="recupera-senha-title">Recuperação de Senha</h5>

                        <Form fluid>
                            <Form.Group controlId="accessCode">
                                <Form.ControlLabel>Login</Form.ControlLabel>
                                <Form.Control
                                    autoFocus
                                    name="accessCode"
                                    type="text"
                                    value={accessCode}
                                    onChange={setAccessCode}
                                    placeholder="código de acesso, cnpj ou cpf"
                                />
                                <Form.HelpText>Informe seu login para enviarmos a senha para o e-mail cadastrado.</Form.HelpText>
                            </Form.Group>

                            <Stack justifyContent="space-between" alignItems="flex-end">
                                <Form.Group>
                                    <ButtonToolbar>
                                        <Button style={{ height: 40 }} appearance={loading ? "primary" : "ghost"} color={loading ? "yellow" : "blue"} onClick={handleSend}>
                                            {loading
                                                ?
                                                <Stack spacing={10} alignItems="center" justifyContent="center">
                                                    <Loader />
                                                    <div style={{ fontSize: 16 }}>Aguarde...</div>
                                                </Stack>
                                                : 'Enviar'}
                                        </Button>
                                    </ButtonToolbar>
                                </Form.Group>
                            </Stack>

                        </Form>
                    </div>
                    <div className="container-logo-login-sistemprag" >
                        {imgSistempragBase64 && <img alt="logo sistemprag" src={imgSistempragBase64} />}
                    </div>
                </Panel>
            </Container>
        </CustomProvider >
    )
}

export default RecuperaSenha
