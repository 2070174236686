import axios from 'axios';

const resource = 'https://brasilapi.com.br/api/'

const apiGetCnpj = async cnpj => {
    const newCnpj = cnpj.replace(/\D/g, '')
    const url = `${resource}cnpj/v1/${newCnpj}`
    try {
        const response = await axios.get(url)
        if (response.data) {
            const data = response.data
            const retorno = {
                nomeEmpresa: data.razao_social || '',
                nomeFantasia: data.nome_fantasia || '',
                endereco:
                    (data.descricao_tipo_de_logradouro ? data.descricao_tipo_de_logradouro + ' ' : '') +
                    (data.logradouro || ''),
                numero: data.numero,
                complemento: data.complemento || '',
                bairro: data.bairro || '',
                cidade: data.municipio || '',
                uf: data.uf || '',
                cep: data.cep || '',
                telefone1: data.ddd_telefone_1 || '',
                telefone2: data.ddd_telefone_2 || '',
                email: data.email || '',
                codigo_municipio_ibge: data.codigo_municipio_ibge || ''
            }

            return retorno
        }
    } catch (error) {
        console.log(error)
    }
    return false
}

const apiGetCep = async cep => {
    const newCep = cep.replace(/\D/g, '')
    const url = `${resource}cep/v2/${newCep}`

    try {
        const response = await axios.get(url)
        if (response.data) {
            const data = response.data
            const retorno = {
                endereco: (data.street || '').toUpperCase(),
                bairro: (data.neighborhood || '').toUpperCase(),
                cidade: (data.city || '').toUpperCase(),
                uf: (data.state || '').toUpperCase()
            }

            return retorno
        }
    } catch (error) {
        console.log(error)
    }
    return false
}

export {
    apiGetCep,
    apiGetCnpj
};

