const TextoDestacado = ({ text, word }) => {

    if (!text) return <></>

    // Divide a frase em partes que contenham a palavra especificada
    const parts = text.split(new RegExp(`(${word})`, 'gi'));

    // Mapeia as partes e destaca a palavra, se for encontrada
    const highlighted = parts.map((part, index) =>
        part.toLowerCase() === word.toLowerCase()
            ? <span key={index} style={{ backgroundColor: 'yellow' }}>
                {part}
            </span>
            : <span key={index}>{part}</span>
    );

    return <div>{highlighted}</div>;
}

export default TextoDestacado