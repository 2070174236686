import React, { useRef } from 'react'
import { Col, Form, Grid, Row } from 'rsuite'

import CustomSelectPicker from '../../components/CustomSelectPicker'
import DataContainer from '../../components/DataContainer'
import DataPanel from '../../components/DataPanel'

import useApiTipoServico from '../../hooks/useApiTipoServico'

import './style.css'

const OrdemServico = ({
    abertura,
}) => {
    const formGroupRefTipoServico = useRef()
    const formGroupRefServico = useRef()

    const apiTipoServico = useApiTipoServico()

    //const [tipoServico, setTipoServico] = useState()

    return (
        <DataContainer>
            <DataPanel
                title={abertura ? 'Abertura de Ordem de Serviço'
                    : ''
                }
            >
                <Grid fluid>
                    <Row>
                        <Col xxl={8} md={12} sm={24} xs={24}>
                            <Form.Group controlId="idTipoServico" ref={formGroupRefTipoServico}>
                                <Form.ControlLabel>Tipo do Serviço</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefTipoServico}
                                    name="idTipoServico"
                                    placeholder="Selecione o Tipo do Serviço"
                                    //onChange={value => setEmpresa(prevEmpresa => ({ ...prevEmpresa, siglaUF: value, idMunicipio: '' }))}
                                    apiGetData={apiTipoServico.getAll}
                                    apiGetInitialData={apiTipoServico.getId}
                                    apiGetDataOrder={{ column: 'nomeTipoServico' }}
                                    getDataObject={item => ({ value: item.idTipoServico, label: item.nomeTipoServico })}
                                //reloadDataTriggerEffect={[cnpjInitial, cepInitial]}
                                //showError={showError}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={8} md={12} sm={24} xs={24}>
                            <Form.Group controlId="idMunicipio" ref={formGroupRefServico}>
                                <Form.ControlLabel>Município</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefServico}
                                    name="idMunicipio"
                                    //placeholder={`Selecione o Serviço${empresa.siglaUF && empresa.siglaUF !== '' ? '' : ' após selecionar a UF'}`}
                                    //onChange={value => setEmpresa(prevEmpresa => ({ ...prevEmpresa, idMunicipio: value }))}
                                    // apiGetData={handleGetMunicipioData}
                                    // apiGetInitialData={apiMunicipio.getId}
                                    apiGetDataOrder={{ column: 'nomServico' }}
                                    getDataObject={item => ({ value: item.idMunicipio, label: item.nomeMunicipio })}
                                //reloadDataTriggerEffect={[empresa.siglaUF, cnpjInitial, cepInitial]}
                                //showError={showError}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataPanel>

        </DataContainer>
    )
}

export default OrdemServico
