import { isValidCNPJ, isValidCpf } from 'documents-validation';

const notEmpty = text => {
    return text.trim() !== ''
}

const isEmail = text => {
    return /\S+@\S+\.\S+/.test(text.trim())
}

const isCNPJ = cnpj => isValidCNPJ(cnpj)
const isCPF = cpf => isValidCpf(cpf)

const dateFromString = text => {
    if (text === '') return null

    const { dia, mes, ano } = getDateTimeParts(text)

    if (!isValidDate(dia, mes, ano)) return null

    const data = new Date(ano, mes, dia)

    return data
}

const dateTimeFromString = text => {
    if (text === '') return null

    const { dia, mes, ano, hora, minuto } = getDateTimeParts(text)

    if (!isValidDate(dia, mes, ano)) return null
    if (!isValidTime(hora, minuto)) return null

    const data = new Date(ano, mes, dia, hora, minuto)

    return data
}

const getDateTimeParts = text => {
    const dataHora = text.includes(' às ') ? text.split(' às ')
        : text.includes('T') ? text.split('T')
            : text.includes(' - ') ? text.split(' - ')
                : text.includes(' ') ? text.split(' ')
                    : null

    const data = dataHora ? dataHora[0] : text

    const dataSplit = data.includes('/') ? data.split('/')
        : data.includes('-') ? data.split('-')
            : null

    let dia, mes, ano, hora, minuto

    if (data.includes('/')) {
        ano = parseInt(dataSplit[2])
        mes = parseInt(dataSplit[1]) - 1
        dia = parseInt(dataSplit[0])
    } else if (data.includes('-')) {
        ano = parseInt(dataSplit[0])
        mes = parseInt(dataSplit[1]) - 1
        dia = parseInt(dataSplit[2])
    } else if (data.length === 8) {
        ano = parseInt(data.substring(4, 8))
        mes = parseInt(data.substring(2, 4)) - 1
        dia = parseInt(data.substring(0, 2))
    }

    if (dataHora && dataHora.length > 1) {
        const horaSplit = dataHora[1].split(':')

        hora = parseInt(horaSplit[0])
        minuto = parseInt(horaSplit[1])
    }

    return {
        dia,
        ano,
        mes,
        hora,
        minuto
    }
}

const phoneFormat = phone => {
    if (!phone) return ''

    if (phone.length === 10) return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    if (phone.length === 11) return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
}

const imageBase64FromURL = async imageURL =>
    new Promise((resolve, reject) => {
        fetch(imageURL)
            .then(response => response.blob())
            .then(blob => {
                if (!blob.type || !blob.type.includes('image')) return resolve(null)
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    resolve(reader.result);
                }
            })
            .catch(error => reject(error))
    })

const dateToString = date => {
    // Converter data para string ("dd/mm/yyyy")
    // Coloca 0 na esquerda
    const dia = (`0${date.getDate()}`).slice(-2)
    const mes = (`0${date.getMonth() + 1}`).slice(-2)
    const ano = date.getFullYear()

    const dataFormatada = `${dia}/${mes}/${ano}`

    return dataFormatada
}

const dateTimeToString = date => {
    // Converter data para string ("dd/mm/yyyy")
    // Coloca 0 na esquerda
    const data = dateToString(date)

    const hora = (`0${date.getHours()}`).slice(-2)
    const minuto = (`0${date.getMinutes()}`).slice(-2)

    const dataHoraFormatada = `${data} às ${hora}:${minuto}`

    return dataHoraFormatada
}

const formatDate = (data, isToSendRequest) => {

    //Formata para o padrão de requisição
    if (isToSendRequest) {
        const text = (data instanceof Date) ? dateToString(data) : data
        const { ano, mes, dia } = getDateTimeParts(text)
        const dataFormatada = `${ano}-${(`0${mes + 1}`).slice(-2)}-${(`0${dia}`).slice(-2)}`
        return dataFormatada
    }

    //Formata para o padrão de exibição DD/MM/AAAA
    const auxData = (data instanceof Date) ? data : dateFromString(data)
    return auxData.toLocaleDateString('pt-br')
}

const isValidDate = (dia, mes, ano) => {
    const date = new Date(ano, mes, dia) // Mês é 0-indexado
    return (
        date.getFullYear() === ano &&
        date.getMonth() === mes &&
        date.getDate() === dia
    )
}

const isValidTime = (hora, minuto) => {
    return (
        hora >= 0 && hora <= 23 &&
        minuto >= 0 && minuto <= 59
    )
}

export {
    dateFromString,
    dateTimeFromString,
    dateTimeToString,
    dateToString,
    formatDate,
    getDateTimeParts,
    imageBase64FromURL,
    isCNPJ,
    isCPF,
    isEmail,
    isValidDate,
    isValidTime,
    notEmpty,
    phoneFormat
};


