import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { useCallback, useEffect, useState } from 'react';

import useApiLocalizacao from '../../hooks/useApiLocalizacao';

const Directions = ({ data }) => {

    const { getRotas, saveRotas } = useApiLocalizacao()

    const map = useMap()
    const routesLibrary = useMapsLibrary('routes');

    const [directionsService, setDirectionsService] = useState()
    const [directionsRenderer, setDirectionsRenderer] = useState();
    const [routes, setRoutes] = useState([]);
    const [routeIndex, setRouteIndex] = useState(0);
    const selected = routes[routeIndex];

    const coordenadasDestino = data?.destino?.coordenadas
    const enderecoDestino = data?.destino?.endereco

    const coordenadasOrigem = data?.origem?.coordenadas

    const cnpjCpf = data?.cnpjCpf

    // Inicia serviço de rota e rederização
    useEffect(() => {
        if (!routesLibrary || !map) return;

        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));

    }, [routesLibrary, map]);

    const loadRoutes = useCallback(async () => {
        if (!directionsService || !directionsRenderer) return;

        const result = await getRotas(cnpjCpf)

        if (result) {
            directionsRenderer.setOptions({ suppressMarkers: true });
            directionsRenderer.setDirections(result);
            setRoutes(result.routes);
            return
        }

        const response = await directionsService
            .route({
                origin: coordenadasOrigem,
                destination: coordenadasDestino,
                travelMode: 'DRIVING',
                provideRouteAlternatives: true
            })

        console.log('directionsService')

        const directionsResult = {
            geocoded_waypoints: response.geocoded_waypoints,
            routes: response.routes,
            request: response.request
        };

        directionsRenderer.setOptions({ suppressMarkers: true });
        directionsRenderer.setDirections(directionsResult);
        setRoutes(response.routes);

        saveRotas({
            cnpjCpf: cnpjCpf,
            jsonRotas: directionsResult
        }).then(() => { })

    }, [cnpjCpf, coordenadasDestino, coordenadasOrigem, directionsService, directionsRenderer, getRotas, saveRotas])

    // Use o serviço de rota
    useEffect(() => {
        if (!directionsService || !directionsRenderer) return;

        loadRoutes()

        return () => directionsRenderer.setMap(null);

        // eslint-disable-next-line
    }, [directionsService, directionsRenderer, coordenadasOrigem, coordenadasDestino, cnpjCpf]);

    // Atualiza a rota selecionada
    useEffect(() => {
        if (!directionsRenderer) return;

        directionsRenderer.setRouteIndex(routeIndex);

    }, [routeIndex, directionsRenderer]);

    if (!selected) return null;

    return (
        <div className="directions">
            <div className='direction-destination'>{enderecoDestino.nome}</div>
            <div className='direction-title'>{`ROTA${routes.length > 1 ? 'S' : ''}`}</div>

            {routes
                .map((route, index) => (
                    <div key={index} className={`direction-item ${routeIndex === index ? 'selected' : ''}`} onClick={() => setRouteIndex(index)}>
                        <div className='bold'>via {route.summary}</div>
                        <div>{`${route.legs[0].distance?.text} (${route.legs[0].duration?.text})`}</div>
                    </div>
                ))}
        </div>
    )
}

export default Directions
