import { Input } from "rsuite";

const CustomInputNumeroEndereco = ({ onChange, inputRef, ...rest }) => {

    const handleInputChange = (value, event) => {
        if (value.toUpperCase() === 'S') return onChange('S', event)
        else if (value.toUpperCase().substring(0, 2) === 'SN') return onChange('SN', event)

        const input = value.replace(/\D/g, '')
        onChange(input, event);
    };

    return (
        <Input
            onChange={handleInputChange}
            ref={inputRef}
            {...rest} />
    )
}

export default CustomInputNumeroEndereco