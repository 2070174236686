import ApiService from '../services/api.js';

const useApiCliente = () => {

    const apiService = new ApiService()
    const api = apiService.api

    const resource = '/cliente'

    const getAll = async ({ pageNumber, itemsPerPage, sortColumn, sortType, searchText, exibir }) => {
        const response = await api.get(resource, {
            params: {
                pageNumber,
                itemsPerPage,
                sortColumn,
                sortType,
                searchText,
                exibir
            }
        })
        return response.data;
    }

    const getId = async id => {
        const response = await api.get(`${resource}/${id}`)
        return response.data;
    }


    const getCnpjCpf = async cnpjCpf => {
        const response = await api.get(`${resource}/cnpjcpf/${cnpjCpf}`)
        return response.data;
    }

    const save = cliente => {
        if (!cliente.idCliente) {
            return api.post(resource, cliente)
        } else {
            return api.patch(`${resource}/${cliente.idCliente}`, cliente)
        }
    }

    const destroy = id => api.delete(`${resource}/${id}`)

    const resetPassword = id => api.patch(`${resource}/resetpassword/${id}`)

    return {
        getAll,
        getCnpjCpf,
        getId,
        resetPassword,
        save,
        destroy
    }
}

export default useApiCliente
