import { MaskedInput } from "rsuite";

const CustomInputCep = ({ onChange, inputRef, ...rest }) => {
    const handleInputChange = (value, event) => {
        const input = value.replace(/\D/g, '');
        onChange(input, event);
    };

    return <MaskedInput
        ref={inputRef}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
        onChange={handleInputChange}
        {...rest} />;
}

export default CustomInputCep