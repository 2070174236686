import PropTypes from 'prop-types';
import { useEffect, useMemo, useReducer, useState } from 'react';

import { Avatar, Button, Col, IconButton, Input, InputGroup, Pagination, Panel, Stack, Table } from 'rsuite';

import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import EditIcon from '@rsuite/icons/Edit';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import PlusIcon from '@rsuite/icons/Plus';
import SearchIcon from '@rsuite/icons/Search';
import TrashIcon from '@rsuite/icons/Trash';
import VisibleIcon from '@rsuite/icons/Visible';

import './style.css';

const { ColumnGroup, Column, HeaderCell, Cell } = Table

const DataTableColumn = col => (
    <Column
        key={col.dataKey}
        minWidth={col.minWidth}
        width={col.width}
        flexGrow={col.flexGrow}
        align={col.align || 'left'}
        sortable={col.sortable}
        fullText={col.fullText}
        resizable={col.resizable}
    >
        <HeaderCell>{col.headerCell}</HeaderCell>
        {
            col.customCell
                ? <col.customCell />
                : col.customCellContent
                    ? <Cell dataKey={col.dataKey}>
                        {rowData => col.customCellContent(rowData, col)}
                    </Cell>
                    : col.avatar
                        ? <Cell dataKey={col.dataKey} style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {rowData => col.avatar && <Avatar size="md" circle src={rowData[col.dataKey]} />}
                        </Cell>
                        : <Cell dataKey={col.dataKey} style={col.style} />
        }
    </Column>
)

const ExpandCell = ({ rowData, dataKey, rowKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props} style={{ padding: 5 }}>
        <IconButton
            appearance="subtle"
            onClick={() => onChange(rowData)}
            icon={
                expandedRowKeys.some(key => key === rowKey)
                    ? <CollaspedOutlineIcon />
                    : <ExpandOutlineIcon />
            }
        />
    </Cell>
)

const DataTable = ({
    title,
    data,
    dataTableColumns,
    placeholderSearch,
    hiddeNewButton,
    textNewButton,
    onClickNewButton,
    loading,
    setLoading,
    goToLastPage,
    setGoToLastPage,
    onChangeParams,
    minHeight,
    alignContainer = 'center',
    ExtraButtons,
    xxl,
    xl,
    lg,
    md,
    sm,
    xs
}) => {

    const { editButtonColumn, deleteButtonColumn, displayButtonColumn, expandedRow, columns } = dataTableColumns;

    const buttonColumnWidth = (editButtonColumn || deleteButtonColumn)
        ? (editButtonColumn ? 40 : 0) + (deleteButtonColumn ? 40 : 0)
        : displayButtonColumn ? 40 : 0

    const dataTableParamCompare = (prevState, newState) => {
        if (prevState.searchText === newState.searchText &&
            prevState.pageNumber === newState.pageNumber &&
            prevState.itemsPerPage === newState.itemsPerPage &&
            prevState.sortColumn === newState.sortColumn &&
            prevState.sortType === newState.sortType) {
            return prevState
        } else {
            return newState
        }
    }

    //Só retorna o estado atualizado se realmente ouve alteração (isso por ser objeto)
    const [dataTableParams, setDataTableParams] = useReducer(dataTableParamCompare, {
        searchText: '',
        pageNumber: 1,
        itemsPerPage: 10,
        sortColumn: undefined,
        sortType: undefined
    });

    const [inputValue, setInputValue] = useState('');
    const [footerText, setFooterText] = useState('');

    const handleChangePage = page => setDataTableParams({ ...dataTableParams, pageNumber: page, });
    const handleChangeLimit = limitValue => setDataTableParams({ ...dataTableParams, pageNumber: 1, itemsPerPage: limitValue });
    const handleSortColumn = (sortColumn, sortType) => setDataTableParams({ ...dataTableParams, sortColumn: sortColumn, sortType: sortType });

    useEffect(() => {
        if (data.filtered) {
            const totalPages = Math.ceil(data.filtered / dataTableParams.itemsPerPage)
            if (goToLastPage) {
                setDataTableParams({ ...dataTableParams, pageNumber: totalPages, });
                if (setGoToLastPage) setGoToLastPage(false)
            } else if (totalPages < dataTableParams.pageNumber) {
                setDataTableParams({ ...dataTableParams, pageNumber: totalPages, });
            }
        }

    }, [data.filtered, goToLastPage, setGoToLastPage, dataTableParams])

    useEffect(() => {
        const delayTypingInputSearch = setTimeout(() => {
            setDataTableParams({ ...dataTableParams, searchText: inputValue, pageNumber: 1 })
        }, 500)

        setFooterText('')
        if (setLoading) setLoading(true)
        return () => clearTimeout(delayTypingInputSearch)

        // eslint-disable-next-line
    }, [inputValue])

    //Paga obter as alterações dos valores que utilizam na pesquisa
    useEffect(() => {
        if (onChangeParams) {
            onChangeParams(dataTableParams)
        }

        // eslint-disable-next-line
    }, [dataTableParams])

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const handleExpanded = rowData => {
        let open = false;
        const nextExpandedRowKeys = [];
        const rowKey = expandedRow.rowKey

        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) open = true
            else nextExpandedRowKeys.push(key)
        })

        if (!open) nextExpandedRowKeys.push(rowData[rowKey]);

        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const createFooterText = () => {
        if (!data.total) {
            setFooterText('')
            return
        }

        const filtrado = data.filtered === data.total ? '' : `(filtrados de ${data.total.toLocaleString('pt-BR')})`
        const ini = ((dataTableParams.pageNumber - 1) * dataTableParams.itemsPerPage) + 1
        let fim = dataTableParams.pageNumber * dataTableParams.itemsPerPage

        if (fim > data.filtered) fim = data.filtered

        setFooterText(`Mostrando ${ini.toLocaleString('pt-BR')} até ${fim.toLocaleString('pt-BR')} de ${data.filtered.toLocaleString('pt-BR')} ${filtrado}`)
    }

    // eslint-disable-next-line
    useMemo(() => createFooterText(), [data.filtered, dataTableParams.pageNumber, dataTableParams.itemsPerPage]);

    return (
        <div className={`data-table-container${alignContainer ? `-${alignContainer}` : 'center'}`}>
            <Col
                xxl={xxl || 24}
                xl={xl || 24}
                lg={lg || 24}
                md={md || 24}
                sm={sm || 24}
                xs={xs || 24}
            >
                <Panel bordered shaded className='data-table-panel' header={title}>
                    <div className="data-table-panel-header" style={{ justifyContent: hiddeNewButton ? 'flex-end' : 'space-between' }}>
                        <Stack justifyContent='flex-end' alignItems='center' spacing={10}>
                            {!hiddeNewButton &&
                                <Button appearance='ghost' color='green' size='sm' onClick={onClickNewButton} startIcon={<PlusIcon />}>
                                    {textNewButton || 'Novo'}
                                </Button>
                            }
                            {ExtraButtons}
                        </Stack>

                        <div className='data-table-panel-search'>
                            <span>Pesquisar</span>
                            <InputGroup inside>
                                <Input type='search' name='data-table-search' onChange={(value, e) => setInputValue(value)} placeholder={placeholderSearch} />
                                <InputGroup.Addon>
                                    <SearchIcon />
                                </InputGroup.Addon>
                            </InputGroup>
                        </div>
                    </div>

                    <Table
                        data={data.data}
                        sortColumn={dataTableParams.sortColumn}
                        sortType={dataTableParams.sortType}
                        onSortColumn={handleSortColumn}
                        loading={loading}
                        minHeight={minHeight || 500}
                        hover
                        autoHeight
                        bordered
                        headerHeight={columns.find(c => c.columnGroup) ? 80 : 40}

                        rowKey={expandedRow ? expandedRow.rowKey : undefined}
                        expandedRowKeys={expandedRow ? expandedRowKeys : undefined}
                        renderRowExpanded={expandedRow ? expandedRow.render : undefined}
                        rowExpandedHeight={expandedRow ? (expandedRow.height ? expandedRow.height : 100) : undefined}
                    >
                        {
                            expandedRow &&
                            <Column width={50} align="center">
                                <HeaderCell></HeaderCell>
                                <ExpandCell
                                    dataKey={expandedRow.dataKey}
                                    rowKey={expandedRow.rowKey || expandedRow.dataKey}
                                    expandedRowKeys={expandedRowKeys}
                                    onChange={handleExpanded}
                                />
                            </Column>
                        }
                        {
                            columns.map(col => (
                                col.columnGroup
                                    ? <ColumnGroup header={col.columnGroup} align='center' key={col.columnGroup}>
                                        {col.columns.map(c => (DataTableColumn(c)))}
                                    </ColumnGroup>
                                    : !col.hidden && DataTableColumn(col)
                            ))
                        }
                        {(displayButtonColumn || editButtonColumn || deleteButtonColumn) &&
                            <Column width={buttonColumnWidth} fixed='right'>
                                <HeaderCell></HeaderCell>
                                <Cell>
                                    {rowData => {
                                        return <>
                                            {
                                                displayButtonColumn && ((!displayButtonColumn.hidden) || (displayButtonColumn.hidden && !displayButtonColumn.hidden(rowData)))
                                                    ? <div className='data-table-column-display-button'>
                                                        <Button
                                                            appearance='ghost'
                                                            color='green'
                                                            style={{ padding: '4px 6px' }}
                                                            onClick={() => displayButtonColumn.onClick(rowData[displayButtonColumn.dataKey])}>
                                                            <VisibleIcon />
                                                        </Button>
                                                    </div>
                                                    : <div className='data-table-column-button'>
                                                        {editButtonColumn && ((!editButtonColumn.hidden) || (editButtonColumn.hidden && !editButtonColumn.hidden(rowData))) &&
                                                            <div className='data-table-column-edit-button'>
                                                                <Button
                                                                    appearance='ghost'
                                                                    color='blue'
                                                                    style={{ padding: '4px 6px' }}
                                                                    onClick={() => editButtonColumn.onClick(rowData[editButtonColumn.dataKey])}>
                                                                    <EditIcon />
                                                                </Button>
                                                            </div>
                                                        }
                                                        {deleteButtonColumn && ((!deleteButtonColumn.hidden) || (deleteButtonColumn.hidden && !deleteButtonColumn.hidden(rowData))) &&
                                                            <div className='data-table-column-delete-button'>
                                                                <Button
                                                                    appearance='ghost'
                                                                    color='red'
                                                                    style={{ padding: '4px 6px' }}
                                                                    onClick={() => deleteButtonColumn.onClick(rowData[deleteButtonColumn.dataKey])}>
                                                                    <TrashIcon />
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                            }
                                        </>
                                    }}
                                </Cell >
                            </Column>
                        }
                    </Table>

                    <div style={{ paddingTop: 20 }}>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={3}
                            size="sm"
                            layout={[footerText, '-', 'limit', '|', 'pager', 'skip']}
                            total={data.filtered}
                            limitOptions={[10, 30, 50]}
                            limit={dataTableParams.itemsPerPage}
                            activePage={dataTableParams.pageNumber}
                            onChangePage={handleChangePage}
                            onChangeLimit={handleChangeLimit}
                        />
                    </div>
                </Panel>
            </Col>
        </div>
    )
}

DataTable.propTypes = {
    data: PropTypes.object,
    dataTableColumns: PropTypes.object,
    title: PropTypes.string,
    placeholderSearch: PropTypes.string,
    hiddeNewButton: PropTypes.bool,
    textNewButton: PropTypes.string,
    onClickNewButton: PropTypes.func,
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    onChangeParams: PropTypes.func,
    goToLastPage: PropTypes.bool,
    minHeight: PropTypes.number,
    xxl: PropTypes.number,
    xl: PropTypes.number,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number
}

export default DataTable
