import ApiService from '../services/api.js';

const useApiMeusDados = () => {

    const apiService = new ApiService()
    const api = apiService.api

    const resource = '/meusdados'

    const getMeusDados = async () => {
        const response = await api.get(resource)
        return response.data;
    }
    const saveDarkMode = darkMode => api.patch(`${resource}/darkmode`, { darkMode: darkMode })
    const saveMeusDados = async user => {
        const response = await api.patch(resource, user)
        return response
    }
    const changePassword = async passwordData => {
        const response = await api.patch(`${resource}/changepassword`, passwordData);
        return response.data;
    }

    return {
        saveDarkMode,
        saveMeusDados,
        getMeusDados,
        changePassword
    }
}

export default useApiMeusDados 