import { useContext } from 'react'
import { Navigate, Route } from 'react-router-dom'

import { AuthContext } from '../contexts/authContext'

import Loading from '../components/Loading'
import Menu from '../components/Menu'
import PageNotFound from '../components/PageNotFound'

import Cliente from '../pages/Cliente'
import Dashboard from '../pages/Dashboard'
import Empresa from '../pages/Empresa'
import Feriado from '../pages/Feriado'
import Fornecedor from '../pages/Fornecedor'
import GrupoQuimico from '../pages/GrupoQuimico'
import Laboratorio from '../pages/Laboratorio'
import LocalAplicacao from '../pages/LocalAplicacao'
import OrdemServico from '../pages/OrdemServico'
import Perfil from '../pages/Perfil'
import Praga from '../pages/Praga'
import Produto from '../pages/Produto'
import RamoAtividade from '../pages/RamoAtividade'
import Servico from '../pages/Servico'
import TipoAplicacao from '../pages/TipoAplicacao'
import TipoEstabelecimento from '../pages/TipoEstabelecimento'
import TipoPraga from '../pages/TipoPraga'
import TipoServico from '../pages/TipoServico'
import UnidadeMedida from '../pages/UnidadeMedida'
import Usuario from '../pages/Usuario'

const PrivateRoute = () => {
    const { signed, loading } = useContext(AuthContext)

    if (loading) return <Loading />
    if (!signed) return <Navigate to='/login' replace={true} />

    return <Menu />
}

const createPrivateRoutes = () => (
    <Route element={<PrivateRoute />}>

        <Route path='/' element={<Dashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />

        <Route path='/grupoquimico' element={<GrupoQuimico />} />
        <Route path='/tipoaplicacao' element={<TipoAplicacao />} />
        <Route path='/laboratorio' element={<Laboratorio />} />
        <Route path='/tipopraga' element={<TipoPraga />} />
        <Route path='/praga' element={<Praga />} />
        <Route path='/localaplicacao' element={<LocalAplicacao />} />
        <Route path='/tipoestabelecimento' element={<TipoEstabelecimento />} />
        <Route path='/ramoatividade' element={<RamoAtividade />} />
        <Route path='/unidademedida' element={<UnidadeMedida />} />
        <Route path='/tiposervico' element={<TipoServico />} />

        <Route path='/produto' element={<Produto />} />
        <Route path='/servico' element={<Servico />} />

        <Route path='/cliente' element={<Cliente />} />
        <Route path='/fornecedor' element={<Fornecedor />} />

        <Route path='osabertura' element={<OrdemServico abertura={true} />} />

        <Route path='/usuario' element={<Usuario />} />
        <Route path='/perfil' element={<Perfil />} />
        <Route path='/empresa' element={<Empresa />} />
        <Route path='/feriado' element={<Feriado />} />

        <Route path='/consultafornecedor' element={<Fornecedor consulta={true} />} />
        <Route path='/consultacliente' element={<Cliente consulta={true} />} />

        <Route path='/consultaservico' element={<Servico consulta={true} />} />
        <Route path='/consultaproduto' element={<Produto consulta={true} />} />

        <Route path='*' element={<PageNotFound />} />
    </Route>
);

export default createPrivateRoutes;