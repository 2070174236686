import { Button, Stack, Toggle } from 'rsuite';

import './style.css';

const AcessaSistema = ({
    showButtonResetPassword,
    onClickButtonResetPassword,
    ...rest
}) => {

    return (
        <Stack spacing={10}>
            <Toggle
                className='toggle-red-green'
                {...rest}
            />
            {showButtonResetPassword && <Button size='sm' appearance='ghost' color='yellow' onClick={onClickButtonResetPassword}>Resetar senha</Button>}
        </Stack>
    )
}

export default AcessaSistema
