import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonToolbar, Container, CustomProvider, Form, InputGroup, Loader, Panel, Stack, useToaster } from "rsuite";
import { ptBR } from "rsuite/esm/locales";

import { showMessage } from "../../components/Message";

import { AuthContext } from "../../contexts/authContext";

import { getSubdomain } from "../../services/subdomain";
import { setToken } from "../../services/token";
import { imageBase64FromURL } from "../../services/utils";

import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

import './style.css';

const Login = () => {
    const { login } = useContext(AuthContext);

    const navigate = useNavigate();
    const toaster = useToaster();

    const [accessCode, setAccessCode] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const [visible, setVisible] = useState(false);

    const [imgClienteBase64, setImgClienteBase64] = useState();
    const [imgSistempragBase64, setImgSistempragBase64] = useState();

    const setImageLogo = useCallback(async () => {
        setToken('')
        const subdomain = getSubdomain()
        if (subdomain) {
            const imgCliente = await imageBase64FromURL(`./logo/${subdomain}/logo.png`)
            const imgSistemprag = await imageBase64FromURL(`./logo/sistemprag/logo.png`)

            setImgClienteBase64(imgCliente)
            setImgSistempragBase64(imgSistemprag)
        }
    }, [])

    useEffect(() => {
        setImageLogo()
    }, [setImageLogo]);

    const handleLogin = async () => {
        if (loading) return false
        setLoading(true)

        try {
            const result = await login(accessCode, password);

            if (result) {
                toaster.clear();
                navigate('/', { replace: true, state: null });
            } else {
                showMessage({ toaster, message: 'Código de Acesso ou senha incorreto.', placement: 'topCenter' })
            }
        } catch (error) {
            showMessage({ toaster, message: error, placement: 'topCenter' })
        }
        setLoading(false)
    }

    const handleVisible = () => setVisible(!visible);

    return (
        <CustomProvider theme='light' locale={ptBR}>
            <Container className="container-login">
                <Panel bordered shaded bodyFill className="container-login-panel">
                    <div className="container-logo-login-client" >
                        {imgClienteBase64
                            ? <img alt="logo cliente" src={imgClienteBase64} />
                            : <Loader size="md" />
                        }
                    </div>
                    <div className="data-panel-login">
                        <Form fluid>
                            <Form.Group controlId="accessCode">
                                <Form.ControlLabel>Login</Form.ControlLabel>
                                <Form.Control
                                    autoFocus
                                    name="accessCode"
                                    type="text"
                                    placeholder="código de acesso, cnpj ou cpf"
                                    value={accessCode}
                                    onChange={setAccessCode}
                                />
                            </Form.Group>

                            <Form.Group controlId="password">
                                <Form.ControlLabel>Senha</Form.ControlLabel>
                                <InputGroup inside>
                                    <Form.Control
                                        name="password"
                                        autoComplete="off"
                                        type={visible ? 'text' : 'password'}
                                        value={password}
                                        onChange={setPassword}
                                    />
                                    <InputGroup.Button onClick={handleVisible}>
                                        {visible ? <EyeIcon /> : <EyeSlashIcon />}
                                    </InputGroup.Button>
                                </InputGroup>
                            </Form.Group>

                            <Stack justifyContent="space-between" alignItems="flex-end">
                                <Form.Group>
                                    <ButtonToolbar>
                                        <Button type="submit" style={{ height: 40 }} appearance={loading ? "primary" : "ghost"} color={loading ? "yellow" : "green"} onClick={handleLogin}>
                                            {loading
                                                ?
                                                <Stack spacing={10} alignItems="center" justifyContent="center">
                                                    <Loader />
                                                    <div style={{ fontSize: 16 }}>Aguarde...</div>
                                                </Stack>
                                                : 'Login'}
                                        </Button>
                                    </ButtonToolbar>
                                </Form.Group>

                                <Link to='/recuperasenha'>Esqueceu a senha?</Link>
                            </Stack>

                        </Form>
                    </div>
                    <div className="container-logo-login-sistemprag" >
                        {imgSistempragBase64
                            ? <img alt="logo sistemprag" src={imgSistempragBase64} />
                            : <Loader size="md" />
                        }
                    </div>
                </Panel>
            </Container>
        </CustomProvider >
    )
}

export default Login
