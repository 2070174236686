import PropTypes from 'prop-types';
import { MaskedInput } from "rsuite";

const CustomInputNumero = ({ onChange, prefixo, sufixo, ...rest }) => {

    const handleInputChange = (value, event) => {
        let newValue = value

        if (prefixo) newValue = newValue.replace(prefixo + ' ', '')
        if (sufixo) newValue = newValue.replace(' ' + sufixo, '')

        const input = newValue.replace(/\D/g, '');
        onChange(input, event);
    };

    const valorMask = (rawValue) => {
        let newValue = rawValue

        if (prefixo) newValue = newValue.replace(prefixo + ' ', '')
        if (sufixo) newValue = newValue.replace(' ' + sufixo, '')

        const digitCount = newValue.replace(/\D/g, '').length
        const numGroups = Math.trunc(digitCount / 3)
        const resto = digitCount % 3

        const mask = []

        if (prefixo) {
            mask.push(...prefixo.split(''))
            mask.push(' ')
        }

        for (let i = 0; i < resto; i++) {
            mask.push(/\d/)
        }

        if (numGroups > 0 && resto > 0) mask.push('.')

        for (let i = 0; i < numGroups; i++) {
            mask.push(...[/\d/, /\d/, /\d/])
            if (numGroups > 1 && i < numGroups - 1) mask.push('.')
        }

        if (sufixo) {
            mask.push(' ')
            mask.push(...sufixo.split(''))
        }

        return mask;
    };

    return <MaskedInput
        mask={valorMask}
        guide={false}
        keepCharPositions={false}
        showMask={false}
        onChange={handleInputChange}
        {...rest} />;
};

CustomInputNumero.propTypes = {
    onChange: PropTypes.func.isRequired,
}

export default CustomInputNumero