import { useEffect, useRef, useState } from "react";
import { Col, Form, Grid, Row, Schema, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import CustomSelectPicker from "../../components/CustomSelectPicker";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/Message";

import useApiPraga from "../../hooks/useApiPraga";
import useApiTipoPraga from '../../hooks/useApiTipoPraga';
import useScreenSizeComparison from "../../hooks/useScreenSizeComparison";

const Praga = () => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const { getAll, getId, destroy, save } = useApiPraga()
    const apiTipoPraga = useApiTipoPraga()

    const formGroupRef = useRef()

    const [pragas, setPragas] = useState({})
    const [praga, setPraga] = useState({})

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [deleteMode, setDeleteMode] = useState(false)

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    const getPragas = async () => {
        if (!dataTableParams) return setPragas({})
        setLoading(true)

        try {
            const data = await getAll({ ...dataTableParams })
            setPragas(data)
        } catch (error) {
            setPragas({});
            showError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        (async () => await getPragas())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (id, isDelete) => {
        try {
            const data = await getId(id)
            setPraga(data)
            setDeleteMode(isDelete)
            setOpenEditModal(true)
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNewButton = () => {
        setPraga({
            idPraga: null,
            nomeComumPraga: '',
            nomeCientificoPraga: '',
            idTipoPraga: ''
        });
        setDeleteMode(false);
        setOpenEditModal(true);
    }
    const handleClickEditButton = id => editData(id)
    const handleClickDeleteButton = id => editData(id, true)

    const handleClickCancelButton = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }

    const handleClickYesButton = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await destroy(praga.idPraga) : await save(praga)

            showSucess()
            handleClickCancelButton()
            await getPragas()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idPraga' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const hiddenColNomeCientifico = isScreenSizeBelow('md')
    const hiddenColId = isScreenSizeBelow('sm')

    const dataTableColumns = {
        editButtonColumn: {
            dataKey: 'idPraga',
            onClick: handleClickEditButton
        },
        deleteButtonColumn: {
            dataKey: 'idPraga',
            onClick: handleClickDeleteButton
        },
        columns: [
            {
                dataKey: 'idPraga',
                headerCell: 'Id',
                align: 'center',
                width: 100,
                sortable: true,
                hidden: hiddenColId
            }, {
                dataKey: 'nomeComumPraga',
                headerCell: 'Nome Comum',
                sortable: true,
                fullText: true,
                flexGrow: 3
            }, {
                dataKey: 'nomeCientificoPraga',
                headerCell: 'Nome Científico',
                sortable: true,
                fullText: true,
                flexGrow: 2,
                hidden: hiddenColNomeCientifico
            }, {
                dataKey: 'nomeTipoPraga',
                headerCell: 'Tipo de Praga',
                sortable: true,
                fullText: true,
                flexGrow: 2,
                customCellContent: rowData => rowData.TipoPraga && rowData.TipoPraga.nomeTipoPraga
            }
        ]
    }

    const { StringType, NumberType } = Schema.Types;

    const model = Schema.Model({
        nomeComumPraga: StringType().isRequired('Nome Comum é obrigatório'),
        nomeCientificoPraga: StringType().isRequired('Nome Científico é obrigatório'),
        idTipoPraga: NumberType().isRequired('Tipo de Praga é obrigatório'),
    });

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget.name

        setPraga(prevPraga => ({
            ...prevPraga,
            [fieldName]: value
        }))
    }

    return (
        <DataContainer title={"Cadastro de Pragas"}>
            <DataTable
                data={pragas}
                dataTableColumns={dataTableColumns}
                placeholderSearch='todas as colunas'
                textNewButton='Nova Praga'
                onClickNewButton={handleClickNewButton}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
                xxl={16}
                xl={18}
                lg={20}
            />

            <DataModal
                title='Cadastro de Praga'
                titleIdValue={praga.idPraga}
                deleteMode={deleteMode}
                open={openEditModal}
                sizeModal='md'
                waiting={waiting}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancelButton}
                formValidation={model}
                formValue={praga}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="nomeComumPraga">
                                <Form.ControlLabel>Nome Comum da Praga</Form.ControlLabel>
                                <Form.Control
                                    name="nomeComumPraga"
                                    autoFocus={!deleteMode}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="nomeCientificoPraga">
                                <Form.ControlLabel>Nome Científico da Praga</Form.ControlLabel>
                                <Form.Control
                                    name="nomeCientificoPraga"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="idTipoPraga" ref={formGroupRef}>
                                <Form.ControlLabel>Tipo de Praga (Classificação)</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRef}
                                    name="idTipoPraga"
                                    placeholder="Selecione o tipo de praga"
                                    onChange={value => setPraga(prevPraga => ({ ...prevPraga, idTipoPraga: value }))}
                                    apiGetData={apiTipoPraga.getAll}
                                    apiGetInitialData={apiTipoPraga.getId}
                                    getDataObject={item => ({ value: item.idTipoPraga, label: item.nomeTipoPraga })}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataModal>
            <Confirm
                open={openConfirm}
                message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados da Praga?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYesButton}
            />
        </DataContainer >
    )
}

export default Praga