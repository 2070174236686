import { useEffect, useState } from 'react';

const BREAKPOINTS = {
    xxl: 1400,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 0,
};

const useScreenSizeComparison = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        // Limpar o evento de redimensionamento quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isScreenSizeBelow = (target) => {
        const targetWidth = typeof target === 'string' ? BREAKPOINTS[target] || 0 : target;
        return windowWidth <= targetWidth;
    };

    const isScreenSizeAbove = (target) => {
        const targetWidth = typeof target === 'string' ? BREAKPOINTS[target] || 0 : target;
        return windowWidth >= targetWidth;
    };

    return { isScreenSizeBelow, isScreenSizeAbove };
}

export default useScreenSizeComparison;