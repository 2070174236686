import { Icon } from '@rsuite/icons';
import { BsQuestionCircle } from 'react-icons/bs';
import { Tooltip, Whisper } from 'rsuite';

import './style.css';

// Define the mapping object
const sizeMap = {
    xs: '1em',
    sm: '2em',
    md: '3em',
    lg: '4em'
}

const Help = ({
    message,
    speaker,
    trigger = 'hover',
    size = '1em',
    color
}) => {

    const defaultSpeaker = <Tooltip>{message}</Tooltip>

    const sizeValue = sizeMap[size]

    return (
        <Whisper placement="top" controlId="help-icon-hover" trigger={trigger} speaker={speaker || defaultSpeaker}>
            <span className='help-icon-container'>
                <Icon as={BsQuestionCircle} size={sizeValue} className='help-icon' color={color} />
            </span>
        </Whisper>
    )
}

export default Help
