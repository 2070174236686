import { useEffect, useState } from "react";
import { Col, Form, Grid, Row, Schema, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/Message";

import useApiTipoEstabelecimento from "../../hooks/useApiTipoEstabelecimento";
import useScreenSizeComparison from "../../hooks/useScreenSizeComparison";

const TipoEstabelecimento = () => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const { save, destroy, getAll, getId } = useApiTipoEstabelecimento();

    const [tiposEstabelecimento, setTiposEstabelecimento] = useState({})
    const [tipoEstabelecimento, setTipoEstabelecimento] = useState({})

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [deleteMode, setDeleteMode] = useState(false)

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    const getTiposEstabelecimento = async () => {
        if (!dataTableParams) return setTiposEstabelecimento({})
        setLoading(true)

        try {
            const data = await getAll({ ...dataTableParams })
            setTiposEstabelecimento(data)
        } catch (error) {
            setTiposEstabelecimento({});
            showError(error)
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => await getTiposEstabelecimento())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (id, isDelete) => {
        try {
            const data = await getId(id)
            setTipoEstabelecimento(data)
            setDeleteMode(isDelete)
            setOpenEditModal(true)
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNewButton = () => {
        setTipoEstabelecimento({
            idTipoEstabelecimento: null,
            nomeTipoEstabelecimento: ''
        });
        setDeleteMode(false);
        setOpenEditModal(true);
    }
    const handleClickEditButton = id => editData(id)
    const handleClickDeleteButton = id => editData(id, true)

    const handleClickCancelButton = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }

    const handleClickYesButton = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await destroy(tipoEstabelecimento.idTipoEstabelecimento) : await save(tipoEstabelecimento)

            showSucess()
            handleClickCancelButton()
            await getTiposEstabelecimento()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idTipoEstabelecimento' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const hiddenCol_sm = isScreenSizeBelow('sm')

    const dataTableColumns = {
        editButtonColumn: {
            dataKey: 'idTipoEstabelecimento',
            onClick: handleClickEditButton
        },
        deleteButtonColumn: {
            dataKey: 'idTipoEstabelecimento',
            onClick: handleClickDeleteButton
        },
        columns: [
            {
                dataKey: 'idTipoEstabelecimento',
                headerCell: 'Id',
                align: 'center',
                width: 100,
                sortable: true,
                hidden: hiddenCol_sm
            }, {
                dataKey: 'nomeTipoEstabelecimento',
                headerCell: 'Nome',
                sortable: true,
                flexGrow: 1
            }
        ]
    }

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        nomeTipoEstabelecimento: StringType().isRequired('Nome é obrigatório'),
    });

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget.name

        setTipoEstabelecimento(prevTipoEstabelecimento => ({
            ...prevTipoEstabelecimento,
            [fieldName]: value
        }))
    }

    return (
        <DataContainer title={"Cadastro de Tipos de Estabelecimentos"}>
            <DataTable
                data={tiposEstabelecimento}
                dataTableColumns={dataTableColumns}
                placeholderSearch='todas as colunas'
                textNewButton='Novo Tipo de Estabelecimento'
                onClickNewButton={handleClickNewButton}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
                xxl={12}
                xl={15}
                lg={18}
            />

            <DataModal
                title='Cadastro de Tipo de Estabelecimento'
                titleIdValue={tipoEstabelecimento.idTipoEstabelecimento}
                deleteMode={deleteMode}
                open={openEditModal}
                sizeModal='md'
                waiting={waiting}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancelButton}
                formValidation={model}
                formValue={(tipoEstabelecimento)}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="nomeTipoEstabelecimento">
                                <Form.ControlLabel>Nome do Tipo de Estabelecimento</Form.ControlLabel>
                                <Form.Control
                                    name="nomeTipoEstabelecimento"
                                    autoFocus={!deleteMode}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataModal>
            <Confirm
                open={openConfirm}
                message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados do Tipo de Estabelecimento?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYesButton}
            />
        </DataContainer >
    )
}

export default TipoEstabelecimento