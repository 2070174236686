import { useEffect, useRef, useState } from "react";
import { Avatar, Button, Col, Form, Grid, InputGroup, Popover, Row, Schema, Stack, Toggle, useToaster } from "rsuite";


import Assinatura from '../../components/Assinatura';
import Confirm from "../../components/Confirm";
import CustomSelectPicker from "../../components/CustomSelectPicker";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import Help from "../../components/Help";
import { showMessage } from "../../components/Message";

import userApiPerfil from "../../hooks/useApiPerfil";
import useApiUsuario from "../../hooks/useApiUsuario";

import './style.css';

const HelpTask = ({ codigoAcessoUsuario, perfilItem }) => {
    const speaker = (
        <Popover title={`Tarefas Permitidas - ${codigoAcessoUsuario}`} className="usuario-popover-tarefas">
            <div className="usuario-popover-tarefas-content">
                {perfilItem.map((c, index) => <p key={index}>{c.Menu.nomeTarefa}</p>)}
            </div>
        </Popover >
    );

    return <Help size="xs" trigger="click" speaker={speaker} />
};

const Usuario = () => {
    const { save, destroy, getAll, getId, resetPassword, verifyTaskPermission } = useApiUsuario();
    const apiPerfil = userApiPerfil();

    const formGroupRef = useRef()

    const [usuarios, setUsuarios] = useState({})
    const [usuario, setUsuario] = useState({})

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openConfirmReset, setOpenConfirmReset] = useState(false)

    const [permissionResetPassword, setPermissionResetPassword] = useState(false)

    const [deleteMode, setDeleteMode] = useState(false)

    const toaster = useToaster()
    const showSucessReset = () => showMessage({ toaster, type: 'success', message: `Reset de senha concluído com sucesso` })
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    //Para verificar se tem permissão na tarefa de reset de senha
    useEffect(() => {
        (async () => {
            try {
                const data = await verifyTaskPermission('tarefa_resetar_senha_usuario')
                if (data) setPermissionResetPassword(data.permission)
                else setPermissionResetPassword(false)
            } catch { setPermissionResetPassword(false) }
        })()
        // eslint-disable-next-line
    }, [])

    const getUsuarios = async () => {
        if (!dataTableParams) return setUsuarios({})
        setLoading(true)

        try {
            const data = await getAll({ ...dataTableParams })
            setUsuarios(data)
        } catch (error) {
            setUsuarios({});
            showError(error)
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => await getUsuarios())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (id, isDelete) => {
        try {
            const data = await getId(id)
            if (data) {
                setUsuario(data)
                setDeleteMode(isDelete);
                setOpenEditModal(true);
            }
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNew = () => {
        setUsuario({
            idUsuario: null,
            codigoAcessoUsuario: '',
            emailUsuario: '',
            nomeUsuario: '',
            cargoUsuario: '',
            marcaHorario: false,
            vendedor: false,
            assinaturaUsuario: null,
            idPerfil: null,
            ativo: true
        });
        setDeleteMode(false);
        setOpenEditModal(true);
    }
    const handleClickEdit = id => editData(id)
    const handleClickDelete = id => editData(id, true)

    const handleClickCancel = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }

    const handleClickYes = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await destroy(usuario.idUsuario) : await save(usuario)

            showSucess()
            handleClickCancel()
            await getUsuarios()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idUsuario' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const customCellContent = (rowData, col) => <div style={!rowData['ativo'] ? { color: 'red' } : {}}>{rowData[col.dataKey]}</div>
    const customCellContentYesNo = (rowData, col) => <div style={!rowData['ativo'] ? { color: 'red' } : {}}>{rowData[col.dataKey] ? "Sim" : "Não"}</div>

    const dataTableColumns = {
        editButtonColumn: {
            dataKey: 'idUsuario',
            onClick: handleClickEdit
        },
        deleteButtonColumn: {
            dataKey: 'idUsuario',
            onClick: handleClickDelete
        },
        columns: [
            {
                dataKey: 'fotoPerfilUsuario',
                avatar: true,
                width: 70,
                align: 'center'
            }, {
                dataKey: 'codigoAcessoUsuario',
                headerCell: 'Cód. Acesso',
                width: 130,
                sortable: true,
                fullText: true,
                customCellContent: customCellContent
            }, {
                dataKey: 'nomeUsuario',
                headerCell: 'Nome',
                flexGrow: 1,
                minWidth: 250,
                sortable: true,
                fullText: true,
                customCellContent: customCellContent
            }, {
                dataKey: 'emailUsuario',
                headerCell: 'e-mail',
                width: 250,
                sortable: true,
                fullText: true,
                customCellContent: customCellContent
            }, {
                dataKey: 'nomePerfil',
                headerCell: 'Perfil de acesso',
                width: 200,
                sortable: true,
                customCellContent: (rowData, col) => rowData.Perfil &&
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={5}
                        style={!rowData.ativo ? { color: 'red' } : {}}
                    >
                        <HelpTask codigoAcessoUsuario={rowData.codigoAcessoUsuario} perfilItem={rowData.Perfil.PerfilItems} key={rowData.idUsuario} />
                        {rowData.Perfil.nomePerfil}
                    </Stack>
            }, {
                dataKey: 'cargoUsuario',
                headerCell: 'Cargo',
                width: 200,
                sortable: true,
                customCellContent: customCellContent
            }, {
                dataKey: 'marcaHorario',
                headerCell: 'M.Hora',
                width: 90,
                sortable: true,
                align: 'center',
                customCellContent: customCellContentYesNo
            }, {
                dataKey: 'vendedor',
                headerCell: 'Vendedor',
                width: 100,
                sortable: true,
                align: 'center',
                customCellContent: customCellContentYesNo
            }, {
                dataKey: 'ativo',
                headerCell: 'Ativo',
                width: 70,
                align: 'center',
                sortable: true,
                customCellContent: customCellContentYesNo
            }
        ]
    }

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget.name
        const fieldValue = ['emailUsuario', 'codigoAcessoUsuario'].includes(fieldName) ? value.toLowerCase() : value

        setUsuario(prevUsuario => ({
            ...prevUsuario,
            [fieldName]: fieldValue
        }));
    }

    const handleClickYesReset = async () => {
        setOpenConfirmReset(false)
        setWaiting(true)
        try {
            await resetPassword(usuario.idUsuario)

            showSucessReset()
            handleClickCancel()
            await getUsuarios()
        } catch (error) {
            showError(error)
        }
    }

    const ExtraButtons = () => {
        if (deleteMode || !permissionResetPassword) return (<></>)

        return (
            <Stack justifyContent="flex-start">
                <Button onClick={() => setOpenConfirmReset(true)} appearance="ghost" color='blue'>
                    Resetar Senha
                </Button>
                <Help message={'Após o reset, a nova senha será igual ao código de acesso (senha padrão)'} />
            </Stack>
        )
    }

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        codigoAcessoUsuario: StringType().isRequired('Código de acesso é obrigatório'),
        nomeUsuario: StringType().isRequired('Nome é obrigatório'),
        emailUsuario: StringType()
            .isRequired('E-mail é obrigatório')
            .isEmail('Informe um e-mail válido'),
        cargoUsuario: StringType().isRequired('Cargo é obrigatório')
    });

    return (
        <DataContainer title={"Cadastro de Usuários"}>
            <DataTable
                data={usuarios}
                dataTableColumns={dataTableColumns}
                placeholderSearch='todas as colunas'
                textNewButton='Novo Usuário'
                onClickNewButton={handleClickNew}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
                xxl={20}
            />

            <DataModal
                title='Cadastro de Usuário'
                titleIdValue={usuario.idUsuario}
                deleteMode={deleteMode}
                open={openEditModal}
                sizeModal='lg'
                waiting={waiting}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancel}
                formValidation={model}
                formValue={usuario}
                ExtraButtonsLeft={usuario.idUsuario && permissionResetPassword ? <ExtraButtons /> : undefined}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24} md={8}>
                            <Stack direction='column' alignItems='center'>
                                <Avatar className='avatar-perfil-200' src={usuario.fotoPerfilUsuario} circle />
                            </Stack>
                        </Col>
                        <Col xs={24} md={16}>
                            <Row>
                                <Col xs={8}>
                                    <Form.Group controlId="ativo">
                                        <Form.ControlLabel>Ativo</Form.ControlLabel>
                                        <Form.Control
                                            name="ativo"
                                            accepter={Toggle}
                                            readOnly={deleteMode || !usuario.idUsuario}
                                            size='lg'
                                            checkedChildren="Sim"
                                            unCheckedChildren="Não"
                                            onChange={handleInputChange}
                                            className="toggle-red-green"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={16}>
                                    <Form.Group controlId="codigoAcessoUsuario">
                                        <Form.ControlLabel>Código de Acesso</Form.ControlLabel>
                                        <Form.Control
                                            name="codigoAcessoUsuario"
                                            autoFocus={!deleteMode && !usuario.idUsuario}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Form.Group controlId="nomeUsuario">
                                        <Form.ControlLabel>Nome do Usuário</Form.ControlLabel>
                                        <Form.Control
                                            name="nomeUsuario"
                                            autoFocus={!deleteMode && usuario.idUsuario}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Form.Group controlId="emailUsuario">
                                        <Form.ControlLabel>Email</Form.ControlLabel>
                                        <InputGroup>
                                            <InputGroup.Addon> @</InputGroup.Addon>
                                            <Form.Control
                                                name="emailUsuario"
                                                type="email"
                                                onChange={handleInputChange}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Form.Group controlId="cargoUsuario">
                                        <Form.ControlLabel>Cargo do Usuário</Form.ControlLabel>
                                        <Form.Control
                                            name="cargoUsuario"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={24} xs={24}>
                                    <Form.ControlLabel>
                                        <div className="form-group-inline">
                                            <Toggle
                                                size='sm'
                                                name="marcaHorario"
                                                defaultChecked={usuario.marcaHorario}
                                                readOnly={deleteMode}
                                                onChange={handleInputChange} />
                                            Usuário faz marcação de horário
                                            <Help size="xs" message="O usuário é um funcionário que marca o horário de trabalho pelo sistema" />
                                        </div>
                                    </Form.ControlLabel>
                                </Col>
                                <Col lg={12} md={24} xs={24}>
                                    <Form.ControlLabel>
                                        <div className="form-group-inline">
                                            <Toggle
                                                size='sm'
                                                name="vendedor"
                                                defaultChecked={usuario.vendedor}
                                                readOnly={deleteMode}
                                                onChange={handleInputChange} />
                                            Usuário é vendedor
                                            <Help size="xs" message="O usuário pode ser vinculado como vendedor no cadastro de Cliente" />
                                        </div>
                                    </Form.ControlLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Form.Group controlId="idPerfil" ref={formGroupRef}>
                                        <Form.ControlLabel>Perfil de Acesso</Form.ControlLabel>
                                        <Form.Control
                                            accepter={CustomSelectPicker}
                                            formGroupRef={formGroupRef}
                                            name="idPerfil"
                                            placeholder="Selecione o perfil de acesso"
                                            onChange={value => setUsuario(prevUsuario => ({ ...prevUsuario, idPerfil: value }))}
                                            apiGetData={apiPerfil.getAll}
                                            apiGetInitialData={apiPerfil.getId}
                                            getDataObject={item => ({ value: item.idPerfil, label: item.nomePerfil })}
                                            showError={showError}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Form.Group controlId="assinaturaUsuario">
                                        <Form.ControlLabel>Assinatura</Form.ControlLabel>
                                        <Form.Control
                                            name="assinaturaUsuario"
                                            accepter={Assinatura}
                                            height={250}
                                            readOnly={deleteMode}
                                            marginBottom={0}
                                            value={usuario.assinaturaUsuario}
                                            onChange={value => setUsuario({ ...usuario, assinaturaUsuario: value })}
                                            showSignButton={false}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Grid>
            </DataModal>
            <Confirm
                open={openConfirm}
                message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados do Usuário?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYes}
            />
            {permissionResetPassword && !deleteMode &&
                <Confirm
                    open={openConfirmReset}
                    message={`Confirma o reset da senha do Usuário?`}
                    onClickNoButton={() => setOpenConfirmReset(false)}
                    onClickYesButton={handleClickYesReset}
                />
            }
        </DataContainer>
    )
}

export default Usuario