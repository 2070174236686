import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Avatar, Button, Container, CustomProvider, Dropdown, IconButton, Navbar, Panel, Popover, Stack, Toggle, Whisper } from 'rsuite';

import { AiFillBell } from 'react-icons/ai';
import { FaUser } from 'react-icons/fa';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { IoApps } from 'react-icons/io5';

import { AuthContext } from '../../contexts/authContext';

import useApiMenu from '../../hooks/useApiMenu';

import { getSubdomain } from "../../services/subdomain";
import { imageBase64FromURL } from '../../services/utils';

import AlterarSenha from '../../pages/AlterarSenha';
import MeusDados from '../../pages/MeusDados';

import ptBR from 'rsuite/locales/pt_BR';
import './style.css';

const MenuItemTitle = ({ title }) => (
	title &&
	<>
		<Dropdown.Item panel className='menu-title'>
			{title}
		</Dropdown.Item>
		<Dropdown.Separator />
	</>
)

const MenuItem = ({ item, backTo, goTo, ...rest }) => (
	<Dropdown.Item className='menu-item' {...rest}>
		{backTo && <FiArrowLeft />}
		{item.icon && <img src={item.icon} alt="icon" />}
		{item.text}
		{item.goTo && <div className='menu-item-right'><FiArrowRight /></div>}
	</Dropdown.Item >
)

const MenuItemLink = ({ item }) => (
	<Link to={item.path} className='menu-item-link' state={{ breadcrumb: item.breadcrumb }}>
		<Dropdown.Item className='menu-item'>
			{item.iconLeft || (item.icon && <img src={item.icon} alt="icon" />)}
			{item.text}
		</Dropdown.Item >
	</Link>
)

const MenuNotificacao = forwardRef(({ onSelect, ...rest }, ref) => {
	return (
		<Popover ref={ref} {...rest} title="Notificações">
			<p>Não há notificações</p>
		</Popover>
	)
})

const MenuPrincipal = forwardRef(({ onSelect, menuData, menuFirst, ...rest }, ref) => {
	const [menu, setMenu] = useState(menuData[menuFirst])

	return (
		<Popover ref={ref} {...rest} style={{ padding: '5px' }} >
			<Dropdown.Menu onSelect={onSelect} className='menu-container'>
				<MenuItemTitle title={menu.title} />
				{
					menu.items.map((item, index) => {
						if (item.separator) return <Dropdown.Separator key={index} />
						if (item.goTo) return <MenuItem key={index} item={item} onClick={e => setMenu(menuData[item.goTo])} goTo={true} />
						if (item.backTo) return < MenuItem key={index} item={item} onClick={e => setMenu(menuData[item.backTo])} backTo={true} />
						return <MenuItemLink key={index} item={item} />
					})
				}
			</Dropdown.Menu>
		</Popover>
	)
});

const MenuPerfil = forwardRef(({ onSelect, icons, ...rest }, ref) => {
	const { user, darkMode, toggleDarkMode } = useContext(AuthContext)

	return (
		<Popover ref={ref} {...rest} style={{ padding: '5px' }} >
			<Dropdown.Menu onSelect={onSelect}>
				<Dropdown.Item panel>
					<Panel shaded className='menu-perfil-panel-info' bodyFill>
						<Stack direction='row'>
							{
								user && user.fotoPerfilUsuario
									? <Avatar circle size='lg' src={user.fotoPerfilUsuario} alt="icon" />
									: <Avatar circle size='lg'><FaUser /></Avatar>
							}
							<Stack direction='column' alignItems='flex-start'>
								<p className='menu-perfil-panel-info-nome'>{user && user.nomeUsuario}</p>
								<p className='menu-perfil-panel-info-cargo'>{user && user.cargoUsuario}</p>
							</Stack>
						</Stack>
					</Panel>
				</Dropdown.Item>
				<Dropdown.Item panel className='menu-item-panel'>
					<strong>Mudar tema</strong>
					<Stack spacing={3}>
						<img src={icons.darkmodesun} style={{ cursor: 'pointer' }} onClick={() => darkMode ? toggleDarkMode() : null} alt='light mode' />
						<Toggle checked={darkMode} onChange={toggleDarkMode} size="sm" />
						<img src={icons.darkmodemoon} style={{ cursor: 'pointer' }} onClick={() => !darkMode ? toggleDarkMode() : null} alt='dark mode' />
					</Stack>
				</Dropdown.Item>
				<Dropdown.Separator />
				<MenuItem item={{ text: 'Meus Dados', icon: icons.userperfil }} eventKey='perfil' />
				<MenuItem item={{ text: 'Alterar Senha', icon: icons.trocasenha }} eventKey='senha' />
				<Dropdown.Separator />
				<MenuItem item={{ text: 'Sair', icon: icons.logout }} eventKey='logout' />
			</Dropdown.Menu>
		</Popover>
	)
})

const Menu = () => {
	const navigate = useNavigate()
	const { getMenuUser, getIconList } = useApiMenu()

	const { user, darkMode, logout } = useContext(AuthContext)

	const [imgIconsBase64, setImgIconsBase64] = useState({})

	const [imgLogoBase64, setImgLogoBase64] = useState();
	const [menuData, setMenuData] = useState();
	const [menuFirst, setMenuFirst] = useState();

	const [openMeusDados, setOpenMeusDados] = useState(false);
	const [openSenha, setOpenSenha] = useState(false);

	const menuRef = useRef()

	useEffect(() => {
		(async () => {
			const subdomain = getSubdomain()
			if (subdomain) {
				const fileLogo = `./logo/${subdomain}/logo`

				const imgLogoDefault = await imageBase64FromURL(`${fileLogo}.png`)
				const imgLogoDarkMode = await imageBase64FromURL(`${fileLogo}-darkmode.png`)

				const imgLogo = darkMode && imgLogoDarkMode ? imgLogoDarkMode : imgLogoDefault
				setImgLogoBase64(imgLogo)
			}

			const iconList = await getIconList()
			for (const icon of iconList) {
				setImgIconsBase64(prevIcon => ({
					...prevIcon,
					[icon.nomeIcon]: icon.imageIcon
				}))
			}

			const menu = await getMenuUser()
			setMenuData(menu.menuData)
			setMenuFirst(menu.firstMenu)
		})()

		//Fecha o menu quando redimensionar a tela
		const handleWindowResize = () => {
			if (menuRef.current) menuRef.current.close();
		};

		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);

		// eslint-disable-next-line
	}, [darkMode]);

	const handleSelectMenu = (eventKey, event) => {
		switch (eventKey) {
			case 'logout':
				logout()
				navigate('/login', { replace: true })
				break;
			case 'senha':
				setOpenSenha(true)
				break;
			case 'perfil':
				setOpenMeusDados(true)
				break;
			default:
				break;
		}

		menuRef.current.close()
	}

	return (
		<>
			<CustomProvider theme={darkMode ? 'dark' : 'light'} locale={ptBR}>
				<Navbar className='menu-navbar'>
					<Navbar.Brand className='menu-logo' href="/">
						{imgLogoBase64 && <img src={imgLogoBase64} alt="logo" />}
					</Navbar.Brand>
					<Stack
						className='menu-stack'
						wrap
						spacing={10}
						alignItems='center'
						justifyContent='flex-end'>

						<Whisper
							placement="bottomEnd"
							trigger="click"
							controlId='MenuPrincipal'
							ref={menuRef}
							speaker={menuData && <MenuPrincipal onSelect={handleSelectMenu} menuData={menuData} menuFirst={menuFirst} />}
						>
							<IconButton circle className='menu-icon-button' icon={<IoApps />} />
						</Whisper>

						<Whisper
							placement="bottomEnd"
							trigger="click"
							controlId="MenuNotificacao"
							ref={menuRef}
							speaker={<MenuNotificacao />}
						>
							<IconButton circle className='menu-icon-button' icon={<AiFillBell />} />
						</Whisper>

						<Whisper
							placement="bottomEnd"
							trigger="click"
							controlId="MenuPerfil"
							ref={menuRef}
							speaker={imgIconsBase64 && <MenuPerfil onSelect={handleSelectMenu} icons={imgIconsBase64} />}
						>
							{user && user.fotoPerfilUsuario
								? <Button className='menu-avatar-button'>
									<Avatar circle src={user.fotoPerfilUsuario} alt="icon" />
								</Button>
								: <IconButton circle className='menu-icon-button' icon={<FaUser />} />
							}
						</Whisper>
					</Stack>
				</Navbar>
				<Container className='menu-content-container'>
					<Outlet />
					{openMeusDados && <MeusDados setOpen={setOpenMeusDados} />}
					{openSenha && <AlterarSenha setOpen={setOpenSenha} />}
				</Container>
			</CustomProvider>
		</>
	)
}

export default Menu
