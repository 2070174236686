import { useState } from "react";
import { Form, InputGroup, Schema, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import DataModal from "../../components/DataModal";
import { showMessage } from "../../components/Message";

import useApiMeusDados from "../../hooks/useApiMeusDados";

import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

const PasswordInput = ({ name, label, ...rest }) => {

    const [visible, setVisible] = useState(false)
    const handleButtonClick = () => setVisible(!visible);

    return (
        <Form.Group controlId={name}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <InputGroup inside>
                <Form.Control name={name}
                    autoComplete="off"
                    type={visible ? 'text' : 'password'}
                    {...rest}
                />
                <InputGroup.Button onClick={handleButtonClick} tabIndex={-1}>
                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                </InputGroup.Button>
            </InputGroup>
        </Form.Group>
    )
}

const AlterarSenha = ({ setOpen }) => {

    const { changePassword } = useApiMeusDados();

    const [waiting, setWaiting] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [passwordData, setPasswordData] = useState({
        password: 'password',
        newPassword: 'password',
        confirmNewPassword: 'password'
    })

    const handleClickYesButton = async () => {
        setOpenConfirm(false);
        setWaiting(true);

        try {
            await changePassword(passwordData);
            showSucess();
        } catch (error) {
            showError(error)
        }
    }

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, message: `Senha alterada com sucesso`, executeFirst: () => setWaiting(false), executeLast: () => setOpen(false) })
    const showError = error => showMessage({ toaster, message: error, executeFirst: () => setWaiting(false) })

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget.name

        setPasswordData((prevPasswordData) => ({
            ...prevPasswordData,
            [fieldName]: value
        }));
    }

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        password: StringType().isRequired('Senha é obrigatória'),
        newPassword: StringType().isRequired('Nova senha é obrigatória'),
        confirmNewPassword: StringType()
            .addRule((value, data) => value === data.newPassword, 'Nova senha e confirmação não combinam')
            .isRequired('Confirmação da nova senha é obrigatória')
    });

    return (
        <>
            <DataModal
                bodyHeight={280}
                title='Alteração de senha'
                open={true}
                sizeModal='sm'
                waiting={waiting}
                customTextSubmitButton='Confirmar'
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={() => setOpen(false)}
                defaultValue={passwordData}
                formValidation={model}
            >

                <PasswordInput name="password" label="Senha Atual" onChange={handleInputChange} />
                <PasswordInput name="newPassword" label="Nova senha" onChange={handleInputChange} />
                <PasswordInput name="confirmNewPassword" label="Confirmação da nova senha" onChange={handleInputChange} />

            </DataModal>

            <Confirm
                open={openConfirm}
                message={`Confirma a alteração da senha?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYesButton}
            />
        </>
    )
}

export default AlterarSenha
