import PropTypes from 'prop-types';
import { MaskedInput } from "rsuite";

const CustomInputCnpjCpf = ({ onChange, tipoFisJur, inputRef, ...rest }) => {
    const handleInputChange = (value, event) => {
        const input = value.replace(/\D/g, '');
        onChange(input, event);
    };

    const cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
    const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]

    return <MaskedInput
        mask={tipoFisJur === 'J' ? cnpjMask : cpfMask}
        onChange={handleInputChange}
        ref={inputRef}
        {...rest} />;
}

CustomInputCnpjCpf.propTypes = {
    onChange: PropTypes.func.isRequired,
    tipoFisJur: PropTypes.string.isRequired
}


export default CustomInputCnpjCpf