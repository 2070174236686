import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import React, { useState } from 'react';
import { Text } from 'rsuite';

import { getSubdomain } from "../../services/subdomain";

const MarcadorOrigem = ({
    coordenadas,
    endereco
}) => {
    const [infowindowOpen, setInfowindowOpen] = useState(true);
    const [markerRef, marker] = useAdvancedMarkerRef();

    const subdomain = getSubdomain()
    const fileLogo = subdomain ? `./logo/${subdomain}/logo.png` : null

    return (
        <>
            <AdvancedMarker
                ref={markerRef}
                onClick={() => setInfowindowOpen(true)}
                position={coordenadas}
                title={endereco.nome}
            />
            {infowindowOpen &&
                <InfoWindow
                    className='mapa-marker'
                    anchor={marker}
                    onCloseClick={() => setInfowindowOpen(false)}
                    headerContent={fileLogo
                        ? <img src={fileLogo} alt='logo' style={{ maxWidth: 128, margin: 2 }} />
                        : <Text className='mapa-marker' weight='extrabold'>{endereco.nome}</Text>
                    }
                >
                    {!fileLogo &&
                        <>
                            <Text>{endereco.endereco}</Text>
                            <Text>{endereco.bairro}</Text>
                            <Text>{endereco.cidade} - {endereco.uf}</Text>
                            <Text>{endereco.cep}</Text>
                        </>
                    }
                </InfoWindow>
            }
        </>
    )
}

export default MarcadorOrigem