import ApiService from '../services/api.js';

const useApiAuth = () => {

    const resource = '/auth'

    const apiService = new ApiService()
    const api = apiService.api

    const login = async (user, password) => {
        const response = await api.post(`${resource}/login`, {
            user,
            password
        })
        return response.data;
    }

    const logout = async () => {
        await api.post(`/auth/logout`);
        return true
    }

    const validate = async () => {
        const response = await api.post(`${resource}/validate`);
        return response.data;
    }

    const recovery = async user => {
        const response = await api.post(`${resource}/recovery`, { user })
        return response.data;
    }

    return {
        login,
        logout,
        validate,
        recovery
    }
}

export default useApiAuth