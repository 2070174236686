import axios from 'axios';

import { getSubdomain } from './subdomain';
import { getToken, setToken } from './token';

class ApiService {
    constructor() {
        this.subdomain = getSubdomain()

        this.api = axios.create({
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}${this.subdomain}.${process.env.REACT_APP_API_DOMAIN}:${process.env.REACT_APP_API_PORT}`,
            headers: {
                "Content-Type": "application/json"
            }
        });

        this.api.interceptors.request.use(request => {
            const token = getToken();
            if (token) request.headers.authorization = `Bearer ${token} `;

            return request;
        });

        this.api.interceptors.response.use(response => response, error => {
            if (error && error.response && error.response.status) {
                if (error.response.status === 401 && window.location.pathname !== '/login') {
                    setToken('');
                    window.location.replace('/login');
                    return Promise.reject({ code: 401, message: "Acesso não autorizado." });
                }

                if (error.response.status === 403) {
                    window.location.replace('/');
                    return Promise.reject({ code: 403, message: "Acesso não permitido." });
                }

                if (error.response.data && error.response.data.message) {
                    return Promise.reject({ code: error.response.status, message: error.response.data.message })
                }
            }

            return Promise.reject(error);
        });
    }
}

export default ApiService;