import ApiService from '../services/api.js';

const useApiUF = () => {

    const apiService = new ApiService()
    const api = apiService.api

    const resource = '/uf'

    const getAll = async ({ pageNumber, itemsPerPage, sortColumn, sortType, searchText }) => {
        const response = await api.get(resource, {
            params: {
                pageNumber,
                itemsPerPage,
                sortColumn,
                sortType,
                searchText
            }
        })
        return response.data;
    }

    const getId = async id => {
        const response = await api.get(`${resource}/${id}`)
        return response.data;
    }

    return {
        getAll,
        getId
    }
}

export default useApiUF
