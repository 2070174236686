import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Grid, Radio, RadioGroup, Row, Schema, Stack, Text, Toggle, useToaster } from "rsuite";

import { Icon } from "@rsuite/icons";
import PlusIcon from '@rsuite/icons/Plus';
import { IoLocationOutline } from "react-icons/io5";
import { MdEmojiPeople } from 'react-icons/md';
import { PiFactory } from 'react-icons/pi';

import Alert from "../../components/Alert";
import Confirm from "../../components/Confirm";
import ContatoCadastro from "../../components/ContatoCadastro";
import ContatoList from "../../components/ContatoList";
import CustomInputCep from "../../components/CustomInputCep";
import CustomInputCnpjCpf from "../../components/CustomInputCnpjCpf";
import CustomInputNumero from "../../components/CustomInputNumero";
import CustomInputNumeroEndereco from "../../components/CustomInputNumeroEndereco";
import CustomInputTelefone from "../../components/CustomInputTelefone";
import CustomSelectPicker from "../../components/CustomSelectPicker";
import CustomTextArea from "../../components/CustomTextArea";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import Help from "../../components/Help";
import Mapa from "../../components/Mapa";
import { showMessage } from "../../components/Message";

import { apiGetCep, apiGetCnpj } from '../../services/brasilApi';
import { isCNPJ, isCPF } from "../../services/utils";

import useApiCliente from "../../hooks/useApiCliente";
import useApiLocalizacao from "../../hooks/useApiLocalizacao";
import useApiMunicipio from "../../hooks/useApiMunicipio";
import useApiRamoAtividade from "../../hooks/useApiRamoAtividade";
import useApiUF from "../../hooks/useApiUF";
import useApiUsuario from "../../hooks/useApiUsuario";
import useApiVendedor from "../../hooks/useApiVendedor";
import useScreenSizeComparison from "../../hooks/useScreenSizeComparison";

import AcessaSistema from "./AcessaSistema";

import './style.css';

const ClienteCustomCellContent = ({ rowData, column }) => {
    const content = column.dataKey === 'nomeMunicipio' ? rowData.Municipio && rowData.Municipio.nomeMunicipio
        : column.dataKey === 'siglaUF' ? rowData.UF && rowData.UF.siglaUF
            : rowData[column.dataKey]

    return (
        <div className={`${!rowData.ativo ? 'datatable-cellcontent-cliente-inativo' : ''}`}>
            {content}
        </div>
    )
}

const Cliente = ({ consulta }) => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const { save, destroy, getAll, getId, resetPassword, getCnpjCpf } = useApiCliente()
    const { verifyTaskPermission } = useApiUsuario()
    const { getCoordenadas } = useApiLocalizacao()
    const apiUF = useApiUF()
    const apiMunicipio = useApiMunicipio()
    const apiRamoAtividade = useApiRamoAtividade()
    const apiVendedor = useApiVendedor()

    const cnpjCpfRef = useRef()
    const formGroupRefUF = useRef()
    const formGroupRefMunicipio = useRef()
    const formGroupRefRamoAtividade = useRef()
    const formGroupRefVendeodr = useRef()

    const [clientes, setClientes] = useState({})
    const [cliente, setCliente] = useState({})
    const [clienteContato, setClienteContato] = useState()
    const [clienteJaCadastrado, setClienteJaCadastrado] = useState()

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingApiCnpj, setLoadingApiCnpj] = useState(false)
    const [loadingApiCep, setLoadingApiCep] = useState(false)

    const [openEditModal, setOpenEditModal] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openContato, setOpenContato] = useState(false)
    const [openConfirmReset, setOpenConfirmReset] = useState(false)

    const [openMapa, setOpenMapa] = useState(false)
    const [dadosMapa, setDadosMapa] = useState()

    const [permissionLiberaAcessoCliente, setPermissionLiberaAcessoCliente] = useState(false)

    const [deleteMode, setDeleteMode] = useState(false)
    const [deleteModeContato, setDeleteModeContato] = useState(false)

    const [cnpjInitial, setCnpjInitial] = useState()
    const [cepInitial, setCepInitial] = useState()

    const toaster = useToaster();
    const showSucessReset = () => showMessage({ toaster, type: 'success', message: `Reset de senha concluído com sucesso` })
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    //Para verificar se tem permissão para permitir acesso ao sistema
    useEffect(() => {
        (async () => {
            try {
                const data = await verifyTaskPermission('tarefa_liberar_acesso_cliente')
                if (data) setPermissionLiberaAcessoCliente(data.permission)
                else setPermissionLiberaAcessoCliente(false)
            } catch { setPermissionLiberaAcessoCliente(false) }
        })()
        // eslint-disable-next-line
    }, [])

    const getClientes = async () => {
        if (!dataTableParams) return setClientes({})
        setLoading(true)

        try {
            const data = await getAll({ ...dataTableParams })
            setClientes(data)
        } catch (error) {
            setClientes({})
            showError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        (async () => await getClientes())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (id, isDelete) => {
        try {
            const data = await getId(id)
            setCliente(data)
            setCnpjInitial(data.cnpjCpfCliente)
            setCepInitial(data.cepCliente)
            setDeleteMode(isDelete)
            setOpenEditModal(true)
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNew = () => {
        setCliente({
            ativo: true,
            acessoSistema: false,
            idCliente: null,
            fisJurCliente: 'J',
            cnpjCpfCliente: '',
            ieCliente: '',
            nomeCliente: '',
            nomeFantasiaCliente: '',
            enderecoCliente: '',
            numeroEnderecoCliente: '',
            complementoCliente: '',
            bairroCliente: '',
            cepCliente: '',
            idMunicipio: '',
            siglaUF: '',
            telefone1Cliente: '',
            telefone2Cliente: '',
            emailCliente: '',
            idRamoAtividade: null,
            idVendedor: null,
            areaTotalM2Cliente: null,
            qtdePontoPermanenteEnvenenamento: null,
            qtdePontoPermanenteCaptura: null,
            qtdeIscaLuminosa: null,
            obsCliente: '',
            ClienteContatos: []
        })
        setClienteJaCadastrado(null)
        setCnpjInitial('')
        setCepInitial('')
        setDeleteMode(false)
        setOpenEditModal(true)
    }
    const handleClickEdit = id => editData(id)
    const handleClickDelete = id => editData(id, true)

    const editDataContato = async (indexContato, isDelete) => {
        if (!cliente || !cliente.ClienteContatos || cliente.ClienteContatos.length < indexContato - 1) return

        const contato = cliente.ClienteContatos[indexContato]
        setClienteContato({
            indexContato: indexContato,
            nomeContato: contato.nomeClienteContato,
            telefoneContato: contato.telefoneClienteContato,
            emailContato: contato.emailClienteContato,
            obsContato: contato.obsClienteContato
        })
        setDeleteModeContato(isDelete)
        setOpenContato(true)
    }

    const handleClickNewContato = () => {
        setClienteContato({})
        setDeleteModeContato(false)
        setOpenContato(true)
    }
    const handleClickConfirmContato = contato => {
        const contatos = cliente.ClienteContatos || []
        const indexContato = contato.indexContato

        if (deleteModeContato) {
            if (indexContato !== null && contatos.length > indexContato) {
                contatos.splice(indexContato, 1)

                setCliente(prevCliente => ({
                    ...prevCliente,
                    ClienteContatos: [
                        ...contatos
                    ]
                }))
            }
        } else {
            const contatoCliente = {
                nomeClienteContato: contato.nomeContato,
                telefoneClienteContato: contato.telefoneContato,
                emailClienteContato: contato.emailContato,
                obsClienteContato: contato.obsContato
            }

            if (indexContato !== null && indexContato >= 0) {
                contatos[contato.indexContato] = contatoCliente
            } else {
                contatos.push(contatoCliente)
            }

            setCliente(prevCliente => ({
                ...prevCliente,
                ClienteContatos: contatos
            }))
        }

        setOpenContato(false)
    }
    const handleClickEditContato = indexContato => editDataContato(indexContato)
    const handleClickDeleteContato = indexContato => editDataContato(indexContato, true)

    const handleClickCancel = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }
    const handleClickYesConfirm = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await destroy(cliente.idCliente) : await save(cliente)

            showSucess()
            handleClickCancel()
            await getClientes()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idCliente' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }
    const handleClickLoadData = async () => {
        setCnpjInitial(clienteJaCadastrado.cnpjCpfCliente)
        setCepInitial(clienteJaCadastrado.cepCliente)
        setCliente(clienteJaCadastrado)
        setClienteJaCadastrado(null)
    }
    const handleClickYesReset = async () => {
        setOpenConfirmReset(false)
        setWaiting(true)
        try {
            await resetPassword(cliente.idCliente)

            showSucessReset()
            handleClickCancel()
            await getClientes()
        } catch (error) {
            showError(error)
        }
    }

    // const hiddenCol_xl = isScreenSizeBelow('xl')
    const hiddenCol_lg = isScreenSizeBelow('lg')
    const hiddenCol_md = isScreenSizeBelow('md')

    const dataTableColumns = {
        displayButtonColumn: {
            dataKey: 'idCliente',
            onClick: handleClickEdit,
            hidden: () => !consulta
        },
        editButtonColumn: {
            dataKey: 'idCliente',
            onClick: handleClickEdit,
            hidden: () => consulta
        },
        deleteButtonColumn: {
            dataKey: 'idCliente',
            onClick: handleClickDelete,
            hidden: () => consulta
        },
        columns: [
            {
                dataKey: 'idCliente',
                headerCell: 'Id',
                align: 'center',
                width: 100,
                sortable: true,
                hidden: hiddenCol_md,
                customCellContent: (rowData, column) => <ClienteCustomCellContent rowData={rowData} column={column} />
            }, {
                dataKey: 'nomeCliente',
                headerCell: 'Nome',
                sortable: true,
                fullText: true,
                flexGrow: 2,
                customCellContent: (rowData, column) => <ClienteCustomCellContent rowData={rowData} column={column} />
            }, {
                dataKey: 'nomeFantasiaCliente',
                headerCell: 'Nome Fantasia',
                sortable: true,
                fullText: true,
                flexGrow: 2,
                hidden: hiddenCol_lg,
                customCellContent: (rowData, column) => <ClienteCustomCellContent rowData={rowData} column={column} />
            }, {
                dataKey: 'nomeMunicipio',
                headerCell: 'Município',
                sortable: true,
                fullText: true,
                flexGrow: 1,
                minwidth: 150,
                hidden: hiddenCol_lg,
                customCellContent: (rowData, column) => <ClienteCustomCellContent rowData={rowData} column={column} />
            }, {
                dataKey: 'siglaUF',
                headerCell: 'UF',
                sortable: true,
                width: 80,
                align: 'center',
                hidden: hiddenCol_lg,
                customCellContent: (rowData, column) => <ClienteCustomCellContent rowData={rowData} column={column} />
            }
        ]
    }

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget ? event.currentTarget.name : event

        const newValue =
            fieldName === 'ieCliente' ? value.toUpperCase()
                : fieldName === 'emailCliente' ? value.toLowerCase()
                    : value

        setCliente(prevCliente => ({
            ...prevCliente,
            [fieldName]: newValue
        }))

        if (fieldName === 'fisJurCliente' && value === 'F') {
            setCliente(prevCliente => ({
                ...prevCliente,
                ieCliente: 'ISENTO'
            }))
        }
    }

    const handleCnpjCpfBlur = async event => {
        const value = event.target.value
        const cnpj = value ? value.replace(/\D/g, '') : ''

        if (cnpj) {
            try {
                //Se der erro é pq não encontrou o cnpj no cadastro de clientes
                const dataCad = await getCnpjCpf(cnpj)

                setClienteJaCadastrado(dataCad)
                return false
            } catch { }
        }

        if (cliente.fisJurCliente === "F") return

        if (!cnpj || cnpj === cnpjInitial) return
        setLoadingApiCnpj(true)

        const dataRFB = await apiGetCnpj(cnpj)

        if (dataRFB) {
            const municipio = await apiMunicipio.getByIbge(dataRFB.codigo_municipio_ibge)

            setCepInitial(dataRFB.cep)
            setCliente(prevCliente => ({
                ...prevCliente,
                nomeCliente: dataRFB.nomeEmpresa,
                nomeFantasiaCliente: dataRFB.nomeFantasia,
                ieCliente: '',
                enderecoCliente: dataRFB.endereco,
                numeroEnderecoCliente: dataRFB.numero,
                complementoCliente: dataRFB.complemento,
                bairroCliente: dataRFB.bairro,
                cepCliente: dataRFB.cep,
                telefone1Cliente: dataRFB.telefone1,
                telefone2Cliente: dataRFB.telefone2,
                emailCliente: dataRFB.email,
                siglaUF: dataRFB.uf,
                idMunicipio: municipio ? municipio.idMunicipio : null
            }))
            setCnpjInitial(cnpj)
        }
        setLoadingApiCnpj(false)
    }

    const handleCepBlur = async event => {
        const value = event.target.value
        const cep = value ? value.replace(/\D/g, '') : ''

        if (!cep || cep === cepInitial) return
        setLoadingApiCep(true)

        const data = await apiGetCep(cep)

        if (data) {
            const municipio = await apiMunicipio.getByUFNome(data.uf, data.cidade)

            setCliente(prevCliente => ({
                ...prevCliente,
                enderecoCliente: data.endereco,
                bairroCliente: data.bairro,
                siglaUF: data.uf,
                idMunicipio: municipio ? municipio.idMunicipio : null
            }))
            setCepInitial(cep)
        }
        setLoadingApiCep(false)
    }

    const handleGetMunicipioData = params => cliente.siglaUF ? apiMunicipio.getByUF(cliente.siglaUF, params) : null

    const handleClickLocalizacao = async () => {
        try {
            const params = {
                cnpjCpf: cliente.cnpjCpfCliente,
                tipoCliFor: 'C'
            }

            const data = await getCoordenadas(params)

            setDadosMapa({ ...data, ...params })
            setOpenMapa(true)
        } catch (error) {
            showError(error)
        }
    }

    const ExtraButtonDataModal = () => {
        if (cliente.idCliente && !deleteMode) {
            return <Button appearance="ghost" color="blue" startIcon={<IoLocationOutline />} onClick={handleClickLocalizacao} >
                Localização
            </Button>
        }
        return (<></>)
    }

    const ExtraButtonDataTable = () => {
        return (
            <RadioGroup
                inline
                appearance="picker"
                defaultValue={dataTableParams?.exibir || 'T'}
                onChange={value => setDataTableParams(prevParams => ({ ...prevParams, exibir: value }))}
            >
                <Text style={{ paddingLeft: 10, alignContent: 'center' }}>Exibir: </Text>
                <Radio value="T">Todos</Radio>
                <Radio value="A">Só Ativos</Radio>
                <Radio value="N">Só Inativos</Radio>
            </RadioGroup>
        )
    }

    const { StringType, NumberType, MixedType } = Schema.Types;

    const model = Schema.Model({
        nomeCliente: StringType()
            .isRequired('Nome é obrigatório')
            .minLength(3, 'Nome deve conter 3 ou mais letras'),
        cnpjCpfCliente: MixedType()
            .isRequired(`${cliente.fisJurCliente === 'F' ? 'CPF' : 'CNPJ'} é obrigatório`)
            .addRule((value, data) => {
                if (data.fisJurCliente === 'F') return value.length === 11
                if (data.fisJurCliente === 'J') return value.length === 14
                return true
            }, `Informe o ${cliente.fisJurCliente === 'F' ? 'CPF' : 'CNPJ'} completo`)
            .addRule((value, data) => {
                if (data.fisJurCliente === 'F') return isCPF(value)
                if (data.fisJurCliente === 'J') return isCNPJ(value)
                return true
            }, `Informe um ${cliente.fisJurCliente === 'F' ? 'CPF' : 'CNPJ'} válido`),
        ieCliente: StringType().isRequired('I.E. é obrigatória'),
        enderecoCliente: StringType().isRequired('Endereço é obrigatório'),
        numeroEnderecoCliente: StringType().isRequired('Número é obrigatório'),
        bairroCliente: StringType().isRequired('Bairro é obrigatório'),
        cepCliente: StringType()
            .isRequired('CEP é obrigatório')
            .minLength(8, 'Informe o CEP completo com 8 dígitos'),
        siglaUF: StringType().isRequired('UF é obrigatória'),
        idMunicipio: NumberType().isRequired('Município é obrigatório'),
        telefone1Cliente: StringType()
            .isRequired('Telefone é obrigatório')
            .minLength(10, 'Telefone deve estar completo com DDD'),
        telefone2Cliente: StringType()
            .minLength(10, 'Telefone deve estar completo com DDD'),
        emailCliente: StringType().isEmail('Informe um endereço de e-mail válido'),
        idRamoAtividade: NumberType().isRequired('Ramo de Atividade é obrigatório')
    });

    return (
        <DataContainer title={`${consulta ? "Consulta" : "Cadastro"} de Clientes`}>

            <DataTable
                data={clientes}
                dataTableColumns={dataTableColumns}
                placeholderSearch='todas as colunas'
                textNewButton='Novo Cliente'
                hiddeNewButton={consulta}
                onClickNewButton={handleClickNew}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(prevParams => ({ ...prevParams, ...params }))}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
                ExtraButtons={<ExtraButtonDataTable />}
            />

            <DataModal
                className={'cliente-cadastro'}
                title={`${consulta ? "Consulta" : "Cadastro"} de Cliente`}
                titleIdValue={cliente.idCliente}
                deleteMode={deleteMode}
                open={openEditModal}
                waiting={waiting}
                hideSubmitButton={consulta}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancel}
                customTextCancelButton={consulta ? "Fechar" : "Cancelar"}
                formValidation={model}
                formValue={cliente}
                formReadOnly={consulta}
                showLoader={loadingApiCnpj || loadingApiCep}
                messageLoader={
                    loadingApiCnpj ? 'Buscando dados da empresa pelo CNPJ'
                        : loadingApiCep ? 'Buscando endereço pelo CEP'
                            : undefined
                }
                ExtraButtonsLeft={<ExtraButtonDataModal />}
            >
                <Grid fluid>
                    <Row>
                        <Col
                            xl={permissionLiberaAcessoCliente ? 3 : 4}
                            md={4}
                            xs={permissionLiberaAcessoCliente ? 8 : 24}
                            style={{ marginBottom: '0px !important' }}
                        >
                            <Form.Group controlId="ativo">
                                <Form.ControlLabel>Cliente Ativo</Form.ControlLabel>
                                <Form.Control
                                    name="ativo"
                                    accepter={Toggle}
                                    readOnly={deleteMode || consulta || !cliente.idCliente}
                                    size='lg'
                                    checkedChildren="Sim"
                                    unCheckedChildren="Não"
                                    onChange={handleInputChange}
                                    className="toggle-red-green"
                                />
                            </Form.Group>
                        </Col>
                        {permissionLiberaAcessoCliente &&
                            <Col
                                xxl={6}
                                xl={6}
                                md={20}
                                xs={16}
                            >
                                <Form.Group controlId="acessoSistema">
                                    <Form.ControlLabel>Permitir acesso ao Sistema <Help message={`A senha padrão são os 5 primeiros dígitos do ${cliente.fisJurCliente === 'J' ? 'CNPJ' : 'CPF'}`} /></Form.ControlLabel>
                                    <Form.Control
                                        name="acessoSistema"
                                        accepter={AcessaSistema}
                                        readOnly={deleteMode}
                                        size='lg'
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                        checked={cliente.acessoSistema}
                                        onChange={handleInputChange}
                                        showButtonResetPassword={cliente.idCliente && cliente.acessoSistema && !consulta && !deleteMode}
                                        onClickButtonResetPassword={() => setOpenConfirmReset(true)}
                                    />
                                </Form.Group>
                            </Col>
                        }
                        <Col
                            xxl={7}
                            xl={permissionLiberaAcessoCliente ? 6 : 8}
                            lg={permissionLiberaAcessoCliente ? 10 : 8}
                            md={8}
                            sm={9}
                            xs={24}
                            className="align-bottom"
                        >
                            <Form.Group controlId="fisJurCliente">
                                <Form.ControlLabel>Tipo de pessoa</Form.ControlLabel>
                                <RadioGroup
                                    name="fisJurCliente"
                                    inline
                                    appearance="picker"
                                    defaultValue="J"
                                    value={cliente.fisJurCliente}
                                    onChange={handleInputChange}>
                                    <Radio
                                        value="J"
                                        disabled={cliente.idCliente && cliente.fisJurCliente === 'F'}
                                    >
                                        <Stack spacing={7} direction="row">
                                            <Icon as={PiFactory} size="1.5em" />
                                            Jurídica
                                        </Stack>
                                    </Radio>
                                    <Radio
                                        value="F"
                                        disabled={cliente.idCliente && cliente.fisJurCliente === 'J'}>
                                        <Stack spacing={7} direction="row">
                                            <Icon as={MdEmojiPeople} size="1.5em" />
                                            Física
                                        </Stack>
                                    </Radio>
                                </RadioGroup>
                            </Form.Group>
                        </Col>
                        <Col
                            xxl={4}
                            xl={permissionLiberaAcessoCliente ? 5 : 6}
                            lg={permissionLiberaAcessoCliente ? 7 : 6}
                            md={permissionLiberaAcessoCliente ? 8 : 6}
                            sm={8}
                            xs={24}
                        >
                            <Form.Group controlId="cnpjCpfCliente">
                                <Form.ControlLabel>{cliente.fisJurCliente === 'J' ? 'CNPJ' : 'CPF'}</Form.ControlLabel>
                                <Form.Control
                                    inputRef={cnpjCpfRef}
                                    accepter={CustomInputCnpjCpf}
                                    tipoFisJur={cliente.fisJurCliente}
                                    name="cnpjCpfCliente"
                                    readOnly={cliente.idCliente}
                                    autoFocus={!deleteMode && !consulta && !cliente.idCliente}
                                    onChange={handleInputChange}
                                    onBlur={handleCnpjCpfBlur}
                                />
                            </Form.Group>
                        </Col>
                        <Col
                            xxl={4}
                            xl={permissionLiberaAcessoCliente ? 4 : 6}
                            lg={permissionLiberaAcessoCliente ? 7 : 6}
                            md={permissionLiberaAcessoCliente ? 8 : 6}
                            sm={7}
                            xs={24}
                        >
                            <Form.Group controlId="ieCliente">
                                <Form.ControlLabel>Inscrição Estadual</Form.ControlLabel>
                                <Form.Control
                                    name="ieCliente"
                                    maxLength={20}
                                    readOnly={deleteMode || consulta || cliente.fisJurCliente === 'F'}
                                    autoFocus={!deleteMode && !consulta && cliente.idCliente && cliente.fisJurCliente === 'J'}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={17} md={18} sm={24} xs={24}>
                            <Form.Group controlId="nomeCliente">
                                <Form.ControlLabel>Nome do Cliente</Form.ControlLabel>
                                <Form.Control
                                    name="nomeCliente"
                                    autoFocus={!deleteMode && !consulta && cliente.idCliente && cliente.fisJurCliente === 'F'}
                                    onChange={handleInputChange}
                                    checkTrigger={'blur'}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={7} md={6} sm={24} xs={24}>
                            <Form.Group controlId="nomeFantasiaCliente">
                                <Form.ControlLabel>Nome Fantasia</Form.ControlLabel>
                                <Form.Control
                                    name="nomeFantasiaCliente"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={3} md={4} sm={5} xs={24}>
                            <Form.Group controlId="cepCliente">
                                <Form.ControlLabel>CEP</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputCep}
                                    name="cepCliente"
                                    onChange={handleInputChange}
                                    onBlur={handleCepBlur}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={12} md={16} sm={14} xs={24}>
                            <Form.Group controlId="enderecoCliente">
                                <Form.ControlLabel>Endereço</Form.ControlLabel>
                                <Form.Control
                                    name="enderecoCliente"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={3} md={4} sm={5} xs={24}>
                            <Form.Group controlId="numeroEnderecoCliente">
                                <Form.ControlLabel>
                                    Número
                                    <Help size="xs" message="Informe o número ou SN se não houver." />
                                </Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputNumeroEndereco}
                                    name="numeroEnderecoCliente"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} md={12} sm={12} xs={24}>
                            <Form.Group controlId="complementoCliente">
                                <Form.ControlLabel>Complemento</Form.ControlLabel>
                                <Form.Control
                                    name="complementoCliente"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={8} md={12} sm={12} xs={24}>
                            <Form.Group controlId="bairroCliente">
                                <Form.ControlLabel>Bairro</Form.ControlLabel>
                                <Form.Control
                                    name="bairroCliente"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={8} md={12} sm={24} xs={24}>
                            <Form.Group controlId="siglaUF" ref={formGroupRefUF}>
                                <Form.ControlLabel>UF</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefUF}
                                    name="siglaUF"
                                    placeholder="Selecione a UF"
                                    onChange={value => setCliente(prevCliente => ({ ...prevCliente, siglaUF: value, idMunicipio: '' }))}
                                    apiGetData={apiUF.getAll}
                                    apiGetInitialData={apiUF.getId}
                                    apiGetDataOrder={{ column: 'nomeUF' }}
                                    getDataObject={item => ({ value: item.siglaUF, label: `${item.siglaUF} - ${item.nomeUF}` })}
                                    reloadDataTriggerEffect={[cnpjInitial, cepInitial]}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={8} md={12} sm={24} xs={24}>
                            <Form.Group controlId="idMunicipio" ref={formGroupRefMunicipio}>
                                <Form.ControlLabel>Município</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefMunicipio}
                                    name="idMunicipio"
                                    placeholder={`Selecione o Município${cliente.siglaUF && cliente.siglaUF !== '' ? '' : ' após selecionar a UF'}`}
                                    onChange={value => setCliente(prevCliente => ({ ...prevCliente, idMunicipio: value }))}
                                    apiGetData={handleGetMunicipioData}
                                    apiGetInitialData={apiMunicipio.getId}
                                    apiGetDataOrder={{ column: 'nomeMunicipio' }}
                                    getDataObject={item => ({ value: item.idMunicipio, label: item.nomeMunicipio })}
                                    reloadDataTriggerEffect={[cliente.siglaUF, cnpjInitial, cepInitial]}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={4} md={7} sm={12} xs={24}>
                            <Form.Group controlId="telefone1Cliente">
                                <Form.ControlLabel>Telefone 1</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputTelefone}
                                    name="telefone1Cliente"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={4} md={7} sm={12} xs={24}>
                            <Form.Group controlId="telefone2Cliente">
                                <Form.ControlLabel>Telefone 2</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputTelefone}
                                    name="telefone2Cliente"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={16} md={10} sm={24} xs={24}>
                            <Form.Group controlId="emailCliente">
                                <Form.ControlLabel>e-mail</Form.ControlLabel>
                                <Form.Control
                                    name="emailCliente"
                                    type="email"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Group controlId="idVendedor" ref={formGroupRefVendeodr}>
                                <Form.ControlLabel>Vendedor</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefVendeodr}
                                    name="idVendedor"
                                    placeholder="Selecione o Vendedor"
                                    onChange={value => setCliente(prevCliente => ({ ...prevCliente, idVendedor: value }))}
                                    apiGetData={apiVendedor.getAll}
                                    apiGetInitialData={apiVendedor.getId}
                                    apiGetDataOrder={{ column: 'nomeVendedor' }}
                                    getDataObject={item => ({ value: item.idVendedor, label: item.nomeVendedor })}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <Form.Group controlId="idRamoAtividade" ref={formGroupRefRamoAtividade}>
                                <Form.ControlLabel>Ramo de Atividade</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefRamoAtividade}
                                    name="idRamoAtividade"
                                    placeholder="Selecione o Ramo de Atividade"
                                    onChange={value => setCliente(prevCliente => ({ ...prevCliente, idRamoAtividade: value }))}
                                    apiGetData={apiRamoAtividade.getAll}
                                    apiGetInitialData={apiRamoAtividade.getId}
                                    apiGetDataOrder={{ column: 'nomeRamoAtividade' }}
                                    getDataObject={item => ({ value: item.idRamoAtividade, label: item.nomeRamoAtividade })}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={24}>
                            <Form.Group controlId="areaTotalM2Cliente">
                                <Form.ControlLabel>Área Total Aproximada</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputNumero}
                                    name="areaTotalM2Cliente"
                                    onChange={handleInputChange}
                                    maxLength={14}
                                    placeholder={'m²'}
                                    sufixo={'m²'}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={24}>
                            <Form.Group controlId="qtdePontoPermanenteEnvenenamento">
                                <Form.ControlLabel>Pontos Perm. Envenenamento</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputNumero}
                                    name="qtdePontoPermanenteEnvenenamento"
                                    onChange={handleInputChange}
                                    maxLength={11}
                                    placeholder={'Qtde'}
                                    prefixo={'Qtde'}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={24}>
                            <Form.Group controlId="qtdePontoPermanenteCaptura">
                                <Form.ControlLabel>Pontos Perm. Captura</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputNumero}
                                    name="qtdePontoPermanenteCaptura"
                                    onChange={handleInputChange}
                                    maxLength={11}
                                    placeholder={'Qtde'}
                                    prefixo={'Qtde'}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={24}>
                            <Form.Group controlId="qtdeIscaLuminosa">
                                <Form.ControlLabel>Iscas Luminosas</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputNumero}
                                    name="qtdeIscaLuminosa"
                                    onChange={handleInputChange}
                                    maxLength={11}
                                    placeholder={'Qtde'}
                                    prefixo={'Qtde'}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="obsCliente">
                                <Form.ControlLabel>Observação</Form.ControlLabel>
                                <Form.Control
                                    name="obsCliente"
                                    accepter={CustomTextArea}
                                    rows={2}
                                    maxLength={500}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <div className="rs-form-control">
                                <div className="rs-form-control-label">
                                    <Stack direction="row" spacing={15}>
                                        <span>Contatos</span>
                                        {!deleteMode && !consulta &&
                                            <Button color="cyan" appearance="ghost" size='xs' startIcon={<PlusIcon />} onClick={handleClickNewContato}>
                                                Novo Contato
                                            </Button>
                                        }
                                    </Stack>
                                </div>
                                <ContatoList
                                    consulta={consulta}
                                    hideButtons={deleteMode}
                                    onEditButtonClick={handleClickEditContato}
                                    onDeleteButtonClick={handleClickDeleteContato}
                                    contatos={!cliente.ClienteContatos
                                        ? null
                                        : cliente.ClienteContatos.map(item => ({
                                            nomeContato: item.nomeClienteContato,
                                            telefoneContato: item.telefoneClienteContato,
                                            emailContato: item.emailClienteContato,
                                            obsContato: item.obsClienteContato
                                        }))
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </Grid>
                <ContatoCadastro
                    deleteMode={deleteModeContato}
                    consulta={consulta}
                    open={openContato}
                    defaultValue={clienteContato}
                    onClose={() => setOpenContato(false)}
                    onConfirm={handleClickConfirmContato}
                />
            </DataModal>
            {!consulta &&
                <Confirm
                    open={openConfirm}
                    message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados do Cliente?`}
                    onClickNoButton={() => setOpenConfirm(false)}
                    onClickYesButton={handleClickYesConfirm}
                />
            }
            {!consulta && !deleteMode &&
                <Alert
                    open={clienteJaCadastrado !== null && clienteJaCadastrado !== undefined}
                    message={`Cliente já cadastrado com o ${cliente.fisJurCliente === 'F' ? "CPF" : "CNPJ"} ${cnpjCpfRef.current ? cnpjCpfRef.current.value : "informado"}.\n\nClique em OK para carregar os dados.`}
                    onClickButton={handleClickLoadData}
                />
            }
            {!consulta && !deleteMode && permissionLiberaAcessoCliente &&
                <Confirm
                    open={openConfirmReset}
                    message={`Confirma o reset da senha do Cliente?`}
                    onClickNoButton={() => setOpenConfirmReset(false)}
                    onClickYesButton={handleClickYesReset}
                />
            }
            {!deleteMode &&
                <Mapa
                    open={openMapa}
                    data={dadosMapa}
                    title={'Localização do Cliente'}
                    onClose={() => setOpenMapa(false)}
                />
            }
        </DataContainer >
    )
}

export default Cliente