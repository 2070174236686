import { useEffect, useRef, useState } from "react";
import { Col, Form, Grid, Row, Schema, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import CustomSelectPicker from "../../components/CustomSelectPicker";
import CustomTextArea from "../../components/CustomTextArea";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/Message";

import useApiServico from "../../hooks/useApiServico";
import useApiTipoServico from "../../hooks/useApiTipoServico";
import useApiUnidadeMedida from "../../hooks/useApiUnidadeMedida";
import useScreenSizeComparison from "../../hooks/useScreenSizeComparison";

const Servico = ({ consulta }) => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const { save, destroy, getAll, getId } = useApiServico()
    const apiTipoServico = useApiTipoServico()
    const apiUnidadeMedida = useApiUnidadeMedida()

    const formGroupRef = useRef()

    const [servicos, setServicos] = useState({})
    const [servico, setServico] = useState({})

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [editMode, setEditMode] = useState("V")

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${editMode === "D" ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    const getServicos = async () => {
        if (!dataTableParams) return setServicos({})
        setLoading(true)

        try {
            const data = await getAll({ ...dataTableParams })
            setServicos(data)
        } catch (error) {
            setServicos({})
            showError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        (async () => await getServicos())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (id, modoEdicao) => {
        try {
            const data = await getId(id)
            setServico(data)
            setEditMode(modoEdicao)
            setOpenEditModal(true)
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNew = () => {
        setServico({
            idServico: null,
            nomeServico: '',
            nomeReduzidoServico: '',
            descricaoServico: '',
            idTipoServico: '',
            idUnidadeMedida: null
        });
        setEditMode("I");
        setOpenEditModal(true);
    }
    const handleClickEdit = id => editData(id, "U")
    const handleClickDelete = id => editData(id, "D")
    const handleClickView = id => editData(id, "V")

    const handleClickCancel = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }

    const handleClickYes = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = editMode === "D" ? await destroy(servico.idServico) : await save(servico)

            showSucess()
            handleClickCancel()
            await getServicos()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idServico' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const hiddenCol_xl = isScreenSizeBelow('xl')
    const hiddenCol_lg = isScreenSizeBelow('lg')
    const hiddenCol_sm = isScreenSizeBelow('sm')

    const dataTableColumns = {
        displayButtonColumn: {
            dataKey: 'idServico',
            onClick: handleClickView,
            hidden: rowData => !consulta && !rowData.servicoPadrao
        },
        editButtonColumn: {
            dataKey: 'idServico',
            onClick: consulta ? handleClickView : handleClickEdit,
            hidden: rowData => consulta || rowData.servicoPadrao
        },
        deleteButtonColumn: {
            dataKey: 'idServico',
            onClick: consulta ? handleClickView : handleClickDelete,
            hidden: rowData => consulta || rowData.servicoPadrao
        },
        columns: [
            {
                dataKey: 'idServico',
                headerCell: 'Id',
                align: 'center',
                width: 100,
                sortable: true,
                hidden: hiddenCol_sm
            }, {
                dataKey: 'nomeServico',
                headerCell: 'Nome',
                sortable: true,
                fullText: true,
                flexGrow: 3
            }, {
                dataKey: 'nomeReduzidoServico',
                headerCell: 'Nome Reduzido',
                sortable: true,
                width: 150,
                hidden: hiddenCol_lg
            }, {
                dataKey: 'descricaoServico',
                headerCell: 'Descrição',
                sortable: true,
                fullText: true,
                flexGrow: 3,
                hidden: hiddenCol_xl
            }, {
                dataKey: 'nomeTipoServico',
                headerCell: 'Tipo de Servico',
                sortable: true,
                fullText: true,
                flexGrow: 2,
                minwidth: 200,
                hidden: hiddenCol_sm,
                customCellContent: rowData => rowData.TipoServico && rowData.TipoServico.nomeTipoServico
            }, {
                dataKey: 'siglaUnidadeMedida',
                headerCell: 'U.M.',
                sortable: true,
                width: 80,
                align: 'center',
                hidden: hiddenCol_sm,
                customCellContent: rowData => rowData.UnidadeMedida && rowData.UnidadeMedida.siglaUnidadeMedida
            }
        ]
    }

    const { StringType, NumberType } = Schema.Types;

    const model = Schema.Model({
        nomeServico: StringType().isRequired('Nome é obrigatório'),
        nomeReduzidoServico: StringType().isRequired('Nome Reduzido é obrigatório'),
        descricaoServico: StringType().isRequired('Descrição é obrigatória'),
        idTipoServico: NumberType().isRequired('Tipo de Servico é obrigatório')
    });

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget ? event.currentTarget.name : event

        const newValue = value[fieldName]

        setServico(prevServico => ({
            ...prevServico,
            [fieldName]: newValue
        }))
    }

    return (
        <DataContainer title={`${consulta ? "Consulta" : "Cadastro"} de Serviços`}>
            <DataTable
                data={servicos}
                dataTableColumns={dataTableColumns}
                placeholderSearch='todas as colunas'
                textNewButton='Novo Serviço'
                onClickNewButton={handleClickNew}
                hiddeNewButton={consulta}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
            />

            <DataModal
                title={`${consulta ? "Consulta" : "Cadastro"} de Serviço`}
                titleIdValue={servico.idServico}
                deleteMode={editMode === "D"}
                open={openEditModal}
                sizeModal='md'
                waiting={waiting}
                onClickSubmitButton={() => setOpenConfirm(true)}
                hideSubmitButton={editMode === "V"}
                onClickCancelButton={handleClickCancel}
                customTextCancelButton={editMode === "V" ? "Fechar" : "Cancelar"}
                formValidation={model}
                formValue={servico}
                formReadOnly={editMode === "V"}
                formOnChange={handleInputChange}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="nomeServico">
                                <Form.ControlLabel>Nome do Serviço</Form.ControlLabel>
                                <Form.Control
                                    name="nomeServico"
                                    autoFocus={editMode === "I" || editMode === "U"}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="nomeReduzidoServico">
                                <Form.ControlLabel>Nome Reduzido do Serviço</Form.ControlLabel>
                                <Form.Control
                                    name="nomeReduzidoServico"
                                    maxLength={15}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="descricaoServico">
                                <Form.ControlLabel>Descrição do Serviço</Form.ControlLabel>
                                <Form.Control
                                    name="descricaoServico"
                                    accepter={CustomTextArea}
                                    rows={5}
                                    maxLength={1000}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="idTipoServico" ref={formGroupRef}>
                                <Form.ControlLabel>Tipo de Servico</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRef}
                                    name="idTipoServico"
                                    placeholder="Selecione o Tipo de Servico"
                                    onChange={value => setServico(prevServico => ({ ...prevServico, idTipoServico: value }))}
                                    apiGetData={apiTipoServico.getAll}
                                    apiGetInitialData={apiTipoServico.getId}
                                    getDataObject={item => ({ value: item.idTipoServico, label: item.nomeTipoServico })}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="idUnidadeMedida" ref={formGroupRef}>
                                <Form.ControlLabel>Unidade de Medida</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRef}
                                    name="idUnidadeMedida"
                                    placeholder="Selecione a Unidade de Medida"
                                    onChange={value => setServico(prevServico => ({ ...prevServico, idUnidadeMedida: value }))}
                                    apiGetData={apiUnidadeMedida.getAll}
                                    apiGetInitialData={apiUnidadeMedida.getId}
                                    getDataObject={item => ({ value: item.idUnidadeMedida, label: `${item.siglaUnidadeMedida} - ${item.nomeUnidadeMedida}` })}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataModal>
            {editMode !== "V" &&
                <Confirm
                    open={openConfirm}
                    message={`Confirma a ${editMode === "D" ? 'exclusão' : 'gravação'} dos dados do Serviço?`}
                    onClickNoButton={() => setOpenConfirm(false)}
                    onClickYesButton={handleClickYes}
                />
            }
        </DataContainer >
    )
}

export default Servico