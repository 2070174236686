import { MaskedInput } from "rsuite";

const CustomInputTelefone = ({ onChange, ...rest }) => {

    const handleInputChange = (value, event) => {
        const input = value.replace(/\D/g, '');
        onChange(input, event);
    };

    const telefoneMask = (rawValue) => {
        const digitCount = rawValue.replace(/\D/g, '').length;
        return digitCount > 10
            ? ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
            : ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    };

    return <MaskedInput
        mask={telefoneMask}
        onChange={handleInputChange}
        {...rest} />;
};

export default CustomInputTelefone