import CalendarIcon from '@rsuite/icons/Calendar';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Button, Calendar, InputGroup, List, MaskedInput, Popover, Text, Whisper } from "rsuite";

import { dateFromString, dateTimeFromString, dateTimeToString, dateToString } from '../../services/utils';

import useApiFeriado from '../../hooks/useApiFeriado';
import './style.css';

const SelectTimeList = ({
    height,
    titulo,
    arrayMap,
    selectedInitial,
    onChange }) => {
    const listItemRef = useRef([])

    const [selected, setSelected] = useState(selectedInitial)

    useEffect(() => {
        onChange(selected)

        if (listItemRef.current[selected] && selected === selectedInitial) {
            listItemRef.current[selected].scrollIntoView({ behavior: 'instant', block: 'center', inline: 'nearest' });
        }

        // eslint-disable-next-line
    }, [selected])

    return (
        <div className='custom-input-date-select-time-list-container'>
            <Text weight='bold' className='custom-input-date-select-time-list-title'>{titulo}</Text>
            <List autoScroll hover style={{ height: height }}>
                {arrayMap.map(i => (
                    <List.Item
                        ref={(el) => (listItemRef.current[i] = el)}
                        key={i}
                        className={`custom-input-date-select-time-list-item ${i === selected ? 'selected' : ''}`}
                        onClick={() => setSelected(i)}
                    >
                        {(`0${i}`).slice(-2)}
                    </List.Item>
                ))}
            </List>
        </div>
    )
}

const SelectTime = ({
    height,
    timeValue,
    setTimeValue
}) => {
    const horas = Array.from({ length: 24 }, (_, i) => i);
    const minutos = Array.from({ length: 60 }, (_, i) => i);

    const heightContainer = height - 12
    const heightList = heightContainer - 40

    const horaSplit = timeValue?.split(':')

    const hora = horaSplit && horaSplit.length > 1 ? parseInt(horaSplit[0]) : ''
    const minuto = horaSplit && horaSplit.length > 1 ? parseInt(horaSplit[1]) : ''

    const handleChangeHora = value => {
        const auxHora = (`0${value}`).slice(-2)
        const auxMinuto = (`0${minuto}`).slice(-2)
        const newTimeValue = `${auxHora}:${auxMinuto}`
        setTimeValue(newTimeValue)
    }

    const handleChangeMinuto = value => {
        const auxMinuto = (`0${value}`).slice(-2)
        const auxHora = (`0${hora}`).slice(-2)
        const newTimeValue = `${auxHora}:${auxMinuto}`
        setTimeValue(newTimeValue)
    }

    return (
        <div className='custom-input-date-select-time-backdrop' style={{ height: heightContainer }}>
            <div className='custom-input-date-select-time-container' style={{ height: heightContainer }}>
                <SelectTimeList
                    height={heightList}
                    titulo={'Horas'}
                    arrayMap={horas}
                    selectedInitial={hora}
                    onChange={handleChangeHora}
                />
                <SelectTimeList
                    height={heightList}
                    titulo={'Minutos'}
                    arrayMap={minutos}
                    selectedInitial={minuto}
                    onChange={handleChangeMinuto}
                />
            </div>
        </div>
    )
}

const PopupCalendar = ({
    onClickOk,
    inputDate,
    isDateTime
}) => {
    const { getYearMonth } = useApiFeriado()

    const whisperRef = useRef()
    const calendarRef = useRef()

    const [dateValue, setDateValue] = useState()
    const [timeValue, setTimeValue] = useState()
    const [showTime, setShowTime] = useState(false)

    const [mes, setMes] = useState()
    const [ano, setAno] = useState()

    const [diasFeriado, setDiasFeriado] = useState()

    useEffect(() => {
        const data = dateFromString(inputDate) || new Date()
        setDateValue(data)

        setMes(data.getMonth() + 1)
        setAno(data.getFullYear())

        if (isDateTime) {
            const dataHora = dateTimeFromString(inputDate) || new Date()
            const hora = dataHora.toLocaleTimeString('pt-br').substring(0, 5)
            setTimeValue(hora)
        }

        // eslint-disable-next-line
    }, [inputDate])

    useEffect(() => {
        if (!ano || !mes) return

        (async () => {
            try {
                const response = await getYearMonth(ano, mes)
                setDiasFeriado(response.data)
            } catch { }
        })()

        // eslint-disable-next-line
    }, [mes, ano])

    const findFeriado = date => {
        if (!diasFeriado) return null
        return (
            diasFeriado.find(c => {
                const auxData = dateFromString(c.dataFeriado)
                return auxData.getDate() === date.getDate()
            })
        )
    }

    const renderCell = date => {
        const feriado = findFeriado(date)

        if (feriado) {
            const dia = date.getDate().toString().padStart(2, '0')
            const mes = date.toLocaleString('pt-BR', { month: 'short' })
            const ano = date.getFullYear();

            const title = `${dia} ${mes}, ${ano} (${feriado.tipoFeriado !== 'P' ? 'Feriado ' : ''}${feriado.descricaoTipoFeriado})\n${feriado.nomeFeriado}\n${feriado.obsFeriado || ''}`

            return <span className="rs-calendar-table-cell-day" title={title}>{parseInt(dia)}</span>;
        }

        return null;
    }

    return (
        <Whisper
            ref={whisperRef}
            trigger="click"
            placement="autoVerticalEnd"
            controlId="control-id-custom-input-date-calendar"
            onClose={() => setShowTime(false)}
            speaker={
                <Popover className="custom-input-date-calendar-container">
                    <div className={`custom-input-date-calendar-body `}>
                        <Calendar
                            ref={calendarRef}
                            bordered
                            compact
                            value={dateValue}
                            onChange={setDateValue}
                            onMonthChange={date => {
                                setDiasFeriado(null)
                                setMes(date.getMonth() + 1)
                                setAno(date.getFullYear())
                            }}
                            className="custom-input-date-calendar"
                            cellClassName={date => {
                                if (findFeriado(date)) return 'dia-feriado'
                                if (date.getDay() === 0) return 'dia-domingo'
                                return undefined
                            }}
                            renderCell={renderCell}
                        />
                        {showTime &&
                            <SelectTime
                                height={calendarRef?.current?.clientHeight}
                                timeValue={timeValue}
                                setTimeValue={setTimeValue}
                            />
                        }
                    </div>
                    <div className={`custom-input-date-calendar-footer ${isDateTime ? 'with-time' : ''}`}>
                        {isDateTime &&
                            <Button onClick={() => setShowTime(!showTime)}>
                                {showTime ? dateValue?.toLocaleDateString('pt-BR') : timeValue}
                            </Button>
                        }
                        <Button
                            appearance='ghost'
                            color="cyan"
                            onClick={() => {
                                if (isDateTime) {
                                    const dataString = dateValue.toLocaleDateString('pt-br') + ' às ' + timeValue
                                    const dataReturn = dateTimeFromString(dataString)

                                    onClickOk(dataReturn)
                                } else {
                                    onClickOk(dateValue)
                                }
                                whisperRef.current.close()
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </Popover>
            }
        >
            <CalendarIcon className='custom-input-date-calendar-icon' />
        </Whisper>
    )
}

const CustomInputDate = ({
    onChange,
    block,
    inputRef,
    isDateTime,
    hideCalendar,
    ...rest
}) => {

    const localRef = useRef(null);
    const refToUse = inputRef || localRef;

    const handleInputChange = (value, event) => {
        const minLength = isDateTime ? 12 : 8
        const input = value.replace(/\D/g, '')

        //retona o texto se a data não estiver completa
        if (input.length < minLength) return onChange(input, event)

        const data = isDateTime ? dateTimeFromString(value) : dateFromString(value)
        if (!data) return onChange(input, event)

        onChange(data, event)
    }

    const handleClickOk = date => {
        const dataFormatada = isDateTime
            ? dateTimeToString(date)
            : dateToString(date)

        refToUse.current.value = dataFormatada

        // Criar um evento simulado completo
        const eventoSimulado = new Event('change', { bubbles: true })

        Object.defineProperty(eventoSimulado, 'target', {
            writable: true,
            value: refToUse.current
        })

        Object.defineProperty(eventoSimulado, 'currentTarget', {
            writable: true,
            value: refToUse.current
        });

        onChange(date, eventoSimulado)
    }

    const mask = isDateTime
        ? [/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', 'à', 's', ' ', /[0-2]/, /\d/, ':', /[0-5]/, /\d/]
        : [/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

    // Conando a propriedade rest para modificar o valor se necesário
    const modifiedProps = { ...rest };

    if (modifiedProps.value instanceof Date) {
        const dataFormatada = isDateTime
            ? dateTimeToString(modifiedProps.value)
            : dateToString(modifiedProps.value)

        modifiedProps.value = dataFormatada
    } else {
        if (!isDateTime) {
            const auxData = dateFromString(modifiedProps.value)
            if (auxData) {
                const dataFormatada = dateToString(auxData)
                modifiedProps.value = dataFormatada
            }
        }
    }


    return (
        <div className={`custom-input-date-container ${block ? 'block' : null}`}>
            <InputGroup inside>
                <MaskedInput
                    mask={mask}
                    onChange={handleInputChange}
                    ref={refToUse}
                    {...modifiedProps}
                />
                {!hideCalendar &&
                    <InputGroup.Addon className='custom-input-date-calendar-icon-container'>
                        <PopupCalendar
                            inputDate={modifiedProps.value}
                            onClickOk={handleClickOk}
                            isDateTime={isDateTime}
                        />
                    </InputGroup.Addon>
                }
            </InputGroup>
        </div>
    )
}

CustomInputDate.propTypes = {
    onChange: PropTypes.func.isRequired,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
}

export default CustomInputDate