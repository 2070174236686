import { useLocation } from 'react-router-dom';
import { Breadcrumb, Container } from 'rsuite';

import './style.css';

const DataContainer = ({
    title,
    minHeight,
    children,
    className
}) => {
    const location = useLocation();

    const stateBreadcrumb = location.state?.breadcrumb || title
    const breadcrumb = stateBreadcrumb ? stateBreadcrumb.split('|') : []

    return (
        <>
            <Container className='data-container' style={minHeight ? { minHeight: minHeight } : null}>
                <div className='data-container-header'>
                    <div className='data-container-title'>
                        {title}
                    </div>
                    {
                        <Breadcrumb className='data-container-breadcrumb'>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            {
                                breadcrumb.map((element, index) => (
                                    <Breadcrumb.Item active={index === (breadcrumb.length - 1)} key={index}>{element}</Breadcrumb.Item>
                                ))
                            }
                        </Breadcrumb>
                    }
                </div>
                {children}
            </Container>
        </>
    )
}


export default DataContainer
