import { useEffect, useRef, useState } from "react";
import { Col, Form, Grid, Row, Schema, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import CustomSelectPicker from "../../components/CustomSelectPicker";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/Message";

import useApiGrupoQuimico from "../../hooks/useApiGrupoQuimico";
import useApiLaboratorio from "../../hooks/useApiLaboratorio";
import useApiProduto from "../../hooks/useApiProduto";
import useApiTipoAplicacao from "../../hooks/useApiTipoAplicacao";
import useApiUnidadeMedida from "../../hooks/useApiUnidadeMedida";
import useScreenSizeComparison from "../../hooks/useScreenSizeComparison";

const Produto = ({ consulta }) => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const { save, destroy, getAll, getId } = useApiProduto()
    const apiTipoApicacao = useApiTipoAplicacao()
    const apiGrupoQuimico = useApiGrupoQuimico()
    const apiLaboratorio = useApiLaboratorio()
    const apiUnidadeMedida = useApiUnidadeMedida()

    const formGroupRefUnidMed = useRef()
    const formGroupRefGrupoQuimico = useRef()
    const formGroupRefTipoAplicacao = useRef()
    const formGroupRefLaboratorio = useRef()

    const [produtos, setProdutos] = useState({})
    const [produto, setProduto] = useState({})

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [deleteMode, setDeleteMode] = useState(false)

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    const getProdutos = async () => {
        if (!dataTableParams) return setProdutos({})
        setLoading(true)

        try {
            const data = await getAll({ ...dataTableParams })
            setProdutos(data)
        } catch (error) {
            setProdutos({})
            showError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        (async () => await getProdutos())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (id, isDelete) => {
        try {
            const data = await getId(id)
            setProduto(data)
            setDeleteMode(isDelete)
            setOpenEditModal(true)
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNew = () => {
        setProduto({
            idProduto: null,
            nomeProduto: '',
            numeroRegistroProduto: '',
            concentracaoVeiculoProduto: '',
            antidotoProduto: '',
            acaoToxicaProduto: '',
            idTipoAplicacao: '',
            idLaboratorio: '',
            idGrupoQuimico: '',
            idUnidadeMedida: ''

        });
        setDeleteMode(false);
        setOpenEditModal(true);
    }
    const handleClickEdit = id => editData(id)
    const handleClickDelete = id => editData(id, true)

    const handleClickCancel = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }

    const handleClickYes = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await destroy(produto.idProduto) : await save(produto)

            showSucess()
            handleClickCancel()
            await getProdutos()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idProduto' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const hiddenCol_xl = isScreenSizeBelow('xl')
    const hiddenCol_lg = isScreenSizeBelow('lg')
    const hiddenCol_md = isScreenSizeBelow('md')

    const dataTableColumns = {
        displayButtonColumn: {
            dataKey: 'idProduto',
            onClick: handleClickEdit,
            hidden: () => !consulta
        },
        editButtonColumn: {
            dataKey: 'idProduto',
            onClick: handleClickEdit,
            hidden: () => consulta
        },
        deleteButtonColumn: {
            dataKey: 'idProduto',
            onClick: handleClickDelete,
            hidden: () => consulta
        },
        columns: [
            {
                dataKey: 'idProduto',
                headerCell: 'Id',
                align: 'center',
                width: 100,
                sortable: true,
                hidden: hiddenCol_md
            }, {
                dataKey: 'nomeProduto',
                headerCell: 'Nome',
                sortable: true,
                fullText: true,
                flexGrow: 2
            }, {
                dataKey: 'siglaUnidadeMedida',
                headerCell: 'U.M.',
                sortable: true,
                width: 80,
                align: 'center',
                customCellContent: rowData => rowData.UnidadeMedida && rowData.UnidadeMedida.siglaUnidadeMedida
            }, {
                dataKey: 'numeroRegistroProduto',
                headerCell: 'N.º Registro MS',
                sortable: true,
                width: 150,
                hidden: hiddenCol_md
            }, {
                dataKey: 'nomeGrupoQuimico',
                headerCell: 'Grupo Químico',
                sortable: true,
                fullText: true,
                flexGrow: 1,
                minwidth: 150,
                hidden: hiddenCol_lg,
                customCellContent: rowData => rowData.GrupoQuimico && rowData.GrupoQuimico.nomeGrupoQuimico
            }, {
                dataKey: 'nomeTipoAplicacao',
                headerCell: 'Tipo de Aplicação',
                sortable: true,
                fullText: true,
                flexGrow: 1,
                minwidth: 150,
                hidden: hiddenCol_lg,
                customCellContent: rowData => rowData.TipoAplicacao && rowData.TipoAplicacao.nomeTipoAplicacao
            }, {
                dataKey: 'concentracaoVeiculoProduto',
                headerCell: 'Concetração e Veículo',
                sortable: true,
                fullText: true,
                flexGrow: 1,
                minwidth: 150,
                hidden: hiddenCol_xl
            }, {
                dataKey: 'acaoToxicaProduto',
                headerCell: 'Ação Tóxica',
                sortable: true,
                fullText: true,
                flexGrow: 1,
                minwidth: 150,
                hidden: hiddenCol_xl
            }, {
                dataKey: 'antidotoProduto',
                headerCell: 'Antídoto',
                sortable: true,
                fullText: true,
                flexGrow: 1,
                minwidth: 150,
                hidden: hiddenCol_xl
            }, {
                dataKey: 'nomeLaboratorio',
                headerCell: 'Laboratório',
                sortable: true,
                fullText: true,
                flexGrow: 1,
                minwidth: 150,
                hidden: hiddenCol_lg,
                customCellContent: rowData => rowData.Laboratorio && rowData.Laboratorio.nomeLaboratorio
            }
        ]
    }

    const { StringType, NumberType } = Schema.Types;

    const model = Schema.Model({
        nomeProduto: StringType().isRequired('Nome é obrigatório'),
        antidotoProduto: StringType().isRequired('Antídoto e Tratamento é obrigatório'),
        acaoToxicaProduto: StringType().isRequired('Ação Tóxica é obrigatória'),
        idTipoAplicacao: NumberType().isRequired('Tipo de Aplicação é obrigatório'),
        idLaboratorio: NumberType().isRequired('Laboratório é obrigatório'),
        idGrupoQuimico: NumberType().isRequired('Grupo Químico é obrigatório'),
        idUnidadeMedida: NumberType().isRequired('Unidade de Medida é obrigatória')
    });

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget.name

        setProduto(prevProduto => ({
            ...prevProduto,
            [fieldName]: value
        }))
    }

    return (
        <DataContainer title={`${consulta ? "Consulta" : "Cadastro"} de Produtos`}>
            <DataTable
                data={produtos}
                dataTableColumns={dataTableColumns}
                placeholderSearch='todas as colunas'
                textNewButton='Novo Produto'
                hiddeNewButton={consulta}
                onClickNewButton={handleClickNew}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
            />

            <DataModal
                title={`${consulta ? "Consulta" : "Cadastro"} de Produto`}
                titleIdValue={produto.idProduto}
                deleteMode={deleteMode}
                open={openEditModal}
                sizeModal='md'
                waiting={waiting}
                hideSubmitButton={consulta}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancel}
                customTextCancelButton={consulta ? "Fechar" : "Cancelar"}
                formValidation={model}
                formValue={produto}
                formReadOnly={consulta}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="nomeProduto">
                                <Form.ControlLabel>Nome do Produto</Form.ControlLabel>
                                <Form.Control
                                    name="nomeProduto"
                                    autoFocus={!deleteMode && !consulta}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} xs={24}>
                            <Form.Group controlId="numeroRegistroProduto">
                                <Form.ControlLabel>Nome de Registro do Produto</Form.ControlLabel>
                                <Form.Control
                                    name="numeroRegistroProduto"
                                    maxLength={20}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={12} xs={24}>
                            <Form.Group controlId="idUnidadeMedida" ref={formGroupRefUnidMed}>
                                <Form.ControlLabel>Unidade de Medida</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefUnidMed}
                                    name="idUnidadeMedida"
                                    placeholder="Selecione a Unidade de Medida"
                                    onChange={value => setProduto(prevProduto => ({ ...prevProduto, idUnidadeMedida: value }))}
                                    apiGetData={apiUnidadeMedida.getAll}
                                    apiGetInitialData={apiUnidadeMedida.getId}
                                    getDataObject={item => ({ value: item.idUnidadeMedida, label: `${item.siglaUnidadeMedida} - ${item.nomeUnidadeMedida}` })}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} sm={24}>
                            <Form.Group controlId="idGrupoQuimico" ref={formGroupRefGrupoQuimico}>
                                <Form.ControlLabel>Grupo Químico</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefGrupoQuimico}
                                    name="idGrupoQuimico"
                                    placeholder="Selecione o Grupo Químico"
                                    onChange={value => setProduto(prevProduto => ({ ...prevProduto, idGrupoQuimico: value }))}
                                    apiGetData={apiGrupoQuimico.getAll}
                                    apiGetInitialData={apiGrupoQuimico.getId}
                                    apiGetDataOrder={{ column: 'nomeGrupoQuimico' }}
                                    getDataObject={item => ({ value: item.idGrupoQuimico, label: item.nomeGrupoQuimico })}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12} sm={24}>
                            <Form.Group controlId="idTipoAplicacao" ref={formGroupRefTipoAplicacao}>
                                <Form.ControlLabel>Tipo de Aplicação</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefTipoAplicacao}
                                    name="idTipoAplicacao"
                                    placeholder="Selecione o Tipo de Aplicação"
                                    onChange={value => setProduto(prevProduto => ({ ...prevProduto, idTipoAplicacao: value }))}
                                    apiGetData={apiTipoApicacao.getAll}
                                    apiGetInitialData={apiTipoApicacao.getId}
                                    apiGetDataOrder={{ column: 'nomeTipoAplicacao' }}
                                    getDataObject={item => ({ value: item.idTipoAplicacao, label: item.nomeTipoAplicacao })}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="concentracaoVeiculoProduto">
                                <Form.ControlLabel>Concentração de Usuo e Veículo do Produto</Form.ControlLabel>
                                <Form.Control
                                    name="concentracaoVeiculoProduto"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="acaoToxicaProduto">
                                <Form.ControlLabel>Ação Tóxica do Produto</Form.ControlLabel>
                                <Form.Control
                                    name="acaoToxicaProduto"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="antidotoProduto">
                                <Form.ControlLabel>Antídoto e Tratamento do Produto</Form.ControlLabel>
                                <Form.Control
                                    name="antidotoProduto"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="idLaboratorio" ref={formGroupRefLaboratorio}>
                                <Form.ControlLabel>Laboratório</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefLaboratorio}
                                    name="idLaboratorio"
                                    placeholder="Selecione o Laboratório"
                                    onChange={value => setProduto(prevProduto => ({ ...prevProduto, idLaboratorio: value }))}
                                    apiGetData={apiLaboratorio.getAll}
                                    apiGetInitialData={apiLaboratorio.getId}
                                    apiGetDataOrder={{ column: 'nomeLaboratorio' }}
                                    getDataObject={item => ({ value: item.idLaboratorio, label: item.nomeLaboratorio })}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataModal>
            {!consulta &&
                <Confirm
                    open={openConfirm}
                    message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados do Produto?`}
                    onClickNoButton={() => setOpenConfirm(false)}
                    onClickYesButton={handleClickYes}
                />
            }
        </DataContainer >
    )
}

export default Produto