import ApiService from '../services/api.js';

const useApiLocalizacao = () => {

    const apiService = new ApiService()
    const api = apiService.api

    const resource = '/localizacao'

    const getCoordenadas = async ({ cnpjCpf, tipoCliFor }) => {
        const response = await api.get(`${resource}/coordenadas`, {
            params: {
                cnpjCpf,
                tipoCliFor
            }
        })
        return response.data;
    }

    const getRotas = async cnpjCpf => {
        const response = await api.get(`${resource}/rotas`, {
            params: {
                cnpjCpf: cnpjCpf
            }
        })
        return response.data;
    }

    const saveRotas = async ({ cnpjCpf, jsonRotas }) => {
        const body = {
            cnpjCpf,
            jsonRotas
        }
        return api.put(`${resource}/rotas`, body)
    }

    return {
        getCoordenadas,
        getRotas,
        saveRotas
    }
}

export default useApiLocalizacao
