import { useEffect, useState } from "react";
import { Col, Form, Grid, Row, Schema, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import DataContainer from "../../components/DataContainer";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/Message";

import useApiTipoServico from "../../hooks/useApiTipoServico";
import useScreenSizeComparison from "../../hooks/useScreenSizeComparison";

const TipoServico = () => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const { save, destroy, getAll, getId } = useApiTipoServico();

    const [tiposServicos, setTiposServicos] = useState({})
    const [tipoServico, setTipoServico] = useState({})

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [editMode, setEditMode] = useState("V")

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, type: 'success', message: `${editMode === 'D' ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    const getTiposServicos = async () => {
        if (!dataTableParams) return setTiposServicos({})
        setLoading(true)

        try {
            const data = await getAll({ ...dataTableParams })
            setTiposServicos(data)
        } catch (error) {
            setTiposServicos({});
            showError(error)
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => await getTiposServicos())()
        // eslint-disable-next-line
    }, [dataTableParams])

    const editData = async (id, modoEdicao) => {
        try {
            const data = await getId(id)
            setTipoServico(data)
            setEditMode(modoEdicao)
            setOpenEditModal(true)
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNew = () => {
        setTipoServico({
            idTipoServico: null,
            nomeTipoServico: ''
        });
        setEditMode("I");
        setOpenEditModal(true);
    }
    const handleClickEdit = id => editData(id, "U")
    const handleClickDelete = id => editData(id, "D")
    const handleClickView = id => editData(id, "V")

    const handleClickCancel = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }

    const handleClickYes = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = editMode === "D" ? await destroy(tipoServico.idTipoServico) : await save(tipoServico)

            showSucess()
            handleClickCancel()
            await getTiposServicos()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idTipoServico' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const hiddenCol_sm = isScreenSizeBelow('sm')

    const dataTableColumns = {
        displayButtonColumn: {
            dataKey: 'idTipoServico',
            onClick: handleClickView,
            hidden: rowData => !rowData.tipoServicoPadrao
        },
        editButtonColumn: {
            dataKey: 'idTipoServico',
            onClick: handleClickEdit,
            hidden: rowData => rowData.tipoServicoPadrao
        },
        deleteButtonColumn: {
            dataKey: 'idTipoServico',
            onClick: handleClickDelete,
            hidden: rowData => rowData.tipoServicoPadrao
        },
        columns: [
            {
                dataKey: 'idTipoServico',
                headerCell: 'Id',
                align: 'center',
                width: 100,
                sortable: true,
                hidden: hiddenCol_sm
            }, {
                dataKey: 'nomeTipoServico',
                headerCell: 'Nome',
                sortable: true,
                flexGrow: 1
            }
        ]
    }

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        nomeTipoServico: StringType().isRequired('Nome é obrigatório'),
    });

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget ? event.currentTarget.name : event

        const newValue = value[fieldName]

        setTipoServico(prevTipoServico => ({
            ...prevTipoServico,
            [fieldName]: newValue
        }))
    }

    return (
        <DataContainer title={"Cadastro de Tipos de Serviços"}>
            <DataTable
                data={tiposServicos}
                dataTableColumns={dataTableColumns}
                placeholderSearch='todas as colunas'
                textNewButton='Novo Tipo de Serviço'
                onClickNewButton={handleClickNew}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
                xxl={12}
                xl={15}
                lg={18}
            />

            <DataModal
                title='Cadastro de Tipo de Serviço'
                titleIdValue={tipoServico.idTipoServico}
                deleteMode={editMode === "D"}
                open={openEditModal}
                sizeModal='md'
                waiting={waiting}
                hideSubmitButton={editMode === "V"}
                customTextCancelButton={editMode === "V" ? "Fechar" : "Cancelar"}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancel}
                formValidation={model}
                formValue={tipoServico}
                formReadOnly={editMode === "V"}
                formOnChange={handleInputChange}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24}>
                            <Form.Group controlId="nomeTipoServico">
                                <Form.ControlLabel>Nome do Tipo de Serviço</Form.ControlLabel>
                                <Form.Control
                                    name="nomeTipoServico"
                                    autoFocus={editMode === "I" || editMode === "U"}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataModal>
            {editMode !== "V" &&
                <Confirm
                    open={openConfirm}
                    message={`Confirma a ${editMode === "D" ? 'exclusão' : 'gravação'} dos dados do Tipo de Serviço?`}
                    onClickNoButton={() => setOpenConfirm(false)}
                    onClickYesButton={handleClickYes}
                />
            }
        </DataContainer >
    )
}

export default TipoServico