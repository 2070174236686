import { useContext, useEffect, useState } from "react";
import { Avatar, Button, Col, Form, Grid, InputGroup, Loader, Row, Schema, Stack, Uploader, useToaster } from "rsuite";

import CameraRetroIcon from '@rsuite/icons/legacy/CameraRetro';

import Assinatura from "../../components/Assinatura";
import Confirm from "../../components/Confirm";
import DataModal from "../../components/DataModal";
import { showMessage } from "../../components/Message";

import { AuthContext } from "../../contexts/authContext";

import useApiMeusDados from "../../hooks/useApiMeusDados";

const PerfilUsuario = ({ setOpen }) => {

    const { savePerfil } = useContext(AuthContext)

    const api = useApiMeusDados()

    const [usuario, setUsuario] = useState();

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const data = await api.getMeusDados()
                if (data) {
                    setUsuario({
                        codigoAcessoUsuario: data.codigoAcessoUsuario,
                        emailUsuario: data.emailUsuario,
                        nomeUsuario: data.nomeUsuario,
                        cargoUsuario: data.cargoUsuario,
                        fotoPerfilUsuario: data.fotoPerfilUsuario,
                        assinaturaUsuario: data.assinaturaUsuario
                    })
                }
            } catch (error) {
                showError(error)
            }
            setLoading(false);
        })()
        // eslint-disable-next-line
    }, [])

    const handleClickYesButton = async () => {
        setOpenConfirm(false)
        setWaiting(true)

        try {
            const response = await savePerfil(usuario)
            if (response.status === 200) return showSucess();
            showError(response)
        } catch (error) {
            showError(error)
        }
    }

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, message: `Alteração concluída com sucesso`, executeFirst: () => setWaiting(false), executeLast: () => setOpen(false) })
    const showError = error => showMessage({ toaster, message: error, executeFirst: () => setWaiting(false) })

    const previewFile = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget.name
        const fieldValue = ['emailUsuario'].includes(fieldName) ? value.toLowerCase() : value

        setUsuario(prevUsuario => ({
            ...prevUsuario,
            [fieldName]: fieldValue
        }));
    }

    const { StringType } = Schema.Types

    const model = Schema.Model({
        nomeUsuario: StringType().isRequired('Nome é obrigatório'),
        emailUsuario: StringType()
            .isRequired('E-mail é obrigatório')
            .isEmail('Informe um e-mail válido'),
        cargoUsuario: StringType().isRequired('Cargo é obrigatório')
    });

    return (
        <>
            {!loading &&
                <>
                    <DataModal
                        title='Meus Dados'
                        open={true}
                        sizeModal='lg'
                        waiting={waiting}
                        customTextSubmitButton='Confirmar'
                        onClickSubmitButton={() => setOpenConfirm(true)}
                        onClickCancelButton={() => setOpen(false)}
                        formValidation={model}
                        formValue={usuario}
                    >
                        <Grid fluid style={{ marginTop: 30 }}>
                            <Row>
                                <Col xs={24} md={12}>
                                    <Stack direction='column' alignItems='center'>
                                        {usuario && <Avatar className='avatar-perfil-300' src={usuario.fotoPerfilUsuario} circle />}
                                        <Uploader
                                            action=''
                                            listType='picture'
                                            fileListVisible={false}
                                            accept="image/*"
                                            shouldUpload={file => {
                                                setUploading(true);
                                                previewFile(file.blobFile, value => {
                                                    setUsuario(prevUsuario => ({ ...prevUsuario, fotoPerfilUsuario: value }));
                                                    setUploading(false);
                                                });
                                                return false;
                                            }}
                                        >
                                            <Button appearance='subtle'>
                                                {
                                                    uploading
                                                        ? <Loader backdrop center />
                                                        : <CameraRetroIcon style={{ fontSize: '2em' }} />
                                                }
                                            </Button>
                                        </Uploader>
                                    </Stack>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Group controlId="codigoAcessoUsuario">
                                        <Form.ControlLabel>Código de Acesso</Form.ControlLabel>
                                        <Form.Control
                                            name="codigoAcessoUsuario"
                                            readOnly
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="nomeUsuario">
                                        <Form.ControlLabel>Nome</Form.ControlLabel>
                                        <Form.Control
                                            name="nomeUsuario"
                                            autoFocus
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="cargoUsuario">
                                        <Form.ControlLabel>Cargo</Form.ControlLabel>
                                        <Form.Control
                                            name="cargoUsuario"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="emailUsuario">
                                        <Form.ControlLabel>Email</Form.ControlLabel>
                                        <InputGroup>
                                            <InputGroup.Addon> @</InputGroup.Addon>
                                            <Form.Control
                                                name="emailUsuario"
                                                type="email"
                                                onChange={handleInputChange}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="assinaturaUsuario">
                                        <Form.ControlLabel>Assinatura</Form.ControlLabel>
                                        <Form.Control
                                            accepter={Assinatura}
                                            name="assinaturaUsuario"
                                            height={250}
                                            value={usuario.assinaturaUsuario}

                                            onChange={value => setUsuario(prevUsuario => ({ ...prevUsuario, assinaturaUsuario: value }))}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Grid>
                    </DataModal>

                    <Confirm
                        open={openConfirm}
                        message={`Confirma a alteração dos dados do Usuário?`}
                        onClickNoButton={() => setOpenConfirm(false)}
                        onClickYesButton={handleClickYesButton}
                    />
                </>
            }
        </>
    )
}

export default PerfilUsuario
