import { Button, Col, Divider, Form, Grid, Modal, Row, Schema } from "rsuite"

import CustomInputTelefone from "../CustomInputTelefone"
import CustomTextArea from "../CustomTextArea"

import { useEffect, useRef, useState } from "react"
import './style.css'

const ContatoCadastro = ({
  open,
  deleteMode,
  consulta,
  defaultValue,
  onClose,
  onConfirm
}) => {

  const [contato, setContato] = useState()

  useEffect(() => {
    setContato({
      ...{ indexContato: null, nomeContato: "", telefoneContato: "", emailContato: "", obsContato: "" },
      ...defaultValue
    })
  }, [defaultValue])

  const handleInputChange = (value, event) => {
    const fieldName = event.currentTarget.name

    const newValue =
      fieldName === 'emailContato' ? value.toLowerCase()
        : value

    setContato(prevContato => ({
      ...prevContato,
      [fieldName]: newValue
    }))
  }

  const { StringType } = Schema.Types;

  const model = Schema.Model({
    nomeContato: StringType()
      .isRequired('Nome é obrigatório')
      .minLength(3, 'Nome deve conter 3 ou mais letras'),
    telefoneContato: StringType()
      .minLength(10, 'Telefone deve estar completo com DDD'),
    emailContato: StringType().isEmail('Informe um endereço de e-mail válido')
  });

  const formRef = useRef()

  const handleSubmitButton = () => {
    if (!deleteMode && !formRef.current.check()) return false;
    onConfirm(contato)
  }

  return (
    <>
      <Modal
        className='contato-cadastro-modal'
        backdropClassName='contato-cadastro-modal-backdrop'
        style={{
          zIndex: 10000, // estilo para o wraper
        }}
        overflow
        backdrop="static"
        open={open}
        onClose={onClose}
      >
        <Modal.Header>
          <Modal.Title>Cadastro de Contato</Modal.Title>
        </Modal.Header>
        <Divider />

        <Modal.Body>
          <Form
            fluid
            formValue={contato}
            model={model}
            checkTrigger='change'
            ref={formRef}
            readOnly={deleteMode || consulta}
          >
            <Grid fluid>
              <Row>
                <Col xs={24}>
                  <Form.Group controlId="nomeContato">
                    <Form.ControlLabel>Nome do Contato</Form.ControlLabel>
                    <Form.Control
                      name="nomeContato"
                      autoFocus={!deleteMode}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={8} sm={24} xs={24}>
                  <Form.Group controlId="telefoneContato">
                    <Form.ControlLabel>Telefone</Form.ControlLabel>
                    <Form.Control
                      accepter={CustomInputTelefone}
                      name="telefoneContato"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={16} sm={24} xs={24}>
                  <Form.Group controlId="emailContato">
                    <Form.ControlLabel>e-mail</Form.ControlLabel>
                    <Form.Control
                      name="emailContato"
                      type="email"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Form.Group controlId="obsContato">
                    <Form.ControlLabel>Observação</Form.ControlLabel>
                    <Form.Control
                      name="obsContato"
                      accepter={CustomTextArea}
                      rows={2}
                      maxLength={500}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Grid>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          {!consulta &&
            <Button onClick={handleSubmitButton} appearance="ghost" color={deleteMode ? 'red' : 'green'}>
              {deleteMode ? 'Remover' : 'Confirmar'}
            </Button>
          }
          <Button onClick={onClose} appearance="ghost" color='orange'>
            {consulta ? "Fechar" : "Cancelar"}
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  )
}

export default ContatoCadastro
