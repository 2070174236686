import { useEffect, useState } from 'react';

import { AuthContext } from './authContext';

import useApiAuth from '../hooks/useApiAuth';
import useApiMeusDados from '../hooks/useApiMeusDados';

import { getToken, setToken } from '../services/token';

export const AuthProvider = ({ children }) => {
    const apiAuth = useApiAuth();
    const apiMeusDados = useApiMeusDados();

    const [user, setUser] = useState(null);
    const [darkMode, setDarkMode] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const token = getToken();
            if (token) {
                try {
                    const data = await apiAuth.validate();
                    if (data.user) {
                        setUser(data.user);
                        setDarkMode(data.darkMode);
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            setLoading(false)
        })();
        // eslint-disable-next-line
    }, []);

    const login = async (accessCode, password) => {
        try {
            const data = await apiAuth.login(accessCode, password)

            if (data.user && data.token) {
                setUser(data.user);
                setDarkMode(data.darkMode);
                setToken(data.token);
                return true
            }
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const logout = () => {
        setUser(null);
        setToken('');
    }

    const toggleDarkMode = async () => {
        const newDarkMode = !darkMode;
        await apiMeusDados.saveDarkMode(newDarkMode);
        setDarkMode(newDarkMode)
    }

    const savePerfil = async usuario => {
        const response = await apiMeusDados.saveMeusDados(usuario);
        if (response.status === 200 && response.data) setUser(response.data);
        return response.response || response;
    }

    return (
        <AuthContext.Provider value={{ signed: Boolean(user), user, loading, darkMode, login, logout, toggleDarkMode, savePerfil }}>
            {children}
        </AuthContext.Provider>
    );
};