import ApiService from '../services/api.js';

const useApiTipoServico = () => {

    const apiService = new ApiService()
    const api = apiService.api

    const resource = '/tiposervico'

    const getAll = async ({ pageNumber, itemsPerPage, sortColumn, sortType, searchText }) => {
        const response = await api.get(resource, {
            params: {
                pageNumber,
                itemsPerPage,
                sortColumn,
                sortType,
                searchText
            }
        })
        return response.data;
    }

    const getId = async id => {
        const response = await api.get(`${resource}/${id}`)
        return response.data;
    }

    const save = tipoServico => {
        if (!tipoServico.idTipoServico) {
            return api.post(resource, tipoServico)
        } else {
            return api.patch(`${resource}/${tipoServico.idTipoServico}`, tipoServico)
        }
    }

    const destroy = id => api.delete(`${resource}/${id}`)

    return {
        getAll,
        getId,
        save,
        destroy
    }
}

export default useApiTipoServico
