import { useNavigate } from "react-router-dom";
import { Button, Container, Panel } from "rsuite";

import './style.css';

const PageNotFound = () => {

  const navigate = useNavigate()

  return (
    <Container>
      <Panel>
        <div className="page-404-content">
          <img src='/error404.svg' alt='page not found' />
          <h3>Ops… Você acabou de encontrar uma página de erro</h3>
          <h5>Lamentamos mas a página que procura não foi encontrada</h5>
          <Button color='blue' appearance="ghost" onClick={() => navigate('/')} >Voltar para a Home</Button>
        </div>
      </Panel>
    </Container>
  )
}

export default PageNotFound
