import { Button, List, Panel } from 'rsuite'

import EditIcon from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import VisibleIcon from '@rsuite/icons/Visible'

import { phoneFormat } from '../../services/utils'

import useScreenSizeComparison from "../../hooks/useScreenSizeComparison"

import './style.css'

const ContatoList = ({
    contatos,
    consulta,
    onEditButtonClick,
    onDeleteButtonClick,
    hideButtons
}) => {
    const { isScreenSizeBelow } = useScreenSizeComparison()

    const hiddenCol_md = isScreenSizeBelow('md')

    return (
        <>
            <Panel bordered bodyFill className="contato-list-container">
                <List size="sm" hover>
                    {contatos && contatos.map((item, index) => (
                        <List.Item key={index} index={index}>
                            <div className='contato-list-item-container'>
                                <div className='contato-list-item-data-container'>
                                    <div className="contato-list-content-container">
                                        <div className='contato-list-content-nome'>
                                            {item.nomeContato}
                                        </div>
                                        <div className='contato-list-content-telefone'>
                                            {phoneFormat(item.telefoneContato)}
                                        </div>
                                        {!hiddenCol_md &&
                                            <div className='contato-list-content-email'>
                                                {item.emailContato}
                                            </div>
                                        }
                                    </div>
                                    <div className='contato-list-content-obs'>
                                        {item.obsContato}
                                    </div>
                                </div>
                                {!hideButtons &&
                                    <div className='contato-list-button-container'>
                                        {consulta
                                            ? <Button
                                                appearance='ghost'
                                                color='green'
                                                style={{ padding: '4px 6px' }}
                                                onClick={() => onEditButtonClick(index)}
                                            >
                                                <VisibleIcon />
                                            </Button>
                                            : <>
                                                <Button
                                                    appearance='ghost'
                                                    color='blue'
                                                    style={{ padding: '4px 6px' }}
                                                    onClick={() => onEditButtonClick(index)}
                                                >
                                                    <EditIcon />
                                                </Button>
                                                <Button
                                                    appearance='ghost'
                                                    color='red'
                                                    style={{ padding: '4px 6px' }}
                                                    onClick={() => onDeleteButtonClick(index)}
                                                >
                                                    <TrashIcon />
                                                </Button>
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        </List.Item >
                    ))}
                </List >
            </Panel >
        </>
    )
}

export default ContatoList
