import ApiService from '../services/api.js';

const useApiMunicipio = () => {

    const apiService = new ApiService()
    const api = apiService.api

    const resource = '/municipio'

    const getByUF = async (siglaUF, { pageNumber, itemsPerPage, sortColumn, sortType, searchText }) => {
        const response = await api.get(`${resource}/uf/${siglaUF}`, {
            params: {
                pageNumber,
                itemsPerPage,
                sortColumn,
                sortType,
                searchText
            }
        })
        return response.data;
    }

    const getId = async id => {
        const response = await api.get(`${resource}/${id}`)
        return response.data;
    }

    const getByIbge = async codigoIbge => {
        try {
            const response = await api.get(`${resource}/ibge/${codigoIbge}`)
            return response.data;
        } catch (error) {
            return null
        }
    }

    const getByUFNome = async (siglaUF, nomeMunicipio) => {
        if (!siglaUF || !nomeMunicipio) return null
        try {
            const response = await api.get(`${resource}/uf/${siglaUF}/${nomeMunicipio}`)
            return response.data;
        } catch (error) {
            return null
        }
    }

    return {
        getByIbge,
        getByUF,
        getByUFNome,
        getId
    }
}

export default useApiMunicipio
