import ApiService from '../services/api.js';

const useApiMenu = () => {

    const apiService = new ApiService()
    const api = apiService.api

    const getMenuUser = async () => {
        const response = await api.get(`/menu/user`)
        return response.data;
    }

    const getMenuList = async () => {
        const response = await api.get(`/menu/list`)
        return response.data;
    }

    const getTaskUser = async () => {
        const response = await api.get(`/task/user`)
        return response.data;
    }

    const getTaskList = async () => {
        const response = await api.get(`/task/list`)
        return response.data;
    }

    const getIcon = async iconName => {
        const response = await api.get(`/icon/${iconName}`)
        return response.data;
    }

    const getIconList = async () => {
        const response = await api.get(`/icon`)
        return response.data;
    }

    return {
        getIcon,
        getIconList,
        getMenuUser,
        getMenuList,
        getTaskUser,
        getTaskList
    }
}

export default useApiMenu
