import { forwardRef } from "react";
import { Message, Popover, Whisper } from "rsuite";

import './style.css';

export const showMessage = ({ toaster, type, message, executeFirst, executeLast, placement, durationTime }) => {
    if (!toaster) return false

    const duration = durationTime || (typeof message === 'object' ? 5000 : 2000)
    const msgType = type || (typeof message === 'object' ? "error" : "success")

    let msg = ''

    if (message.response && message.response.data) {
        if (message.response.data.message) {
            msg = message.response.data.message
        } else if (message.response.data.errors) {
            for (let index = 0; index < message.response.data.errors.length; index++) {
                const erro = message.response.data.errors[index];
                msg += erro.message + '\n'
            }
        }
    } else if (message.message) {
        msg = message.message
    } else {
        msg = message
    }

    if (executeFirst) executeFirst();

    toaster.push(<Message type={msgType} showIcon closable header={msg} />, { placement: `${placement || 'topEnd'}`, duration: duration });

    if (executeLast) setTimeout(() => executeLast(), duration);
}

const PopoverMessage = forwardRef(({ type, content, ...rest }, ref) => {

    const contentClass = type === 'error'
        ? 'popover-message-error'
        : null

    return (
        <div className="popover-message-container" >
            <Popover ref={ref} {...rest} >
                <div className="popover-message-content-container">
                    <p className={contentClass}>
                        {content}
                    </p>
                </div>
            </Popover>
        </div>
    );
});

export const PopoverMessageContainer = forwardRef(({ type, children, message, ...rest }, ref) => (
    <Whisper
        containerStyle={{ padding: 0 }}
        trigger="none"
        placement="bottomStart"
        ref={ref}
        speaker={<PopoverMessage type={type} content={message} />}
        {...rest}
    >
        {children}
    </Whisper>
))