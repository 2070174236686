import ApiService from '../services/api.js';
import { formatDate } from '../services/utils.js';

const useApiFeriado = () => {

    const apiService = new ApiService()
    const api = apiService.api

    const resource = '/feriado'

    const getAll = async ({ pageNumber, itemsPerPage, sortColumn, sortType, searchText, exibirSoDoAno }) => {
        const response = await api.get(resource, {
            params: {
                pageNumber,
                itemsPerPage,
                sortColumn,
                sortType,
                searchText,
                exibirSoDoAno
            }
        })
        return response.data;
    }

    const getOne = async data => {
        const dataFormatada = formatDate(data, true)
        const response = await api.get(`${resource}/${dataFormatada}`)
        return response.data;
    }

    const getYearMonth = async (ano, mes) => {
        const response = await api.get(`${resource}/${ano}/${mes}`)
        return response.data;
    }

    const save = (feriado, isUpdate) => {
        if (isUpdate) {
            const dataFormatada = formatDate(feriado.dataFeriado, true)
            return api.patch(`${resource}/${dataFormatada}`, feriado)
        } else {
            return api.post(resource, feriado)
        }
    }

    const destroy = data => {
        const dataFormatada = formatDate(data, true)
        return api.delete(`${resource}/${dataFormatada}`)
    }

    return {
        getAll,
        getOne,
        getYearMonth,
        save,
        destroy
    }
}

export default useApiFeriado
