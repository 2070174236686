import { useEffect, useRef, useState } from "react";
import { Col, Form, Grid, Radio, RadioGroup, Row, Schema, Stack, useToaster } from "rsuite";

import { Icon } from "@rsuite/icons";
import { MdEmojiPeople } from 'react-icons/md';
import { PiFactory } from 'react-icons/pi';

import Assinatura from "../../components/Assinatura";
import Confirm from "../../components/Confirm";
import CustomInputCep from "../../components/CustomInputCep";
import CustomInputCnpjCpf from "../../components/CustomInputCnpjCpf";
import CustomInputDate from "../../components/CustomInputDate";
import CustomInputNumeroEndereco from "../../components/CustomInputNumeroEndereco";
import CustomInputTelefone from "../../components/CustomInputTelefone";
import CustomSelectPicker from "../../components/CustomSelectPicker";
import DataContainer from "../../components/DataContainer";
import DataPanel from "../../components/DataPanel";
import Help from "../../components/Help";
import { showMessage } from "../../components/Message";

import { apiGetCep, apiGetCnpj } from '../../services/brasilApi';
import { dateFromString, isCNPJ, isCPF } from "../../services/utils";

import useApiEmpresa from "../../hooks/useApiEmpresa";
import useApiMunicipio from "../../hooks/useApiMunicipio";
import useApiUF from "../../hooks/useApiUF";

import './style.css';

const Empresa = () => {
    const { save, getEmpresa } = useApiEmpresa()
    const apiUF = useApiUF()
    const apiMunicipio = useApiMunicipio()

    const formGroupRefUF = useRef()
    const formGroupRefMunicipio = useRef()

    const [empresa, setEmpresa] = useState({ fisJurEmpresa: 'J' })

    const [waiting, setWaiting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingApiCnpj, setLoadingApiCnpj] = useState(false)
    const [loadingApiCep, setLoadingApiCep] = useState(false)

    const [openConfirm, setOpenConfirm] = useState(false)

    const [cnpjInitial, setCnpjInitial] = useState()
    const [cepInitial, setCepInitial] = useState()

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, type: 'success', message: 'Gravação concluída com sucesso' })
    const showError = error => showMessage({ toaster, type: 'error', message: error, executeFirst: () => setWaiting(false) })

    const getData = async () => {
        setLoading(true)
        try {
            const data = await getEmpresa()
            if (data) {

                data.validadeCEVSEmpresa = dateFromString(data.validadeCEVSEmpresa)
                data.validadeCRQEmpresa = dateFromString(data.validadeCRQEmpresa)

                setEmpresa(data)
                setCnpjInitial(data.cnpjCpfEmpresa)
                setCepInitial(data.cepEmpresa)
            }
        } catch (error) {
            showError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        (async () => await getData())()
        // eslint-disable-next-line
    }, [])

    const handleClickYesConfirm = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            await save(empresa)
            showSucess()
            await getData()
        } catch (error) {
            showError(error)
        }
        setWaiting(false)
    }

    const handleInputChange = (value, event) => {
        const fieldName = event.currentTarget ? event.currentTarget.name : event

        const newValue =
            fieldName === 'ieEmpresa' ? value[fieldName].toUpperCase()
                : fieldName === 'emailEmpresa' ? value[fieldName].toLowerCase()
                    : value[fieldName]

        setEmpresa(prevEmpresa => ({
            ...prevEmpresa,
            [fieldName]: newValue
        }))

        if (fieldName === 'fisJurEmpresa' && value === 'F') {
            setEmpresa(prevEmpresa => ({
                ...prevEmpresa,
                ieEmpresa: 'ISENTO'
            }))
        }
    }

    const handleCnpjCpfBlur = async event => {
        const value = event.target.value
        const cnpj = value ? value.replace(/\D/g, '') : ''

        if (empresa.fisJurEmpresa === "F") return

        if (!cnpj || cnpj === cnpjInitial) return
        setLoadingApiCnpj(true)

        const dataRFB = await apiGetCnpj(cnpj)

        if (dataRFB) {
            const municipio = await apiMunicipio.getByIbge(dataRFB.codigo_municipio_ibge)

            setCepInitial(dataRFB.cep)
            setEmpresa(prevEmpresa => ({
                ...prevEmpresa,
                nomeEmpresa: dataRFB.nomeEmpresa,
                nomeFantasiaEmpresa: dataRFB.nomeFantasia,
                ieEmpresa: '',
                enderecoEmpresa: dataRFB.endereco,
                numeroEnderecoEmpresa: dataRFB.numero,
                complementoEmpresa: dataRFB.complemento,
                bairroEmpresa: dataRFB.bairro,
                cepEmpresa: dataRFB.cep,
                telefone1Empresa: dataRFB.telefone1,
                telefone2Empresa: dataRFB.telefone2,
                emailEmpresa: dataRFB.email,
                siglaUF: dataRFB.uf,
                idMunicipio: municipio ? municipio.idMunicipio : null
            }))
            setCnpjInitial(cnpj)
        }
        setLoadingApiCnpj(false)
    }

    const handleCepBlur = async event => {
        const value = event.target.value
        const cep = value ? value.replace(/\D/g, '') : ''

        if (!cep || cep === cepInitial) return
        setLoadingApiCep(true)

        const data = await apiGetCep(cep)

        if (data) {
            const municipio = await apiMunicipio.getByUFNome(data.uf, data.cidade)

            setEmpresa(prevEmpresa => ({
                ...prevEmpresa,
                enderecoEmpresa: data.endereco,
                bairroEmpresa: data.bairro,
                siglaUF: data.uf,
                idMunicipio: municipio ? municipio.idMunicipio : null
            }))
            setCepInitial(cep)
        }
        setLoadingApiCep(false)
    }

    const handleGetMunicipioData = params => empresa.siglaUF ? apiMunicipio.getByUF(empresa.siglaUF, params) : null

    const { StringType, NumberType, MixedType, DateType } = Schema.Types;

    const model = Schema.Model({
        nomeEmpresa: StringType()
            .isRequired('Nome é obrigatório')
            .minLength(3, 'Nome deve conter 3 ou mais letras'),
        cnpjCpfEmpresa: MixedType()
            .isRequired(`${empresa.fisJurEmpresa === 'F' ? 'CPF' : 'CNPJ'} é obrigatório`)
            .addRule((value, data) => {
                if (data.fisJurEmpresa === 'F') return value.length === 11
                if (data.fisJurEmpresa === 'J') return value.length === 14
                return true
            }, `Informe o ${empresa.fisJurEmpresa === 'F' ? 'CPF' : 'CNPJ'} completo`)
            .addRule((value, data) => {
                if (data.fisJurEmpresa === 'F') return isCPF(value)
                if (data.fisJurEmpresa === 'J') return isCNPJ(value)
                return true
            }, `Informe um ${empresa.fisJurEmpresa === 'F' ? 'CPF' : 'CNPJ'} válido`),
        ieEmpresa: StringType().isRequired('I.E. é obrigatória'),
        enderecoEmpresa: StringType().isRequired('Endereço é obrigatório'),
        numeroEnderecoEmpresa: StringType().isRequired('Número é obrigatório'),
        bairroEmpresa: StringType().isRequired('Bairro é obrigatório'),
        cepEmpresa: StringType()
            .isRequired('CEP é obrigatório')
            .minLength(8, 'Informe o CEP completo com 8 dígitos'),
        siglaUF: StringType().isRequired('UF é obrigatória'),
        idMunicipio: NumberType().isRequired('Município é obrigatório'),
        telefone1Empresa: StringType()
            .isRequired('Telefone é obrigatório')
            .minLength(10, 'Telefone deve estar completo com DDD'),
        telefone2Empresa: StringType().minLength(10, 'Telefone deve estar completo com DDD'),
        telefone3Empresa: StringType().minLength(10, 'Telefone deve estar completo com DDD'),
        emailEmpresa: StringType().isEmail('Informe um endereço de e-mail válido'),
        numeroCEVSEmpresa: StringType().isRequired('Registro no CEVS é obrigatório'),
        validadeCEVSEmpresa: DateType('Informe uma data válida')
            .isRequired('Validade é obrigatória')
            .min(new Date('01/01/0001'), 'Informe uma data válida'),
        numeroCRQEmpresa: StringType().isRequired('Número do CRQ é obrigatório'),
        validadeCRQEmpresa: DateType('Informe uma data válida')
            .isRequired('Validade do CRQ é obrigatória')
            .min(new Date('01/01/0001'), 'Informe uma data válida'),
        nomeQuimicoResponsavelEmpresa: StringType()
            .isRequired('Nome do Responsável Químico é obrigatório')
            .minLength(3, 'Nome deve conter 3 ou mais letras'),
        numeroCRQQuimicoResponsavelEmpresa: StringType().isRequired('Número do CRQ é obrigatório')
    });

    return (
        <DataContainer title={"Dados da Empresa"}>
            <DataPanel
                className={'empresa-dados'}
                bodyHeight='calc(100vh - 270px)'
                waiting={waiting}
                loading={loading}
                onClickSubmitButton={() => setOpenConfirm(true)}
                formValidation={model}
                formValue={empresa}
                formOnChange={handleInputChange}
                showLoader={loadingApiCnpj || loadingApiCep}
                messageLoader={
                    loadingApiCnpj ? 'Buscando dados da empresa pelo CNPJ'
                        : loadingApiCep ? 'Buscando endereço pelo CEP'
                            : undefined
                }
            >
                <Grid fluid>
                    <Row>
                        <Col xxl={4} md={8} sm={9} xs={24} className="align-bottom">
                            <Form.Group controlId="fisJurEmpresa">
                                <Form.ControlLabel>Tipo de pessoa</Form.ControlLabel>
                                <Form.Control
                                    name="fisJurEmpresa"
                                    accepter={RadioGroup}
                                    inline
                                    appearance="picker"
                                    defaultValue="J"
                                >
                                    <Radio value="J">
                                        <Stack spacing={7} direction="row">
                                            <Icon as={PiFactory} size="1.5em" />
                                            Jurídica
                                        </Stack>
                                    </Radio>
                                    <Radio value="F">
                                        <Stack spacing={7} direction="row">
                                            <Icon as={MdEmojiPeople} size="1.5em" />
                                            Física
                                        </Stack>
                                    </Radio>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xl={6} md={8} sm={8} xs={24}>
                            <Form.Group controlId="cnpjCpfEmpresa">
                                <Form.ControlLabel>{empresa.fisJurEmpresa === 'J' ? 'CNPJ' : 'CPF'}</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputCnpjCpf}
                                    tipoFisJur={empresa.fisJurEmpresa}
                                    name="cnpjCpfEmpresa"
                                    autoFocus={true}
                                    onBlur={handleCnpjCpfBlur}
                                />
                            </Form.Group>
                        </Col>
                        <Col xl={6} md={8} sm={7} xs={24}>
                            <Form.Group controlId="ieEmpresa">
                                <Form.ControlLabel>Inscrição Estadual</Form.ControlLabel>
                                <Form.Control
                                    name="ieEmpresa"
                                    maxLength={20}
                                    readOnly={empresa.fisJurEmpresa === 'F'}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={17} md={18} sm={24} xs={24}>
                            <Form.Group controlId="nomeEmpresa">
                                <Form.ControlLabel>Nome da Empresa</Form.ControlLabel>
                                <Form.Control
                                    name="nomeEmpresa"
                                    checkTrigger={'blur'}
                                />
                            </Form.Group>
                        </Col>
                        <Col xl={7} md={6} sm={24} xs={24}>
                            <Form.Group controlId="nomeFantasiaEmpresa">
                                <Form.ControlLabel>Nome Fantasia</Form.ControlLabel>
                                <Form.Control name="nomeFantasiaEmpresa" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={3} md={4} sm={5} xs={24}>
                            <Form.Group controlId="cepEmpresa">
                                <Form.ControlLabel>CEP</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputCep}
                                    name="cepEmpresa"
                                    onBlur={handleCepBlur}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={12} md={16} sm={14} xs={24}>
                            <Form.Group controlId="enderecoEmpresa">
                                <Form.ControlLabel>Endereço</Form.ControlLabel>
                                <Form.Control name="enderecoEmpresa" />
                            </Form.Group>
                        </Col>
                        <Col xxl={3} md={4} sm={5} xs={24}>
                            <Form.Group controlId="numeroEnderecoEmpresa">
                                <Form.ControlLabel>
                                    Número
                                    <Help size="xs" message="Informe o número ou SN se não houver." />
                                </Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputNumeroEndereco}
                                    name="numeroEnderecoEmpresa"
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} md={12} sm={12} xs={24}>
                            <Form.Group controlId="complementoEmpresa">
                                <Form.ControlLabel>Complemento</Form.ControlLabel>
                                <Form.Control name="complementoEmpresa" />
                            </Form.Group>
                        </Col>
                        <Col xxl={8} md={12} sm={12} xs={24}>
                            <Form.Group controlId="bairroEmpresa">
                                <Form.ControlLabel>Bairro</Form.ControlLabel>
                                <Form.Control
                                    name="bairroEmpresa"
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={8} md={12} sm={24} xs={24}>
                            <Form.Group controlId="siglaUF" ref={formGroupRefUF}>
                                <Form.ControlLabel>UF</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefUF}
                                    name="siglaUF"
                                    placeholder="Selecione a UF"
                                    onChange={value => setEmpresa(prevEmpresa => ({ ...prevEmpresa, siglaUF: value, idMunicipio: '' }))}
                                    apiGetData={apiUF.getAll}
                                    apiGetInitialData={apiUF.getId}
                                    apiGetDataOrder={{ column: 'nomeUF' }}
                                    getDataObject={item => ({ value: item.siglaUF, label: `${item.siglaUF} - ${item.nomeUF}` })}
                                    reloadDataTriggerEffect={[cnpjInitial, cepInitial]}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={8} md={12} sm={24} xs={24}>
                            <Form.Group controlId="idMunicipio" ref={formGroupRefMunicipio}>
                                <Form.ControlLabel>Município</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomSelectPicker}
                                    formGroupRef={formGroupRefMunicipio}
                                    name="idMunicipio"
                                    placeholder={`Selecione o Município${empresa.siglaUF && empresa.siglaUF !== '' ? '' : ' após selecionar a UF'}`}
                                    onChange={value => setEmpresa(prevEmpresa => ({ ...prevEmpresa, idMunicipio: value }))}
                                    apiGetData={handleGetMunicipioData}
                                    apiGetInitialData={apiMunicipio.getId}
                                    apiGetDataOrder={{ column: 'nomeMunicipio' }}
                                    getDataObject={item => ({ value: item.idMunicipio, label: item.nomeMunicipio })}
                                    reloadDataTriggerEffect={[empresa.siglaUF, cnpjInitial, cepInitial]}
                                    showError={showError}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={3} lg={4} sm={8} xs={24}>
                            <Form.Group controlId="telefone1Empresa">
                                <Form.ControlLabel>Telefone 1</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputTelefone}
                                    name="telefone1Empresa"
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={3} lg={4} sm={8} xs={24}>
                            <Form.Group controlId="telefone2Empresa">
                                <Form.ControlLabel>Telefone 2</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputTelefone}
                                    name="telefone2Empresa"
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={3} lg={4} sm={8} xs={24}>
                            <Form.Group controlId="telefone3Empresa">
                                <Form.ControlLabel>Telefone 3</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputTelefone}
                                    name="telefone3Empresa"
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={7} lg={12} md={11} sm={24} xs={24}>
                            <Form.Group controlId="emailEmpresa">
                                <Form.ControlLabel>e-mail</Form.ControlLabel>
                                <Form.Control
                                    name="emailEmpresa"
                                    type="email"
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={8} lg={12} md={13} sm={24} xs={24}>
                            <Form.Group controlId="nomeContatoEmpresa">
                                <Form.ControlLabel>Nome do Contato</Form.ControlLabel>
                                <Form.Control
                                    name="nomeContatoEmpresa"
                                    checkTrigger={'blur'}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} sm={12} xs={24}>
                            <Form.Group controlId="numeroCEVSEmpresa">
                                <Form.ControlLabel>Número do Registro no CEVS</Form.ControlLabel>
                                <Form.Control name="numeroCEVSEmpresa" />
                            </Form.Group>
                        </Col>
                        <Col lg={6} sm={12} xs={24}>
                            <Form.Group controlId="validadeCEVSEmpresa">
                                <Form.ControlLabel>Validade do Registro no CEVS</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputDate}
                                    name="validadeCEVSEmpresa"
                                    block
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6} sm={12} xs={24}>
                            <Form.Group controlId="numeroCRQEmpresa">
                                <Form.ControlLabel>Número do CRQ da Empresa</Form.ControlLabel>
                                <Form.Control name="numeroCRQEmpresa" />
                            </Form.Group>
                        </Col>
                        <Col lg={6} sm={12} xs={24}>
                            <Form.Group controlId="validadeCRQEmpresa">
                                <Form.ControlLabel>Validade do CRQ da Empresa</Form.ControlLabel>
                                <Form.Control
                                    accepter={CustomInputDate}
                                    name="validadeCRQEmpresa"
                                    block
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={7} lg={12} md={14} xs={24}>
                            <Form.Group controlId="nomeQuimicoResponsavelEmpresa">
                                <Form.ControlLabel>Nome do Químico Responsável</Form.ControlLabel>
                                <Form.Control
                                    name="nomeQuimicoResponsavelEmpresa"
                                    checkTrigger={'blur'}
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={5} xl={6} lg={8} md={10} xs={24}>
                            <Form.Group controlId="numeroCRQQuimicoResponsavelEmpresa">
                                <Form.ControlLabel>Número do CRQ do Químico Responsável</Form.ControlLabel>
                                <Form.Control name="numeroCRQQuimicoResponsavelEmpresa" />
                            </Form.Group>
                        </Col>
                        <Col xxl={12} xl={18} lg={16} xs={24}>
                            <Form.Group controlId="assinaturaQuimicoResponsavelEmpresa">
                                <Form.ControlLabel>Assinatura do Químico Responsável</Form.ControlLabel>
                                <Form.Control
                                    accepter={Assinatura}
                                    name="assinaturaQuimicoResponsavelEmpresa"
                                    height={165}
                                    marginBottom={0}
                                    value={empresa.assinaturaQuimicoResponsavelEmpresa}
                                    onChange={value => setEmpresa(prevEmpresa => ({ ...prevEmpresa, assinaturaQuimicoResponsavelEmpresa: value }))}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataPanel>
            <Confirm
                open={openConfirm}
                message={'Confirma a gravação dos dados da Empresa?'}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYesConfirm}
            />
        </DataContainer>
    )
}

export default Empresa